import useConfiguration from "@hooks/UseConfiguration";
import {Configuration, ConfigurationProps} from "@interfaces/Configuration";
import React from "react"

interface ConfigurationContextType {
    configurations: Configuration[],
    getValue: <Key extends keyof ConfigurationProps>(key:Key, defaultValue?: ConfigurationProps[Key])=>ConfigurationProps[Key] | undefined,
    setValue: <Key extends keyof ConfigurationProps>(key:Key, value:ConfigurationProps[Key])=>void
    setActiveConfiguration: (id:string)=>void,
    removeConfiguration: (id:string)=>void,
    newConfiguration: ()=>Promise<Configuration|undefined>
}

const ConfigurationContext = React.createContext<ConfigurationContextType>(null!);

function ConfigurationProvider({ children }: { children: React.ReactNode }) {
    
    const [configurations, setValue, getValue, removeConfiguration, setActiveConfiguration, newConfiguration] = useConfiguration()

    const value = { configurations, setValue, getValue, removeConfiguration, setActiveConfiguration, newConfiguration } as ConfigurationContextType

    return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>
}

export {ConfigurationProvider, ConfigurationContext}