import { Order } from "@csapi/provisioning/api"
import Localization from "@localization/Index"
import ShopIcon from "@components/ShopIcon/ShopIcon"
import { getOrderStatus, OrderStatus } from "@utils/OrderUtils"
import { getShopsFromIds } from "@utils/ShopsUtils"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import styles from './css/OrderSummaryHeader.module.scss'
import OrderSummaryHeaderDetails from "./OrderSummaryHeaderDetails"

type Props = {
    order: Order,
    onClick?: Function,
    // lastUpdate?: Date,
    // errors?: unknown[], //TODO: define order errors structure
    // onRefresh?: Function
}

const OrderSummaryHeader = (props:Props) =>{
    const { order, onClick } = props
    const shopIds = [order?.shop_id?.$oid!]
    const shop = getShopsFromIds(shopIds)?.[0]

    return <div>
                <div className="d-flex justify-content-left align-items-center p-2">
                    <div>
                        <h3 className={`${onClick?"cs-link":''}`}>{Localization.PAGE_ORDERS.ORDINE} <span onClick={()=>{onClick?.()}}><strong>{order?.shop_order_id}</strong></span></h3>
                        <OrderSummaryHeaderDetails order={order} />
                    </div>
                </div>
            </div>
}

export default OrderSummaryHeader