import { Category } from '@csapi/provisioning'
import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback } from 'react'
import { getCategories, deleteCategory, updateCategory,createCategory  } from '@api/Categories'

interface CategoryContextProps {
  categories: Category[]
  // altre funzioni come prima
  fetchCategories: () => void
  removeCategory: (categoryId: string) => void
  modifyCategory: (categoryId: string, updatedCategory: Category) => void
  newCategory: (newCategory: Category) => void
  isCategoryLoading : boolean
}

const CategoryContext = createContext<CategoryContextProps | undefined>(undefined)

export const CategoryProvider = ({ children }: { children: ReactNode }) => {
  const [categories, setCategories] = useState<Category[]>([])
  const [isCategoryLoading, setIsCategoryLoading] = useState(true)


  const fetchCategories = useCallback(async () => {
    setIsCategoryLoading(true)
    const fetchedCategories = await getCategories()
    setCategories(fetchedCategories?.content ?? [])
    setIsCategoryLoading(fetchedCategories?.content && fetchedCategories?.content.length >0 ? false : true)
    
  }, [])

  // Carica le categorie all'avvio
  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  const removeCategory = async (categoryId: string) => {
    try {
      await deleteCategory(categoryId)
      fetchCategories()
    } catch (error) {
      console.error(`Errore nella cancellazione della categoria:`, error)
    }
  }

  const modifyCategory = async (categoryId: string, updatedCategory: Category) => {
    try {
      await updateCategory(categoryId, updatedCategory)
      fetchCategories()
    } catch (error) {
      console.error(`Errore nell'aggiornamento della categoria:`, error)
    }
  }

  const newCategory = async (newCategory: Category) => {
    try {
      const createdCategory  = await createCategory(newCategory)
      if(createdCategory.content)
        fetchCategories()
    } catch (error) {
      console.error('Errore nella creazione della categoria:', error)
    }
  }

  return (
    <CategoryContext.Provider value={{ categories, fetchCategories, removeCategory, modifyCategory, newCategory, isCategoryLoading }}>
      {children}
    </CategoryContext.Provider>
  )
}

export const useCategories = () => {
  const context = useContext(CategoryContext)
  if (!context) {
    throw new Error('useCategories must be used within a CategoryProvider')
  }
  return context
}
