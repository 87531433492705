import CSDiv from "@components/HTMLElements/CSDiv"
import { PropsWithChildren } from "react"
import SearchableListAdd from "./SearchableListAdd"
import SearchableListSearch from "./SearchableListSearch"

export type Search = {
    label?: string
    onChange?: (term:string)=>unknown
    disabled?: boolean
}

export type Add = {
    label?: string,
    onAdd?: (newValue: string)=>unknown
    disabled?: boolean
}

type Props = {
    search?: Search,
    add?: Add
    controlId: string
}

const SearchableList = (props: PropsWithChildren<Props>) =>{
    const { controlId, search, add, children } = props
    return <CSDiv fillVertical offsetVertical={90}>
        {search && <SearchableListSearch search={search} controlId={controlId} />}
        <CSDiv className={`mt-5 mb-5 d-flex flex-column justify-content-start align-items-center`} fillVertical offsetVertical={190} style={{overflowY: "auto"}}>
            {children}
        </CSDiv>
        {add && <SearchableListAdd add={add} controlId={controlId} />}
    </CSDiv>
}

export default SearchableList