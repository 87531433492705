export enum PageStates {
    INIT = "INIT",
    INIT_ERROR = "INIT_ERROR",
    IDLE = "IDLE",
    LOADING = "LOADING",
    ERROR = "ERROR",
    LOADING_ERROR = "LOADING_ERROR",
    DATA_LOADING = "DATA_LOADING",
    DATA_LOADING_ERROR = "DATA_LOADING_ERROR",
    SUBMITTING = "SUBMITTING",
    SUBMITTING_ERROR = "SUBMITTING_ERROR",
    FORM_ERROR = "FORM_ERROR",
}