
interface IconProps {
    name: string,
    size?: number,
    color?: string
}

interface ShopIconProps {
    id: string,
    size?: number,
    extended?: boolean
}

interface FlagsIconProps{
    flagId: string,
    size?: number,
    round?:boolean,
    onClick?: () => void,
    title?: boolean
    disabled?: boolean
}

export enum LanguageId {
    CN = 'cn',
    DE = 'de',
    DK = 'dk',
    ES = 'es',
    FR = 'fr',
    EN = 'en',
    IT = 'it',
    RU = 'ru',
    SE = 'se',
    KR = 'kr',
}

//FIXME:  c'è una discrepanza tra la iso delle bandiere del backend [ISO 639-1:2002] e quella del Front End [ISO 3166-1-alpha-2]
// per ora l'unica differenza è sul danese ma in caso di nuova lingua bisona veridicare come tradurla e nel caso fare un metodo generico
export const countryIdISOConverter = (country :string) =>{
    return country ==='da'?'dk':country
}



export const countryIds = Object.values(LanguageId) as string[];


export const convertLanguageId = (countryKey: string): LanguageId | undefined => {
    switch (countryKey) {
      case 'de':
        return LanguageId.DE
      case 'dk':
        return LanguageId.DK
      case 'es':
        return LanguageId.ES
      case 'fr':
        return LanguageId.FR
      case 'en':
        return LanguageId.EN
      case 'it':
        return LanguageId.IT
      case 'se':
        return LanguageId.SE
      case 'cn':
        return LanguageId.CN
      case 'ru':    
        return LanguageId.RU
      case 'kr':
        return LanguageId.KR  
      default:
        return undefined
    }
  }

  export enum AddressCountryCodeEnum {
    Undefined= 'UNDEFINED',
    Ac= 'AC',
    Ad= 'AD',
    Ae= 'AE',
    Af= 'AF',
    Ag= 'AG',
    Ai= 'AI',
    Al= 'AL',
    Am= 'AM',
    /*An= 'AN',*/
    Ao= 'AO',
    Aq= 'AQ',
    Ar= 'AR',
    As= 'AS',
    At= 'AT',
    Au= 'AU',
    Aw= 'AW',
    Ax= 'AX',
    Az= 'AZ',
    Ba= 'BA',
    Bb= 'BB',
    Bd= 'BD',
    Be= 'BE',
    Bf= 'BF',
    Bg= 'BG',
    Bh= 'BH',
    Bi= 'BI',
    Bj= 'BJ',
    Bl= 'BL',
    Bm= 'BM',
    Bn= 'BN',
    Bo= 'BO',
    Bq= 'BQ',
    Br= 'BR',
    Bs= 'BS',
    Bt= 'BT',
    /*Bu= 'BU',*/
    Bv= 'BV',
    Bw= 'BW',
    By= 'BY',
    Bz= 'BZ',
    Ca= 'CA',
    Cc= 'CC',
    Cd= 'CD',
    Cf= 'CF',
    Cg= 'CG',
    Ch= 'CH',
    Ci= 'CI',
    Ck= 'CK',
    Cl= 'CL',
    Cm= 'CM',
    Cn= 'CN',
    Co= 'CO',
    /*Cp= 'CP',*/
    Cr= 'CR',
    /*Cs= 'CS',*/
    Cu= 'CU',
    Cv= 'CV',
    Cw= 'CW',
    Cx= 'CX',
    Cy= 'CY',
    Cz= 'CZ',
    De= 'DE',
    /*Dg= 'DG',*/
    Dj= 'DJ',
    Dk= 'DK',
    Dm= 'DM',
    Do= 'DO',
    Dz= 'DZ',
    /*Ea= 'EA',*/
    Ec= 'EC',
    Ee= 'EE',
    Eg= 'EG',
    Eh= 'EH',
    Er= 'ER',
    Es= 'ES',
    Et= 'ET',
    /*Eu= 'EU',*/
    /*Ez= 'EZ',*/
    Fi= 'FI',
    Fj= 'FJ',
    Fk= 'FK',
    Fm= 'FM',
    Fo= 'FO',
    Fr= 'FR',
    /*Fx= 'FX',*/
    Ga= 'GA',
    Gb= 'GB',
    Gd= 'GD',
    Ge= 'GE',
    Gf= 'GF',
    Gg= 'GG',
    Gh= 'GH',
    Gi= 'GI',
    Gl= 'GL',
    Gm= 'GM',
    Gn= 'GN',
    Gp= 'GP',
    Gq= 'GQ',
    Gr= 'GR',
    Gs= 'GS',
    Gt= 'GT',
    Gu= 'GU',
    Gw= 'GW',
    Gy= 'GY',
    Hk= 'HK',
    Hm= 'HM',
    Hn= 'HN',
    Hr= 'HR',
    Ht= 'HT',
    Hu= 'HU',
    /*Ic= 'IC',*/
    Id= 'ID',
    Ie= 'IE',
    Il= 'IL',
    Im= 'IM',
    In= 'IN',
    Io= 'IO',
    Iq= 'IQ',
    Ir= 'IR',
    Is= 'IS',
    It= 'IT',
    Je= 'JE',
    Jm= 'JM',
    Jo= 'JO',
    Jp= 'JP',
    Ke= 'KE',
    Kg= 'KG',
    Kh= 'KH',
    Ki= 'KI',
    Km= 'KM',
    Kn= 'KN',
    Kp= 'KP',
    Kr= 'KR',
    Kw= 'KW',
    Ky= 'KY',
    Kz= 'KZ',
    La= 'LA',
    Lb= 'LB',
    Lc= 'LC',
    Li= 'LI',
    Lk= 'LK',
    Lr= 'LR',
    Ls= 'LS',
    Lt= 'LT',
    Lu= 'LU',
    Lv= 'LV',
    Ly= 'LY',
    Ma= 'MA',
    Mc= 'MC',
    Md= 'MD',
    Me= 'ME',
    Mf= 'MF',
    Mg= 'MG',
    Mh= 'MH',
    Mk= 'MK',
    Ml= 'ML',
    Mm= 'MM',
    Mn= 'MN',
    Mo= 'MO',
    Mp= 'MP',
    Mq= 'MQ',
    Mr= 'MR',
    Ms= 'MS',
    Mt= 'MT',
    Mu= 'MU',
    Mv= 'MV',
    Mw= 'MW',
    Mx= 'MX',
    My= 'MY',
    Mz= 'MZ',
    Na= 'NA',
    Nc= 'NC',
    Ne= 'NE',
    Nf= 'NF',
    Ng= 'NG',
    Ni= 'NI',
    Nl= 'NL',
    No= 'NO',
    Np= 'NP',
    Nr= 'NR',
    /*Nt= 'NT',*/
    Nu= 'NU',
    Nz= 'NZ',
    Om= 'OM',
    Pa= 'PA',
    Pe= 'PE',
    Pf= 'PF',
    Pg= 'PG',
    Ph= 'PH',
    Pk= 'PK',
    Pl= 'PL',
    Pm= 'PM',
    Pn= 'PN',
    Pr= 'PR',
    Ps= 'PS',
    Pt= 'PT',
    Pw= 'PW',
    Py= 'PY',
    Qa= 'QA',
    Re= 'RE',
    Ro= 'RO',
    Rs= 'RS',
    Ru= 'RU',
    Rw= 'RW',
    Sa= 'SA',
    Sb= 'SB',
    Sc= 'SC',
    Sd= 'SD',
    Se= 'SE',
    /*Sf= 'SF',*/
    Sg= 'SG',
    Sh= 'SH',
    Si= 'SI',
    Sj= 'SJ',
    Sk= 'SK',
    Sl= 'SL',
    Sm= 'SM',
    Sn= 'SN',
    So= 'SO',
    Sr= 'SR',
    Ss= 'SS',
    St= 'ST',
    /*Su= 'SU',*/
    Sv= 'SV',
    Sx= 'SX',
    Sy= 'SY',
    Sz= 'SZ',
   /* Ta= 'TA',*/
    Tc= 'TC',
    Td= 'TD',
    Tf= 'TF',
    Tg= 'TG',
    Th= 'TH',
    Tj= 'TJ',
    Tk= 'TK',
    Tl= 'TL',
    Tm= 'TM',
    Tn= 'TN',
    To= 'TO',
    /*Tp= 'TP',*/
    Tr= 'TR',
    Tt= 'TT',
    Tv= 'TV',
    Tw= 'TW',
    Tz= 'TZ',
    Ua= 'UA',
    Ug= 'UG',
    /*Uk= 'UK',*/
    Um= 'UM',
    Us= 'US',
    Uy= 'UY',
    Uz= 'UZ',
    Va= 'VA',
    Vc= 'VC',
    Ve= 'VE',
    Vg= 'VG',
    Vi= 'VI',
    Vn= 'VN',
    Vu= 'VU',
    Wf= 'WF',
    Ws= 'WS',
    Xk= 'XK',
    Ye= 'YE',
    Yt= 'YT',
    /*Yu= 'YU',*/
    Za= 'ZA',
    Zm= 'ZM',
    /*Zr= 'ZR',*/
    Zw= 'ZW'
}
	
export enum CountryToCurrencyMap {
  "EU"= "EUR",
  "US"= "USD",
  "AE"= "AED",
  "AF"= "AFN",
  "AG"= "XCD",
  "AL"= "ALL",
  "AM"= "AMD",
  "AO"= "AOA",
  "AQ"= "N/A",
  "AR"= "ARS",
  "AU"= "AUD",
  "AW"= "AWG",
  "AZ"= "AZN",
  "BA"= "BAM",
  "BB"= "BBD",
  "BD"= "BDT",
  "BF"= "XOF",
  "BG"= "BGN",
  "BH"= "BHD",
  "BI"= "BIF",
  "BM"= "BMD",
  "BN"= "BND",
  "BO"= "BOB",
  "BR"= "BRL",
  "BS"= "BSD",
  "BT"= "BTN",
  "BW"= "BWP",
  "BY"= "BYN",
  "BZ"= "BZD",
  "CA"= "CAD",
  "CD"= "CDF",
  "CF"= "XAF",
  "CH"= "CHF",
  "CL"= "CLP",
  "CN"= "CNY",
  "CO"= "COP",
  "CR"= "CRC",
  "CU"= "CUP",
  "CV"= "CVE",
  "CW"= "ANG",
  "CZ"= "CZK",
  "DJ"= "DJF",
  "DK"= "DKK",
  "DO"= "DOP",
  "DZ"= "DZD",
  "EG"= "EGP",
  "EH"= "MAD",
  "ER"= "ERN",
  "ET"= "ETB",
  "FJ"= "FJD",
  "FK"= "FKP",
  "GB"= "GBP",
  "GE"= "GEL",
  "GH"= "GHS",
  "GI"= "GIP",
  "GM"= "GMD",
  "GN"= "GNF",
  "GT"= "GTQ",
  "GY"= "GYD",
  "HK"= "HKD",
  "HN"= "HNL",
  "HR"= "HRK",
  "HT"= "HTG",
  "HU"= "HUF",
  "ID"= "IDR",
  "IL"= "ILS",
  "IN"= "INR",
  "IQ"= "IQD",
  "IR"= "IRR",
  "IS"= "ISK",
  "JM"= "JMD",
  "JO"= "JOD",
  "JP"= "JPY",
  "KE"= "KES",
  "KG"= "KGS",
  "KH"= "KHR",
  "KM"= "KMF",
  "KP"= "KPW",
  "KR"= "KRW",
  "KW"= "KWD",
  "KY"= "KYD",
  "KZ"= "KZT",
  "LA"= "LAK",
  "LB"= "LBP",
  "LI"= "CHF",
  "LK"= "LKR",
  "LR"= "LRD",
  "LS"= "LSL",
  "LY"= "LYD",
  "MA"= "MAD",
  "MD"= "MDL",
  "MG"= "MGA",
  "MK"= "MKD",
  "MM"= "MMK",
  "MN"= "MNT",
  "MO"= "MOP",
  "MR"= "MRU",
  "MU"= "MUR",
  "MV"= "MVR",
  "MW"= "MWK",
  "MX"= "MXN",
  "MY"= "MYR",
  "MZ"= "MZN",
  "NA"= "NAD",
  "NG"= "NGN",
  "NI"= "NIO",
  "NO"= "NOK",
  "NP"= "NPR",
  "NZ"= "NZD",
  "OM"= "OMR",
  "PA"= "PAB",
  "PE"= "PEN",
  "PF"= "XPF",
  "PG"= "PGK",
  "PH"= "PHP",
  "PK"= "PKR",
  "PL"= "PLN",
  "PY"= "PYG",
  "QA"= "QAR",
  "RO"= "RON",
  "RS"= "RSD",
  "RU"= "RUB",
  "RW"= "RWF",
  "SA"= "SAR",
  "SB"= "SBD",
  "SC"= "SCR",
  "SD"= "SDG",
  "SE"= "SEK",
  "SG"= "SGD",
  "SH"= "SHP",
  "SL"= "SLL",
  "SO"= "SOS",
  "SR"= "SRD",
  "SS"= "SSP",
  "ST"= "STN",
  "SY"= "SYP",
  "SZ"= "SZL",
  "TH"= "THB",
  "TJ"= "TJS",
  "TM"= "TMT",
  "TN"= "TND",
  "TO"= "TOP",
  "TR"= "TRY",
  "TT"= "TTD",
  "TW"= "TWD",
  "TZ"= "TZS",
  "UA"= "UAH",
  "UG"= "UGX",
  "UY"= "UYU",
  "UZ"= "UZS",
  "VE"= "VES",
  "VN"= "VND",
  "VU"= "VUV",
  "WF"= "XPF",
  "WS"= "WST",
  "YE"= "YER",
  "ZA"= "ZAR",
  "ZM"= "ZMW",
  "ZW"= "ZWL"
 }
 


export type { IconProps, ShopIconProps, FlagsIconProps}