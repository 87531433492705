import Container from "react-bootstrap/esm/Container"
import Breadcrumbs, { BreadcrumbItemProps } from "@components/Page/Breadcrumbs"
import { useEffect } from "react"
import styles from '../../brand.module.scss'

type PageHeaderProps = {
    icon?: string | {url: string},
    title: string,
    breadcrumbs?: Array<BreadcrumbItemProps>,
    side?: JSX.Element,
    bottom?: JSX.Element,
    data_cy?: string
}

const PageHeader = (props:PageHeaderProps)=> {
    const {title, icon, breadcrumbs, data_cy, side, bottom} = props

    useEffect(()=>{
        document.title = `${title} - CloudStore`
    },[title])
    
    return (
        <Container fluid className="pb-4">
            <div data-cy={`page-header-${data_cy}`} className="d-flex justify-content-between">
                <div>
                    <div className="d-flex flex-column align-items-left">
                        <h1>{title}</h1>
                        {breadcrumbs && <Breadcrumbs elements={breadcrumbs}/>}
                        {bottom && <div>{bottom}</div>}
                    </div>
                </div>
                {!!icon? <div className="d-flex align-items-center">
                    {(typeof icon)==='string' &&
                        <i className={`${icon} font-4x text-muted`} />
                    }
                    {(icon as {url:string}).url &&
                        <img src={(icon as {url:string}).url} style={{width: '3em', height: '3em', backgroundColor: styles.csWhite, borderRadius: '50%', border: `1px solid ${styles.csMuted}`, padding: '5px'}} />
                    }
                </div>: ''}
                {side && <div>{side}</div>}
            </div>
        </Container>
        
    )
}

export default PageHeader