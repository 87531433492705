const en = {
    __LANGUAGE: "en",
    ACCEDI: "Sign in",
    LOGIN: 'Login',
    RICORDAMI: 'Remember me',
    CREDENZIALI_NON_RICONOSCIUTE: 'Invalid credentials',
    CERCA: 'Search',
    CAMBIA_PASSWORD: "Change password",
    SOTTOUTENTI: "Subusers",
    BILLING: "Billing",
    MODULI: "Modules",
    LOGOUT: "Logout",
    COPIATO: "Copiato!",
    OK: "Ok",
    MODIFICA: "Modifica",
    ELIMINA: "Elimina",
    VISUALIZZA: "Visualizza",
    ORDINA: "Ordina",
    AGGIUNGI: "Add",
    CARICA: "Upload",
    ANNULLA: "Cancel",
    RIFIUTA: "Deny",
    RIPRISTINA: "Restore",
    RIGENERA: "Regenerate",
    SELEZIONA_TUTTI: "check all",
    DESELEZIONA_TUTTI: "Uncheck all",
    CONFERMA: "Confirm",
    ATTENDERE: "Please wait...",
    ATTENZIONE: "Warning",
    ERRORE_GENERICO: "Si è verificato un errore, si prega di riprovare.",
    SELEZIONA_UN_VALORE: "Seleziona un valore",
    SELEZIONA_UN_FILE: "Seleziona un file",
    SI: "Sì",
    NO: "No",
    TUTTI: "Tutti",
    SALES_ORDER: "Ordine di vendita",
    TRANSFER_ORDER:"Ordine di trasferimento",
    SALVA: "Salva",
    E_MAIL: "Email",
    ELIMINAZIONE_IN_CORSO: "Eliminazione in corso...",
    SALVATAGGIO_IN_CORSO: "Salvataggio in corso...",
    OPERAZIONE_AVVENUTA_CON_SUCCESSO: "Operazione avvenuta con successo",
    COPIA_VALORE: "Copia valore",
    PROCEDI: "Procedi",
    PROSEGUI: "Prosegui",
    CAMBIA: "Cambia",
    CREA: "Crea",
    ISTRUZIONI: "Instructions",
    DETTAGLI: "Details",
    MOSTRA: "Show",
    RISULTATI: "__COUNT__ results",
    HOME: "Home",
    INIZIA: "Inizia",
    VERIFICA: "Check",
    SELEZIONA_FILE: "Seleziona file",
    SALE_CHANNEL:"Sale Channel",
    SALES_CHANNEL:"Sale Channels",
    BADGE_SHOP_PRIMARY:"Primary Shop",
    COUNTRY: "Country",
    CONTACTS: {
        EMAIL: "Email",
        PHONE: "Phone",
        MOBILE: "Mobile"
    },
    MENU: {
        IL_TUO_CLOUDSTORE: "Il tuo CloudStore",
        CATALOGO: "Catalogo",
        ORDINI: "Orders",
        SPEDIZIONI: "Spedizioni",
        CLIENTI: "Clienti",
        SHOPS: "Shops",
        ASSOCIAZIONE_MAGAZZINI: "Associazione magazzini",
        MAGAZZINI: "Magazzini",
        LISTA: "Lista",
        LISTA_ARTICOLI: "Lista articoli",
        SHIPMENTS: "Spedizioni",
        LISTING: "Listing",
        ASSOCIAZIONE_ATTRIBUTI: "Associazione attributi",
        AGGIORNAMENTO_DISPONIBILITA: "Availability Update",
        WHS_MOVEMENTS:"Warehouse movements",
        CATEGORY:"Category",
        RETURNS:"Returns",
        GESTIONE_PREZZI: "Prices management",
        CONDIZIONI_COMMERCIALI: "Business conditions",
        MODIFICA_CONDIZIONE_COMMERCIALE: "Edit business condition",
        LOGS:"Activity Log",
        PACKAGING: "Packaging",
        LOGS_SETTINGS:"Activity Log settings",
        RISERVE: "Reservations",
        QUEUES:"Queues",
        REGOLE_ESCLUSIONE_LISTING: "Listing exclusion rules",
        REGOLE_ESCLUSIONE: "Exclusion rules",
        MODIFICA_REGOLA_ESCLUSIONE_LISTING: "Edit listing exclusion rule",
        CUSTOMERS: "Customers",
        MODULI: "Modules",
    },
    PAGE_ACCESS_DENIED: {
        TITLE:"Access Denied",
        SUBTITLE:"Attention you do not have permission to access the requested section",
        BACK_TO_HOME:"Back to homepage"
    },
    PAGE_BUSINESS_CONDITIONS: {
        NUOVA_CONDIZIONE_COMMERCIALE: "nuova condizione commerciale",
        AVVIA_ELABORAZIONE: "Avvia elaborazione",
        TEST_CONDIZIONI_COMMERCIALI: "Esegui un test delle condizioni commerciali",
        TUTTE_LE_CONDIZIONI_COMMERCIALI_APPLICATE: "Tutte le condizioni commerciali sono applicate.",
        DA_ELABORARE: "Ci sono __ELEMENTS__ da elaborare.",
        ASSOCIAZIONI_CON_SHOPS: "associazioni con shops",
        ASSOCIAZIONI_CON_CUSTOMER: "associazioni con customers",
        CONDIZIONI: "condizioni",
        CONDIZIONI_ATTIVE: "Condizioni attive",
        CONDIZIONI_DA_ELABORARE: "Condizioni da elaborare",
        CONDIZIONI_DISABILITATE: "Condizioni disabilitate",
        CONDIZIONI_CANCELLATE: "Condizioni cancellate",
        CONDIZIONI_IN_ELABORAZIONE: "Condizioni in elaborazione",
        TAB_0: "Condizioni attive / da elaborare / disabilitate",
        CONFERMA_DISABILITA: "Confermi la disattivazione della condizione __NAME__?",
        CONFERMA_CANCELLAZIONE: "Confermi la cancellazione della condizione __NAME__?",
        CONFERMA_ABILITA: "Confermi l'abilitazione della condizione __NAME__?",
        CONFERMA_ANNULLAMENTO: "Confermi l'annullamento dell'operazione in corso per la condizione __NAME__?",
        CONFERMA_AVVIO_ELABORAZIONE: "Confermi l'avvio dell'elaborazione delle condizioni commerciali?",
        DATI_GENERALI: "Dati generali",
        NOME: "Nome",
        TAX_RATE: "Tax Rate",
        CONDIZIONE_DI_DEFAULT: "Condizione di default",
        ASSOCIAZIONE_SHOP: "Associazione shop",
        ASSOCIAZIONE_CUSTOMER: "Associazione customer",
        ECCEZIONI: "Eccezioni",
        ASSOCIAZIONE_SHOP_INFO: "In questa sezione puoi associare questa condizione commerciale a uno o più shop.",
        AGGIUNGI_ASSOCIAZIONE: "Aggiungi associazione",
        NUOVA_ASSOCIAZIONE: "Nuova associazione",
        CONFERMA_ASSOCIAZIONE_SHOP: "Confermi l'associazione di questa condizione commerciale agli shop selezionati?",
        CONFERMA_CANCELLAZIONE_ASSOCIAZIONE_SHOP: "Confermi la cancellazione dell'associazione selezionata?",
        ASSOCIAZIONE_CUSTOMER_INFO: "In questa sezione puoi associare questa condizione commerciale a uno o più customer.",
        CONFERMA_ASSOCIAZIONE_CUSTOMER: "Confermi l'associazione di questa condizione commerciale ai customer selezionati?",
        CONFERMA_CANCELLAZIONE_ASSOCIAZIONE_CUSTOMER: "Confermi la cancellazione dell'associazione selezionata?",
        SELEZIONA_UN_CUSTOMER: "Seleziona un customer",
        ECCEZIONI_INFO: "In questa sezione puoi definire delle eccezioni alla condizione di default definita in testata.",
        NUOVA_ECCEZIONE: "Nuova eccezione",
        MODIFICA_ECCEZIONE: "Modifica eccezione",
        SCARICA_TEMPLATE: "Scarica template Excel vuoto",
        SCARICA_ECCEZIONI: "Scarica Excel delle eccezioni configurate",
        CARICA_ECCEZIONI: "Carica Excel delle eccezioni per questa condizione commerciale",
        SELEZIONA_ALMENO_UN_ATTRIBUTO: "Seleziona almeno un attributo su cui applicare la condizione",
        CONFERMA_SALVATAGGIO_CONDIZIONE: "Confermi il salvataggio delle modifiche effettuate?",
        TABLE: {
            NOME: "Nome",
            VARIAZIONE: "Variazione",
            MARKUP: "Markup",
            DISCOUNT: "Sconto",
            NUMERO_ECCEZIONI: "N° Eccezioni",
            STATO: "Stato",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            DISABILITA: "Disabilita",
            CANCELLA: "Cancella",
            RIATTIVA: "Riattiva",
            ANNULLA_OPERAZIONE: "Annulla operazione",
        },
        STATUS: {
            LABEL: {
                PENDING: "Da elaborare",
                PROCESSING: "In elaborazione",
                CONFIRMED: "Confermata",
                TO_BE_DEPRECATED: "Da rendere obsoleta",
                DEPRECATED: "Obsoleta",
                TO_BE_DISABLED: "Da disabilitare",
                DISABLED: "Disabilitata",
                TO_BE_CANCELED: "Da cancellare",
                CANCELED: "Cancellata",
                TO_BE_ENABLED: "Da abilitare",
                    },
            SHORT: {
                PENDING: "Questa condizione è in attesa di essere confermata e attivata.",
                PROCESSING: "Questa condizione è attualmente in fase di elaborazione.",
                CONFIRMED: "Questa condizione è confermata e attualmente in vigore.",
                TO_BE_DEPRECATED: "Questa condizione è stata impostata per essere resa obsoleta durante la prossima elaborazione.",
                DEPRECATED: "Questa condizione è obsoleta e non è più in vigore.",
                TO_BE_DISABLED: "Questa condizione è stata impostata per essere disabilitata durante la prossima elaborazione.",
                DISABLED: "Questa condizione è attualmente disabilitata e non viene applicata.",
                TO_BE_CANCELED: "Questa condizione è stata impostata per essere cancellata durante la prossima elaborazione.",
                CANCELED: "Questa condizione è cancellata.",
                TO_BE_ENABLED: "Questa condizione è stata impostata per essere abilitata durante la prossima elaborazione.",
            },
            EXTENDED: {
                PENDING: "LONG DESCRIPTION PENDING",
                PROCESSING: "LONG DESCRIPTION PROCESSING",
                CONFIRMED: "LONG DESCRIPTION CONFIRMED",
                TO_BE_DEPRECATED: "LONG DESCRIPTION TO_BE_DEPRECATED",
                DEPRECATED: "LONG DESCRIPTION DEPRECATED",
                TO_BE_DISABLED: "LONG DESCRIPTION TO_BE_DISABLED",
                DISABLED: "LONG DESCRIPTION DISABLED",
                TO_BE_CANCELED: "LONG DESCRIPTION TO_BE_CANCELED",
                CANCELED: "LONG DESCRIPTION CANCELED",
                TO_BE_ENABLED: "LONG DESCRIPTION TO_BE_ENABLED",
            }
        },
        EXCEPTIONS: {
            SEASON: "Season",
            BRAND: "Brand",
            CATEGORY: "Category",
            SKU_PARENT: "SKU Parent",
            PARTNER_CODE: "Partner",
            VARIAZIONE: "Variazione",
            AZIONI: "Azioni"
        },
        PROCESSING: {
            ELABORAZIONE_IN_CORSO: "Elaborazione in corso...",
            PREPARAZIONE: "Preparazione...",
            ELABORAZIONE_ASSOCIAZIONI_CUSTOMERS: "Elaborazione associazioni customer...",
            ELABORAZIONE_ASSOCIAZIONI_SHOPS: "Elaborazione associazioni shop...",
            AGGIORNAMENTO_STATI_RELAZIONI: "Aggiornamento stati e relazioni...",
            APPLICAZIONE_CONDIZIONI_CREAZIONE_LISTING: "Applicazione condizioni e creazione listing..."
        },
        UPLOAD_RESULTS: {
            RISULTATO_CARICAMENTO: "Risultato del caricamento",
            NO_ROWS_ACCEPTED: "Poiché nessuna riga è stata elaborata correttamente, non sono state apportate modifiche alle eccezioni attuali.",
            SOME_ROWS_ACCEPTED: "Puoi visionare il risultato nella tabella delle eccezioni in questa pagina; è possibile che alcune righe dell'Excel siano state raggruppate per essere gestite con maggior semplicità e chiarezza. Se sei soddisfatto del risultato, ti basterà salvare la condizione commerciale con l'apposito pulsante in alto a destra!",
            EXCEPTIONS_CREATED: "Eccezioni create:",
            ROWS_ERROR: "Righe in errore (scartate):",
            ROWS_WARNING: "Righe con avvisi:",
            RIGA: "Riga",
        }
    },
    PAGE_LISTING_EXCLUSION_RULES: {
        STATUS: {
            LABEL: {
                PENDING: "Da elaborare",
                PROCESSING: "In elaborazione",
                CONFIRMED: "Confermata",
                TO_BE_DEPRECATED: "Da rendere obsoleta",
                DEPRECATED: "Obsoleta",
                TO_BE_DISABLED: "Da disabilitare",
                DISABLED: "Disabilitata",
                TO_BE_CANCELED: "Da cancellare",
                CANCELED: "Cancellata",
                TO_BE_ENABLED: "Da abilitare",
                    },
            SHORT: {
                PENDING: "Questa regola è in attesa di essere confermata e attivata.",
                PROCESSING: "Questa regola è attualmente in fase di elaborazione.",
                CONFIRMED: "Questa regola è confermata e attualmente in vigore.",
                TO_BE_DEPRECATED: "Questa regola è stata impostata per essere resa obsoleta durante la prossima elaborazione.",
                DEPRECATED: "Questa regola è obsoleta e non è più in vigore.",
                TO_BE_DISABLED: "Questa regola è stata impostata per essere disabilitata durante la prossima elaborazione.",
                DISABLED: "Questa regola è attualmente disabilitata e non viene applicata.",
                TO_BE_CANCELED: "Questa regola è stata impostata per essere cancellata durante la prossima elaborazione.",
                CANCELED: "Questa regola è cancellata.",
                TO_BE_ENABLED: "Questa regola è stata impostata per essere abilitata durante la prossima elaborazione.",
            },
            EXTENDED: {
                PENDING: "LONG DESCRIPTION PENDING",
                PROCESSING: "LONG DESCRIPTION PROCESSING",
                CONFIRMED: "LONG DESCRIPTION CONFIRMED",
                TO_BE_DEPRECATED: "LONG DESCRIPTION TO_BE_DEPRECATED",
                DEPRECATED: "LONG DESCRIPTION DEPRECATED",
                TO_BE_DISABLED: "LONG DESCRIPTION TO_BE_DISABLED",
                DISABLED: "LONG DESCRIPTION DISABLED",
                TO_BE_CANCELED: "LONG DESCRIPTION TO_BE_CANCELED",
                CANCELED: "LONG DESCRIPTION CANCELED",
                TO_BE_ENABLED: "LONG DESCRIPTION TO_BE_ENABLED",
            }
        },
        PROCESSING: {
            ELABORAZIONE_IN_CORSO: "Elaborazione in corso...",
            PREPARAZIONE: "Preparazione delle regole in corso...",
            ELABORAZIONE: "Elaborazione delle regole in corso...",
            APPLICAZIONE: "Applicazione delle regole in corso...",
            FINALIZZAZIONE: "Impostazione stati finali e creazione dei listing in corso..."
        },
        TABLE: {
            NOME: "Nome regola",
            DATA_CREAZIONE: "Data creazione",
            DATA_MODIFICA: "Data modifica",
            STATO: "Stato",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            DISABILITA: "Disabilita",
            CANCELLA: "Cancella",
            RIATTIVA: "Riattiva",
            ANNULLA_OPERAZIONE: "Annulla operazione",
        },
        TUTTE_LE_REGOLE_APPLICATE: "Tutte le regole sono applicate.",
        DA_ELABORARE: "Ci sono __COUNT__ regole da elaborare.",
        REL_NON_COMPLETA: "Questa regola non verrà applicata in quanto non sono state inserite le condizioni o non è stata specificata la lista degli shop esclusi",
        CONFERMA_AVVIO_ELABORAZIONE: "Confermi l'avvio dell'elaborazione delle regole?",
        NUOVA_REGOLA: "Nuova regola",
        AVVIA_ELABORAZIONE: "Avvia elaborazione delle regole",
        TEST_REGOLE: "Esegui un test sulle regole",
        REGOLE_ATTIVE: "Regole attive",
        REGOLE_DA_ELABORARE: "Regole da elaborare",
        REGOLE_DISABILITATE: "Regole disabilitate",
        REGOLE_CANCELLATE: "Regole cancellate",
        REGOLE_IN_ELABORAZIONE: "Regole in elaborazione",
        TAB_0: "Regole attive / da elaborare / disabilitate",
        CONFERMA_DISABILITA: "Confermi la disattivazione della regola __NAME__?",
        CONFERMA_CANCELLAZIONE: "Confermi la cancellazione della regola __NAME__?",
        CONFERMA_ABILITA: "Confermi l'abilitazione della regola __NAME__?",
        CONFERMA_ANNULLAMENTO: "Confermi l'annullamento dell'operazione in corso per la regola __NAME__?",
        NOME: "Nome",
        CONFERMA_SALVATAGGIO_REGOLA: "Confermi il salvataggio delle modifiche effettuate alla regola?",
        DATI_GENERALI: "Dati generali",
        CONDIZIONI: "Condizioni",
        SHOP_ESCLUSI: "Shop esclusi",
        STORICO: "Storico",
        HISTORY: {
            NESSUN_ELEMENTO_DISPONIBILE: "Nessun elemento disponibile.",
            CREATE_GUI: "La regola è stata creata sull'applicazione CloudStore.",
            CREATE_ENGINE: "La regola è stata creata dal motore di regole CloudStore durante il passaggio:",
            CREATE_API: "La regola è stata creata dalle API CloudStore.",
            UPDATED_GUI: "La regola è stata modificata sull'applicazione CloudStore.",
            UPDATED_ENGINE: "La regola è stata modificata dal motore di regole CloudStore durante il passaggio:",
            UPDATED_API: "La regola è stata modificata dalle API CloudStore.",
            STEPS: {
                _0: "sconosciuto",
                _1: "preparazione delle regole",
                _2: "elaborazione delle regole",
                _3: "applicazione delle regole",
                _4: "impostazione stati finali e creazione dei listing",
            }
        },
        EXCLUDED_SHOPS_INFO: "In questa sezione puoi scegliere a quali shop applicare l'esclusione dai listing definita nella regola che stai modificando.",
        SELEZIONA_SHOP_ESCLUSI: "Seleziona gli shop da escludere",
        NUOVA_CONDIZIONE: "Nuova condizione",
        MODIFICA_CONDIZIONE: "Modifica condizione",
        SCARICA_TEMPLATE: "Scarica template Excel vuoto",
        SCARICA_CONDIZIONI: "Scarica Excel delle condizioni configurate",
        CARICA_CONDIZIONI: "Carica Excel delle condizioni per questa regola",
        CONDIZIONI_INFO: "In questa sezione puoi definire le condizioni affinché la regola di esclusione risulti valida.",
        CONDITIONS: {
            SEASON: "Season",
            BRAND: "Brand",
            CATEGORY: "Category",
            SKU_PARENT: "SKU Parent",
            OPERATORE: "Operatore",
            VALORE: "Valore",
            CONFERMA_AGGIUNGI_A_CONDIZIONE: "Confermi l'aggiunta dei valori richiesti alla condizione?",
            CONFERMA_AGGIUNGI_A_CONDIZIONE_HELP: "L'aggiunta non verrà salvata in automatico! Potrai sempre decidere di modificare le condizioni prima del salvataggio.",
            VALORE_PRESENTE_IN_CLOUDSTORE: "Questo valore è già presente in qualcuno degli articoli del tuo inventario.",
            VALORE_NON_PRESENTE_IN_CLOUDSTORE: "Questo valore non è ancora censito in nessuno degli articoli del tuo inventario.",
            NESSUN_VALORE_SELEZIONATO: "Nessun valore selezionato.",
            CATEGORIE_SELEZIONATE: "Ci sono __COUNT__ categorie selezionate. Per visualizzarle, utilizzare il selettore qui sopra.",
        },
        UPLOAD_RESULTS: {
            RISULTATO_CARICAMENTO: "Risultato del caricamento",
            NO_ROWS_ACCEPTED: "Poichè nessuna condizione caricata è risultata valida, le condizioni attuali rimangono immutate.",
            SOME_ROWS_ACCEPTED: "Puoi visualizzare il risultato del caricamento nei relativi box di questa pagina.",
        }
    },
    PAGE_MODULES: {
        STATUS:{
            LABEL:"Status",
            ACTIVE:"Enabled",
            INACTIVE:"Not enabled",
            NOT_APPLICABLE: "Not applicable",
            PENDING: "Pending",
            VALIDATING: "Validating",
            CONFIRMED: "Confirmed",
            AUTH_ERROR: "Authentication error",
            ERROR: "Error",
        },
        TITLES: {
            BUSINESS_CONDITIONS: "Condizioni Commerciali",
            B2B: "B2B",
            POS: "POS",
            ERP: "ERP",
            FINANCE: "Finance",
            FxFF: "Fullfilment by Farfetch",
            WAREHOUSES: "Magazzini",
            NETSUITE: "Integrazione NetSuite",
            MARKETPLACE_SAVAIT: "Marketplace Savait",
            DETAILS_TITLE:"Module details"

        },
        TITLES_DETAILS: {
            BUSINESSCONDITIONS:"Business Conditions",
            B2B: "B2B Module",
            POS: "POS module",
            ERP: "ERP Module",
            FINANCE: "Finance module",
            FXFF: "Fullfilment Module by Farfetch",
            WAREHOUSES: "Warehouses Module",
            NETSUITE: "NetSuite Integration",
            MARKETPLACESAVAIT: "Marketplace Savait Integration",
            MARKETPLACE_SAVAIT: "Marketplace Savait Integration",
        },
        DESCRIPTIONS: {
            BUSINESS_CONDITIONS: "Questo modulo permette di gestire in maniera completa le informazioni dei prezzi di un articolo sia a livello di listini che a livello di condizioni commerciali.",
            B2B: "È un'applicazione che consente di vendere i prodotti del tuo catalogo a clienti B2B attraverso le funzionalità tipiche di un canale di vendita, come la gestione dell'anagrafica clienti e la gestione del flusso ordini. Compatibile con PC e tablet.",
            POS: "È un'applicazione dedicata al mongo Retail che consente di integrare un negozio fisico nell'ecosistema di CloudStore attraverso semplici funzionalità come la creazione di un ordine e la gestione dell'anagrafica di un cliente. Permette inoltre di avere monitorare le giacenze presente in altri negozi/magazzini così da poter fornire al cliente finale un'esperienza completa.",
            ERP: "Il modulo ERP è progettato per fornire funzionalità specifiche e dedicate a chi integra il proprio ERP con CloudStore attraverso le CloudStore API.",
            FINANCE: "Questo modulo permette di stimare le componenti tasse sui prezzi indicati negli ordini privi di tale informazione, durante la fase di ricezione ordine dagli shop.",
            FxFF: "L'integrazione permette la gestione dei canali Farfetch per i quali è attivo il servizio di Fulfillment by Farfetch (FxFF).",
            WAREHOUSES: "Il modulo magazzini mette a disposizione diverse funzionalità e aree che l'utente può usare per gestire in maniera più ampia e personalizzata l'inventario e la pubblicazione degli articoli sugli shop.",
            NETSUITE: "L'integrazione implementa processi di inbound e outbound di dati tra gli account di CloudStore e di NetSuite al fine di sincronizzare clienti, articoli, ordini, dati di fatturazione, dati di spedizione e movimenti di magazzino.",
            MARKETPLACE_SAVAIT: "Questo modulo abilita un account CloudStore alle funzionalità tipiche di un Marketplace quali la gestione del catalogo da fornitori multipli e lo smistamento degli ordini ai vari partner.",
        },
        DESCRIPTIONS_LONG: {
            BUSINESSCONDITIONS: "This module fully manages the pricing information of an item, both at the level of price lists and commercial conditions. It is possible to manage different price lists to be associated with Shops to define selling prices and discounted prices of each item, as well as to define commercial conditions reserved for both Shops (whether they represent retailers or not) and Customers who will purchase through the CloudStore B2B application.",
            B2B: "This application allows you to sell products from your catalog to B2B customers through the typical features of a sales channel, such as customer record management and order flow management. Compatible with PCs and tablets. Order creation: The module enables you to create orders from the catalog by adding items to the cart. The 'Orders from Showroom' feature is particularly interesting as it allows you to create an order during a guided visit in your showroom with an interface specifically optimized for mobile devices. Pre-orders: Give your customers the opportunity to create pre-orders which you can integrate and confirm, specifying discounts and quantities. Item card: The item card is designed to group variations of an item by specifying the variation attribute, thus facilitating the consultation of available quantities for each variation. Quick order: With one click, all available quantities of all catalog variations of an item will be added to the order. Customer records: Independently manage your customers' records by giving them access to the portal via username and password.",
            POS: "It is an application dedicated to Retail management that allows integrating a physical store into the CloudStore ecosystem through simple functionalities such as creating an order and managing customer records. It also allows monitoring of inventory levels in other stores/warehouses, thus providing the end customer with a complete experience.",
            ERP: "The ERP module is designed to provide specific functionalities dedicated to those who integrate their ERP with CloudStore through CloudStore APIs. It implements logic related to quantity updates, facilitates the retrieval of information such as warehouse movements related to orders, and prepares dedicated queues for communicating specific information (such as orders) in order to define a more precise flow and a more comprehensive relationship between the ERP and CloudStore.",
            FINANCE: "This module estimates the tax components on the prices indicated in orders lacking such information during the order receipt phase from shops. The estimate is made based on certain parameters defined by the user, particularly tax percentages and tax classes. Through the tax percentages, tax rates to be applied under certain conditions related to the geographical location of the order's shipping address are defined. Through the tax classes, tax classes to which the tax percentages are associated are defined. It is necessary to specify for each product in inventory the tax class to apply, in order to allow the calculation of the estimate.",
            FXFF: "The integration allows the management of Farfetch channels for which the Fulfillment by Farfetch (FxFF) service is active. During the creation of a Farfetch-type channel, it is possible to determine whether the FxFF service is active and in that case, it is necessary to specify the reference warehouse.",
            WAREHOUSES: "The warehouses module offers various functionalities and areas that the user can use to manage inventory and publication of articles on shops in a broader and customized manner. Warehouse records: List of warehouses used for which it is also possible to specify a client code and priority. Strategy: Various strategies are available that allow CloudStore to decide (or not) the picking warehouse for the goods following the arrival of an order. Advanced features: It will be possible to enable/disable warehouses on individual shops and/or enable/disable the warehouses on individual articles. In this way, it will be possible to customize the actual availability that you want to publish on a specific Shop.",
            NETSUITE: "This integration, optionally activatable in your CloudStore account, enables communication of CloudStore with your Oracle NetSuite account. The integration implements inbound and outbound data processes between the two accounts to synchronize customers, items, orders, billing data, shipping data, and warehouse movements. Synchronization in both directions is supported for various entities, depending on which of the two systems is the master of that specific information. Communication occurs through activity queues, processed by the CloudStore engine and consultable and verifiable through the user interface, through which the operator can reprocess unsuccessful communications. CloudStore interacts with NetSuite through its APIs, operating where possible directly with the CRUD of the involved entities, or obtaining information through saved searches. For the proper functioning of the integration and to manage a richer informational set than the base present in NetSuite, the integration uses Custom Field, Custom Record, and Custom List that must be configured in NetSuite (the configuration of these elements in the NetSuite account is entrusted to the Oracle technological partner of the Customer). The integration is highly customizable by the CloudStore Team through a rich set of configuration parameters and through custom code. To optimize performance, the processes of the integration are typically executed in parallel.",
            MARKETPLACESAVAIT: "This module enables a CloudStore account to the typical functionalities of a Marketplace such as managing the catalog from multiple suppliers and routing orders to various partners."
        },
        B2B: {
            SHOP_PRIMARIO: "Shop primario",
            SHOP_SECONDARI: "Numero shop secondari",
        },
        POS: {
            NUMERO_SHOP: "Numero shop",
            SHOP_ASSOCIATIONS:"Shop associations"
        },
        WAREHOUSES: {
            ALGO_DESC:{
                NO_OP_DESC:"when an order arrives, CloudStore does not perform any operation to choose the goods picking warehouse. The quantities of the warehouses will therefore remain unchanged. Only the quantity of the bucket is updated.",
                WHS_PRIORITY_DESC:"when an order arrives, CloudStore will choose the picking warehouse for each item present in the order itself based on the warehouse priorities set by the user. The warehouse with the lowest priority value will be chosen. EX: warehouses with priority 1 is more priority than the warehouse with priority 2. The quantity of the bucket is also updated.",
                TEMP_COMMIT_DESC:"when an order arrives, CloudStore adds the items and quantities present in the order to the TEMPORARY COMMIT virtual warehouse. The TEMPORARY COMMIT is taken into consideration in the calculation of the quantities to send to the channel. The quantity of the bucket is also updated .",
            },
            SELECT_ALGO: "Select Algorithm for warehouses module",
            ALGORITMO: "Algoritmo",
            NOBODY :"",
            NO_OP:"No operation",
            WHS_PRIORITY:"Warehouse Priority",
            TEMP_COMMIT:"Support warehouse",
            SHOP_DRIVEN:"",
        },
        NETSUITE: {
            STATO: "Stato",
        },
        ACTIVATE_MODULE:"Module activation",
        UPDATE_DATE:"",
        ENABLE_DISABLED:"Enable / disabled module",
        GENERAL_CONFIG: "General configurations",
        UPDATE_CONFIRM:"You want to continue with the update of the __MODULE__ module",
        ERROR_LOADING:"An error occurred while loading module, please try again or contact CloudStore support",
    },
    PAGE_PACKAGING: {
        SELEZIONA_UN_ORDINE: "Seleziona un ordine",
        SELEZIONA_UN_MAGAZZINO: "Seleziona un magazzino...",
        SELEZIONA_UN_COMPUTER: "Seleziona un computer di stampa...",
        SELEZIONA_UNA_STAMPANTE_ZEBRA: "Seleziona una stampante zebra...",
        SELEZIONA_UNA_STAMPANTE_STANDARD: "Seleziona una stampante standard...",
        SELEZIONA_UNA_STAMPANTE_DI_SERVIZIO: "Seleziona una stampante di servizio...",
        SELEZIONA_UN_MAGAZZINO_INFO: "Per iniziare, seleziona un magazzino nelle opzioni in alto a destra.",
        LEGGI_UN_ARTICOLO: "Leggi il codice a barre di un articolo per identificare un ordine da lavorare.",
        ARTICOLO_NON_IN_ORDINE: "L’articolo letto non appartiene a quest’ordine.",
        ARTICOLO_COMPLETATO: "L'articolo letto è già stato completamente confermato in quest'ordine.",
        ARTICOLO_IN_NESSUN_ORDINE: "Non è stato possibile trovare ordini da inscatolare che contengono questo articolo.",
        ARTICOLO_IN_ORDINE_PACKAGED: "L’articolo appartiene a un ordine che è già stato confermato ma non stampato. Vuoi andare alla pagina di dettaglio dell’ordine per procedere con la stampa?",
        ARTICOLO: "Articolo",
        MAGAZZINO: "Magazzino",
        SCATOLA: "Scatola",
        SELEZIONA_UNA_SCATOLA_ORDINE: "Seleziona una scatola per l'ordine",
        SELEZIONA_UNA_SCATOLA_RIGHE: "Seleziona una scatola per ciascuna riga dell'ordine",
        QUANTITA_CONFERMATA: "Quantità confermata",
        QUANTITA_RICHIESTA: "Quantità richiesta",
        SELEZIONA_UNA_SCATOLA: "Seleziona una scatola...",
        SELEZIONA_SCATOLE: "Seleziona scatole",
        CONFERMA_SCATOLA: "Conferma scatola",
        CHIUDI_LAVORAZIONE: "Chiudi lavorazione",
        CHIUDI_ORDINE: "Chiudi ordine",
        STAMPA_TUTTI: "Stampa tutti",
        STAMPA_DI_SERVIZIO: "Stampa di servizio",
        RICHIESTA_CONFERMA_NO_SCATOLE: "Non hai ancora confermato la scelta della scatola per questo ordine",
        RICHIESTA_CONFERMA: "Prima di chiudere la lavorazione di questo ordine, controlla di avere stampato i documenti necessari.",
        PRINT_ERRORS: {
            TITLE: "Si sono verificati i seguenti errori in fase di stampa:",
            GENERIC: "Generico",
            DOCUMENT_NOT_PDF: "Il documento richiesto non è un pdf",
            ORDER_DOCUMENTS_MISSING: "Manca il documento nell'ordine",
            URL_NOT_FOUND: "URL del documento non trovata",
            UNAUTHORIZED: "Accesso al documento non autorizzato",
            CETTIRE_NO_ORDER: "Ordine Cettire non confermato e quindi non disponibile per la stampa",
            NO_ORDER_ITEM_ID: "Order item id non presente",
            CONFIRM_ORDER_ITEM: "Si è verificato un errore nella conferma della riga dell'ordine Cettire.",
            UNKNOWN_ERROR: "Errore sconosciuto",
            SERVICE: "Stampa di servizio",
        },
        ZEBRA_ERRORS: {
            OK: "La stampante funziona correttamente.",
            PAPER_OUT: "Carta assente o terminata",
            RIBBON_OUT: "Ribbon assente o terminato",
            MEDIA_DOOR_OPEN: "Sportello aperto",
            CUTTER_FAULT: "Errore nel cutter",
            PRINTHEAD_OVERHEATING: "Testina di stampa surriscaldata",
            MOTOR_OVERHEATING: "Motore surriscaldato",
            PRINTHEAD_FAULT: "Errore nella testina di stampa",
            INCORRECT_PRINTHEAD: "Testina di stampa non corretta",
            PRINTER_PAUSED: "Stampante in pausa",
            ERRORE_SCONOSCIUTO: "Errore sconosciuto. Controllare che la stampante Zebra sia accesa e collegata correttamente.",
        },
        CETTIRE: {
            ALMENO_UNA_RIGA_DA_CONFERMARE: "Per stampare è necessario confermare almeno un articolo per il magazzino selezionato.",
            NON_TUTTI_CONFERMATI_PER_WH: "Non tutti gli articoli sono completamente confermati per il magazzino selezionato. Vuoi procedere ugualmente con la stampa?",
        }
    },
    PAGE_RESERVATIONS: {
        AGGIUNGI_RISERVA: "Aggiungi riserva",
        ELIMINA_RISERVE: "Elimina riserve",
        SCARICA_EXCEL: "Scarica Excel",
        ARTICOLI: "__COUNT__ articoli",
        SELEZIONARE_SHOP_SKU_PARENT: "Selezionare shop e sku parent",
        TABLE: {
            ARTICOLO: "Articolo",
            PREZZO: "Prezzo",
            MAGAZZINO: "Magazzino",
            SHOPS: "Shop",
            QTY: "Qty",
            DATA_CREAZIONE: "Data creazione",
            DATA_MODIFICA: "Data modifica",
            QTY_RENDER: "__QTY__ di __WH_QTY__",
            SHOP_TOOLTIP: "Clicca per andare alla pagina dello shop __SHOP_NAME__, alt+click per filtrare le riserve per questo shop",
            WH_TOOLTIP: "Premi alt+click per filtrare le riserve per questo magazzino",
        },
        SHOP_SKU_PARENT_VALIDI: "Gli shop e lo sku parent selezionati sono validi.",
        VALIDAZIONE_IN_CORSO: "Validazione in corso...",
        NEW_RESERVATION_CHECK_ERRORS: {
            SHOP_NON_IN_BUCKETS: "I seguenti shop non appartengono a nessuno dei bucket disponibili all'utente:",
            ARTICOLO_NON_TROVATO: "Articolo non trovato.",
            SKU_CON_BUCKET_MANCANTI: "I seguenti sku non sono disponibili ad alcuni dei bucket utilizzati dagli shop selezionati:",
            SKU_CON_SHOP_ESCLUSI: "I seguenti sku hanno, tra gli shop esclusi dai listing, almeno uno degli shop selezionati:",
            WHS_NON_DISPONIBILI: "Non sono disponibili magazzini per gli shop selezionati.",
        },
        NEW_RESERVATION_RESULTS: {
            QUANTITA: "Quantità richiesta",
            ESEGUITI: "Operazioni di riserva eseguite",
            SCARTATI: "Operazioni di riserva scartate",
            IN_ERRORE: "Operazioni di riserva in errore",
        },
        SELEZIONA_MASSIMA_QUANTITA_DISPONIBILE: "Riserva la massima quantità disponibile nel magazzino",
        ATTIVI_SE_SHOP_SKU_PARENT_VALIDATI: "Attivi una volta selezionati e validati gli shop e lo sku parent da utilizzare per la nuova riserva.",
        SELEZIONARE_MAGAZZINO_E_QUANTITA: "Selezionare un magazzino e definire la quantità da riservare.",
        SELEZIONARE_MAGAZZINO_MAX_QTY: "Selezionare un magazzino; verrà riservata la quantità massima disponibile in esso.",
    },
    PAGE_SHOPS: {
        FILTRA_SUCCESSO: "Mostra gli shops confermati",
        FILTRA_ERRORE: "Mostra gli shops in errore generico",
        FILTRA_ERRORE_AUTENTICAZIONE: "Mostra gli shop in errore di autenticazione",
        FILTRA_DISABILITATI: "Mostra gli shops disabilitati",
        FILTRA_IN_ELABORAZIONE: "Mostra gli shops in elaborazione",
        NUOVO_SHOP: "Nuovo Shop",
        TIPO_SHOP: "Tipo di shop",
        NOME_SHOP: "Nome shop",
        TABLE: {
            NOME: "Nome",
            TIPO: "Tipo",
            STATO: "Stato",
            ABILITATO: "Abilitato",
            DISABILITATO: "Disabilitato",
            CODICE: "Codice",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            COPIA_ID: "Copia ID",
            COPIA_MERCHANT_ID: "Copia Merchant ID",
            COPIA_TOKEN: "Copia Token",
            COPIA_BEARER: "Copia Bearer Token",
            ATTIVA: "Attiva shop",
            DISATTIVA: "Disattiva shop",
            CONFERMA_DISATTIVAZIONE_SHOP: "Confermi la disattivazione dello shop __SHOP__?",
            CONFERMA_ATTIVAZIONE_SHOP: "Confermi l'attivazione dello shop __SHOP__?",
            SHOP_ATTIVATO: "Shop __SHOP__ attivato correttamente.",
            SHOP_DISATTIVATO: "Shop __SHOP__ disattivato correttamente.",
            MIINTO_API: "Miinto API",
            COPIA_URL: "Copy URL",
            COPIA_USERNAME: "Copy username",
            COPIA_PASSWORD: "Copy password",
            COPIA_X_API_KEY: "Copia x_api_key",
        }
    },
    PAGE_SHOP: {
        MODIFICA_SHOP: "Modifica shop",
        TIPO_SHOP: "Tipo shop",
        NOME: "Nome",
        CUSTOMER_CODE: "Customer code",
        CONFIGURAZIONI_GENERALI: "Configurazioni generali",
        CONFIGURAZIONI_SPECIFICHE: "Configurazioni specifiche",
        ALTRE_CONFIGURAZIONI: "Altre configurazioni",
        ENDPOINT_PER_SCARICO_CATALOGO: "Endpoint per scarico catalogo",
        CREDENZIALI: "Credenziali",
        LISTINI: "Listini",
        LISTINO_PIENO: "Listino pieno",
        LISTINO_SCONTATO: "Listino scontato",
        ACCESS_KEY: "Access key",
        SECRET_KEY: "Secret key",
        BRAND_LABEL: "Brand label",
        SELLER_LABEL: "Seller label",
        SHIPPING_POINT: "Shipping point",
        GENERALE: "Generale",
        DEFAULT_CURRENCY: "Default currency",
        LISTING: "Listing",
        FLUSSI: "Flussi",
        CONFIGURAZIONE_FLUSSI_E_LISTING: "Configurazione flussi e listing",
        STRATEGIA_MAGAZZINI: "Strategia magazzini",
        SOVRASCRIVI_CONFIGURAZIONE_ACCOUNT: "Sovrascrivi la configurazione dell'account",
        STRATEGIA: "Strategia",
        CONFERMA_CREAZIONE: "Confermi la creazione dello shop?",
        CONFERMA_MODIFICA: "Confermi le modifiche allo shop?",
        CREAZIONE_AVVENUTA_CON_SUCCESSO: "Creazione dello shop __NAME__ avvenuta con successo",
        MODIFICA_AVVENUTA_CON_SUCCESSO: "Modifica allo shop __NAME__ avvenuta con successo",
        TOKEN: "Token",
        ORDER_PRICE:"Order price",
        CREDENZIALI_SHOP_API:"Shop API Credentials",
        INBOUND_ORDER_PRICE_CHECK: "Controlla prezzo durante scarico ordini",
        INBOUND_ORDER_PRICE_CHECK_TOLERANCE_PERC: "Tolerance in price control",
        API_CREDENTIALS:"API Credentials",
        SFTP_CREDENTIALS:"SFTP Credentials",
        OTHER_CONFIGURATIONS:"Other Configurations",
        _24S:{
            USERNAME:"Username",
            PASSWORD:"Password",
            LANGUAGES:"Select one or more languages"  
        },
        CHANNEL: {
            ERRORE_SALVATAGGIO: "Si è verificato un errore nel salvataggio dei seguenti shop: __SHOPLIST__",
        },
        MIINTO: {
            IDENTIFIER: "Identificativo",
            SECRET: "Secret",
            PUBLISH_ONLY_ITEMS_WITH_QUANTITY: "Pubblica solo articoli con quantità",
            LANGUAGES: "Lingue",
            PRICEBOOKS: "Listini",
            TOKEN: "Token",
            URL: "URL",
            USERNAME: "Username",
            PASSWORD: "Password",
        },
        AMAZON: {
            AMAZON_EUROPE: "Amazon Europe",
            MARKETPLACE: "Marketplace",
            CLIENT_ID: "Client ID",
            MERCHANT_ID: "Merchant ID",
            CLIENT_SECRET: "Client Secret",
            REFRESH_TOKEN: "Refresh token",
            CONFERMA_RIMOZIONE_SHOP: "Confermi la rimozione del marketplace __MARKETPLACE__?",
            SELEZIONARE_ALMENO_UN_MARKETPLACE: "Selezionare almeno un marketplace",
        },
        WINKELSTRAAT: {
            X_API_KEY: "x_api_key",
            TOKEN_CHANNABLE: "Token Channable",
            PROJECT_ID_CHANNABLE: "Project ID Channable",
            SKU_PREFIX: "SKU Prefix",
            WEBHOOKS_TITLE: "Webhooks",
            WEBHOOKS: {
                SHIPMENT: "Shipment",
                SHIPMENT_TRACK: "Shipment track",
                RMA_ITEMS: "RMA items",
            },
            URL: "URL",
            USERNAME: "Username",
            PASSWORD: "Password",
        },
        ITALIST:{
            PARTIAL_ORDER:"Enable partial order",
            API_KEY: "api key",
            STORE_KEY:"store key",
        },
        CETTIRE:{
            USERNAME: "Username",
            PASSWORD: "Password",
            USER_ID:"User Id",
        },
        MIRAKL:{
         API_KEY: "Api key",
         SHOP_ID: "Shop Id",
        },
        FARFETCH:{
            STORE_ID:"Store Id",
            SAFE_KEY:"Secret Key",
            GOT_TO_SHOP:"Dettaglio dello shop",
            MERCHANT_CODE:"Merchant Code",
            MERCHANT_CODE_EXIST: "Value already exists in another Farfetch",
            VPI_PORT_ERROR:"port must be less than or equal to 65535",
            VPI:{
                TITLE:"Vendor Provider Imagery",
                ENABLED:"Abilita Vendor Provider Imagery",
                HOSTNAME:"Host name",
                PORT:"Port",
                USENAME:"Username",
                PASSWORD:"Password",
            },
            FXFF:{
                TITLE:"Fulfillment",
                ENABLED:"Enabled Fulfillment by Farfetch", 
                LOGISTIC_CENTER:"Logistic Center",
                LOGISTIC_CENTER_IT:"Italy",
                LOGISTIC_CENTER_HK:"Hong Kong",
                LOGISTIC_CENTER_US:"United States",
            },
            SANDBOX:"Sandbox",
            B2B:"B2B",
            ROLE:"Role",
            TABLE_SEC:{
                TITLE:"List of connected secondary shops",
                NOME:  "Shop name",
                CODICE:"Code",
                COPY_ID:"Copy Shop ID",
                SHOP_ID_COPY: "Shop id of __FF__ copy!",
                GOT_TO_SHOP:"Dettaglio dello shop",
            },
            PRIMARY_SHOP:"Primary Shop",
        }
    },
    PAGE_WAREHOUSES: {
        NUOVO_MAGAZZINO: "New Warehouse",
        FILTRA_SYSTEM: "Show system-defined warehouses",
        FILTRA_USER: "Show user-defined warehouses",
        TABLE: {
            NOME: "Name",
            STATO: "Status",
            PRIORITA: "Priority",
            CODICE: "Code",
            DEFINITO_DA: "Defined by",
            VAI_A_DETTAGLIO: "Go to detail",
            COPIA_ID: "Copy ID",
            ELIMINA: "Delete Warehouse",
            CONFERMA_RIMOZIONE_MAGAZZINO: "Do you confirm the deletion of the warehouse __WAREHOUSE__?",
            RIMOZIONE_MAGAZZINO_SUCCESS: "The warehouse __WAREHOUSE__ has been successfully deleted",
            ADDRESS_LINE: "Address",
            CITY: "City",
            PROVINCE: "Province",
            PROVINCE_CODE: "Province Code",
            POSTAL_CODE: "Postal Code",
            COUNTRY_CODE: "Country Code",
            MODIFY: "Modify Warehouse",
        },
        OPERATION_SUCCESS: "Warehouse saved successfully",
        OPERATION_ERROR: "Error saving warehouse"
    },
    PAGE_CUSTOMERS:{
        CUSTOMER_DETAILS:"Dettaglio Cliente",
        COPIA_ID: "Copy ID",
        NEW_CUSTOMER:"New Customer",
        CUSTOMER:"Customer",
        RETURN:"Return",
        LOADING: "loading...",
        NOT_FOUND: "Not found",
        ON_ERROR: "on error",
        CREATION_DATE:"Creation Date",
        ORDERS_MADE:"Orders placed in the period",
        UPDATED_DATE:"Updated Date",
        ORDER_DATE: "Order date",
        SHIPPING_ADDRESSES:"Shipping addresses",
        ADD_NEW_ADDRESS:"Add new address",
        MODIFY_ADDRESS:"Modify shipping address",
        SHIPPING_ADDRESSES_DEFAULT:"Default address",
        TABS:{
            OVERVIEW:{
                TITLE:"Overview",
                ORDERS_AMOUNT: "Orders Amount",
            },
            DETAILS:{
                TITLE:"Details",
                UPDATE_MESSAGE:"You want to proceed with update customer",
            },
            BC:{
                TITLE:"Business Conditions",
                EMPTY_BOX:"No business conditions associated with the user were found",
                TL_CREATION_DATE:"Business conditions creation date",
                TL_LAST_UPDATE_DATA:"Last update date of the business conditions"
            },
            ADD_INFO:{
                TITLE:"Additional Information",
            },
            PWD_MAN:{
                TITLE:"Password Manager",
                E_D_USER:"Enabled / Disabled User",
                RESET_PWD:"Reset Password",
                RESET_PWD_MESSAGE:"You want to proceed with resetting the password of the customer __CUSTOMER__",
                CHANGE_STATUS_MESSAGE:"You change the customer's status to __STATUS__",
                NO_USERNAME:"The customer's username is not present"
            },
        },
        STATUS :{
            ACTIVE:"Active",
            INACTIVE: "Inactive",
        },
        TABLE:{
            USERNAME:" User Name",
            NAME:"Name",
            SURNAME:"Surname",
            CODE :"Username / Customer Code",
            COUNTRY:"Country",
            STATUS:"Status",
            EMAIL:"Email",
            EMAILPEC:"Email pec",
            PHONE:"Phone Number",
            CELL:"Cell Number",
            ANAGRAFICA_CLIENTE: "Customer Record",
            BILLING_ADDRESS: "Billing Address",
            SHIPPING_ADDRESS: "Shipping Address",
            CF: "Tax Code",
            SDI: "SDI Code",
            VAT: "VAT Code",
            ADDRESS_LINE: "Address",
            CITY: "City",
            VAI_A_DETTAGLIO: "Go to detail",
            PROVINCE: "Province",
            PROVINCE_CODE: "Province Code",
            POSTAL_CODE: "Postal Code",
            COUNTRY_CODE: "Country",
            SWITCH_DESC: "The billing address matches the shipping address"
        },
        CONTACT_NAME:"Contact name",
        OPERATION_SUCCESS: "Customer saved successfully",
        OPERATION_ERROR: "Error saving customer",
        ATLEAST_1_STATUS:"At least one status must be selected",
        RESET_PWD_SUCCESS:"Password reset successful",
        CHANGE_STATUS_SUCCESS:"Customer status change successful",
        CONFIRM_DELETE_ADDRESS:"Delete address?",
        ADD_CUSTOMER_ADDRESS:"Add customer address",
        CUSTOMER_ADDRESS_LIST:"Customer's shipping address list"
    },
    PAGE_WHS_MOVEMENTS: {
        FILTRO_AVANZATO: "Advanced Filter",
        NEW_MOVEMENTS: "New movements",
        SCARICA_MOVIMENTI:"Download template",
        CARICA_MOVIMENTI:"Update movement",
        CREATION_PROGRESS:"Movement creation in progress",
        CREATION_SUCCESS:"Movement creation in progress",
        CREATION_ERROR:"Error creating movement",
        TABLE: {
            DATE: "Date",
            ORDER: "Order",
            BRAND: "Brand",
            QUANTITY: "Quantity",
            TYPE: "Tipo",
            WAREHOUSE: " Warehouse",
            TAGS: "Tags",
            COPIA_ID: "Copy ID",
            COPIA_ID_ORDER: "Copy Order ID",
            TYPE_ABSOLUTE:"Absolute",
            TYPE_DIFFERENTIAL:"Differential",
            SKU: "SKU",
        },
        ADD_WHR_MOVEMENTS:{
            TITLE: "New warehouse movements",
            SKU: "SKU",
            SKU_PLACEHOLDER: "insert SKU",
            WAREHOUSE :"Warehouse",
            WAREHOISE_PLACEHOLDER: "Select Warehouse",
            TYPE_PLACEHOLDER: "Select Type",
        }, 
        DOWNLOAD_MODAL_TEXT: {
            TITLE:"Instructions",
            DESCRIPTION: "By clicking the button below, you will download the CSV template for bulk stock movement upload.",
            COLUMNS: "The file consists of the following columns:",
            SKU: "represents the item's SKU. Mandatory.",
            WH_ID: "CloudStore warehouse identifier. Optional.",
            WH_ID_INFO: "Ignored if WH_ID is provided.",
            WH_CODE:"warehouse customer code.",
            WH_CODE_2: "Ignored if WH_ID is entered.",
            QTY: "quantity moved. Can be negative or positive. Mandatory.",
            QTY_2: "If TYPE = 'A', then the value must be positive.",
            TYPE: "movement type: A = Absolute, D = Differential. Mandatory.",
            TAGS: "list of values separated by ','. Used to assign additional labels to the movement. Optional.",
            ALERT: "One of the two values between WHI_ID and WH_CODE must be provided.",
            OPTIONAL: "Optional",
            MANDATORY: "Mandatory",
            ATTENTION:"Attention"
       },
        UPLOAD_MODAL:{
             TITLE:"Upload new warehouses movements",
             UPLOAD_SUCCESS:"Upload success"
       }

    },
    PAGE_SHIPMENTS :{
        ORDINI: "Orders",
        NESSUN_ORDINE_PRESENTE: "No order found.",
        SHIPMENT :"Shipment",
        NUOVA_SPEDIZIONE: "New Shipment",
        FILTRO_AVANZATO: "Advanced Filter",
        VAI_A_DETTAGLIO: "Goto details",
        APRI_ANTEPRIMA: "Show preview",
        COPIA_ID: "Copy ID",
        FILTERS: {
            ORDER_TYPE: "Tipologia di ordine",
            ORFRT: "N° ordine"
        },
        TABLE: {
            ORDER: "Order",
            CARRIER: "Carrier",
            STATUS: "Status",
            TRACKING_NUMBER: "N° Tracking",
            SHIPPING_DATE: "Shipping Date",
            DELIVERY_DATE: "Delivery Date",
            DELIVERY_METHOD: "Delivery Method",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            COPIA_ID: "Copia ID",
            ORDER_POPUP_TITLE: "Orders ID",
            TRACKING_URL: "Tracking URL",
        },
        PREVIEW: {
            RECIPIENT_INFO: "Recipient Information",
            NAME : "Name",
            SURNAME : "Surname",
            SHIPMENT_INFO: "Shipment Information",
            COMPANY: "Company",
            BUYER: "Buyer",
            ADDRESS: "Address",
        }
    },
    PAGE_CATALOG: {
        NUOVO_ARTICOLO: "Nuovo articolo",
        FILTRO_AVANZATO: "Filtro avanzato",
        FILTERS: {
            BRAND: "Brand",
            CATEGORIE: "Categorie",
            STAGIONI: "Stagioni",
            CANALE_DI_VENDITA: "Canale di vendita",
            QUANTITY: "Quantità",
            SELEZIONA_LE_CATEGORIE: "Seleziona le categorie...",
            CATEGORIE_SELEZIONATE: "Categorie selezionate"
        }
    },
    PAGE_BINDINGS: {
        SELEZIONARE_UNO_SHOP: "Selezionare uno shop per iniziare",
        ATTRIBUTI_COMPLETAMENTE_ASSOCIATI: "Attributi completamente associati",
        SELEZIONARE_UN_ATTRIBUTO: "Selezionare un attributo dall'elenco",
        NUOVO_ATTRIBUTO: "Aggiungi un nuovo attributo",
        NUOVA_REGOLA: "Aggiungi una nuova regola",
        REGOLE_PER_ATTRIBUTO: "Regole per l'attributo",
        FILTRA_ASSOCIAZIONI_ATTIVE: "Visualizza le associazioni già attive",
        INDOVINA_ASSOCIAZIONI: "Autocompila le associazioni cercando di dedurre i valori dello shop da selezionare in base ai valori CloudStore",
        RIPRISTINA_ASSOCIAZIONI: "Annulla tutte le modifiche correnti e riporta le associazioni allo stato iniziale",
        AGGIUNGI_ASSOCIAZIONE: "Aggiungi una associazione inserendo un valore CloudStore non presente",
        SE_SI_VERIFICANO_CONDIZIONI: "Se si verificano le seguenti condizioni",
        LE_ASSOCIAZIONI_SONO_LE_SEGUENTI: "Le associazioni sono le seguenti",
        REGOLA_DEFAULT_NESSUNA_CONDIZIONE: "Questa è la regola di default. Non si possono aggiungere condizioni, le associazioni saranno sempre attive se le altre regole non hanno validità.",
        NESSUNA_CONDIZIONE: "Nessuna condizione definita. Le associazioni sono sempre valide.",
        BINDINGS_TABLE: {
            VALORE_CS: "Valore CloudStore",
            VALORE_SHOP: "Valore Shop"
        },
        CONFERMA_GUESS_BINDINGS: "Il sistema proverà a compilare automaticamente i valori degli shop non ancora associati a partire dai corrispondenti valori CloudStore. Vuoi proseguire?",
        CONFERMA_RIPRISTINO_BINDINGS: "Se prosegui, tutte le modifiche non salvate alle associazioni andranno perdute."
    },
    PAGE_ORDERS: {
        SCARICA_ORDINI: "Download Orders",
        ORDINE: "Order",
        STATO_ORDINE: "Order status",
        DATA: "Date",
        CONTROVALORE: "Total value",
        NUMERO_ARTICOLI: "Items quantity",
        APRI_ANTEPRIMA: "Show preview",
        VAI_A_DETTAGLIO: "Go to details",
        COPIA_ID: "Copy ID",
        COPIA_CODICE_ORDINE: "Copy order code",
        INFORMAZIONI_ACQUIRENTE: "Buyer info",
        SPEDIZIONE: "Shipping",
        FATTURAZIONE: "Billing",
        ORDINI_TROVATI: "__COUNT__ orders found",
        STATUS_CHANGE: "Change order status",
    },
    PAGE_AVAILABILITY_UPDATES:{
        TABLE:{
            DATE : "Date",
            SHOP : "Shop",
            QUANTITY: "Quantity",
            REASON: "Reason",
            STATE:"State",
            MESSAGE: "Message",
            COMPLETED :"Completed",
            INCOMPLETED: "Incompleted",
            LISTING: "Listing",
            MOVEMENT: "Movement" 
        }

    },
    PAGE_ORDER: {
        ORDINE: "Order",
        DETTAGLIO_ORDINE: "Order details",
        IN_CARICAMENTO: "loading...",
        NON_TROVATO: "not found",
        IN_ERRORE: "in error",
        ORDINE_NON_TROVATO: "The order searched was not found.",
        ORDINE_IN_ERRORE: "There was an error in loading the order, please try again.",
        PANORAMICA: "Panoramica",
        ARTICOLI: "Articoli",
        INFORMAZIONI: "Informazioni",
        MOVIMENTAZIONI: "Movimentazioni",
        DOCUMENTI: "Documenti",
        NETSUITE: "NetSuite",
        METADATA: "Metadata",
        DATA: "Data",
        SKU: "SKU",
        ARTICOLO: "Articolo",
        BRAND: "Brand",
        QUANTITA: "Quantità",
        PREZZO_UNITARIO: "Prezzo unitario",
        PREZZO_TOTALE: "Prezzo totale",
        SUBTOTALE: "Subtotale",
        TOTALE_ORDINE: "Totale ordine",
        SPESE_DI_SPEDIZIONE: "Spese di spedizione",
        SCONTO: "Sconto",
        MARKUP: "Markup",
        TIPO: "Tipo",
        MAGAZZINO: "Magazzino",
        TAGS: "Tags",
        ASSOLUTO: "Assoluto",
        DIFFERENZIALE: "Differenziale",
        MOVIMENTI_MAGAZZINO: "Movimenti di magazzino",
        COPIA_ID_MAGAZZINO: "Copia l'ID del magazzino",
        ID_MAGAZZINO_COPIATO: "ID del magazzino __WH__ copiato!",
        UTENTE: "Utente",
        MODULO: "Modulo",
        SHOP: "Shop",
        AGGIORNAMENTI_DISPONIBILITA: "Aggiornamenti disponibilità",
        STATO: "Stato",
        MESSAGGIO: "Messaggio",
        NUOVO_DOCUMENTO: "Nuovo documento",
        DOCUMENTI_DI_TIPO: "Documenti di tipo",
        ELIMINA_DOCUMENTO: "Elimina documento",
        STAMPA_DOCUMENTI_SELEZIONATI: "Stampa documenti selezionati",
        SELEZIONA_PER_LA_STAMPA: "Seleziona col tasto destro, rimuovi col tasto sinistro",
        TIPO_DOCUMENTO: "Tipo documento",
        NESSUN_DOCUMENTO_DISPONIBILE: "Nessun documento disponibile per quest'ordine.",
        SELEZIONA_MODALITA_CARICAMENTO: "Seleziona la modalità di caricamento del documento:",
        VIA_FILE: "Via file",
        VIA_URL: "Via URL",
        SELEZIONA_FILE_DA_CARICARE: "Seleziona il file da caricare",
        URL_DA_CARICARE: "Url da caricare",
        ELIMINA_DOCUMENTO_CONFERMA: "Sei sicuro di voler eliminare il documento",
        DOCUMENTO_ELIMINATO: "Documento eliminato con successo",
        CARICAMENTO_IN_CORSO: "Caricamento in corso...",
        NOME: "Nome",
        EMAIL: "Email",
        ID: "ID",
        CHANGE_ORDER_SATUS:"Enter the new order status",
        CANCEL:"Cancel",
        CHANGE_STATUS:"Change status",
        CHANGE_STATUS_CONFIRM_MESSAGE:"You want to proceed with the change of status in __STATUS__",
        CHANGE_STATUS_SUCCESS:"Change of status successful",
        SHIPPING_INFO: {
            NOME_AZIENDA: "Nome azienda",
            NOME: "Nome",
            COGNOME: "Cognome",
            CONTATTO_1: "Contatto 1",
            CONTATTO_2: "Contatto 2",
            VIA: "Via / piazza / altro",
            NUMERO: "N°",
            CITTA: "Città",
            CAP: "CAP",
            PROVINCIA: "Provincia",
            NAZIONE: "Nazione",
        },
        BILLING_INFO: {
            NOME_AZIENDA: "Nome azienda",
            NOME: "Nome",
            COGNOME: "Cognome",
            PARTITA_IVA: "Partita IVA",
            CODICE_FISCALE: "Codice fiscale",
            PIVA: "VAT",
            CF: "F.C.",
            CONTATTO_1: "Contatto 1",
            CONTATTO_2: "Contatto 2",
            VIA: "Via / piazza / altro",
            NUMERO: "N°",
            CITTA: "Città",
            CAP: "CAP",
            PROVINCIA: "Provincia",
            NAZIONE: "Nazione",
            ID_FATTURA: "ID fattura",
            NUMERO_FATTURA: "Numero fattura",
            DATA_FATTURA: "Data fattura",
            TOTALE_FATTURA: "Totale fattura",
            URL: "Url",
            METODO_PAGAMENTO: "Metodo di pagamento"
        },
        TAB_NETSUITE: {
            ID_ELEMENTO: "ID Elemento",
            CODICE_ESTERNO: "Codice esterno",
            DATA_CREAZIONE: "Data creazione",
            DATA_ULTIMO_AGGIORNAMENTO: "Data ultimo aggiornamento",
            STATO: "Stato",
            NUMERO_ESECUZIONI: "Numero di esecuzioni",
            NOME_SHOP: "Nome shop",
            ESEGUI_ELEMENTO: "Esegui elemento",
            INVIA_ORDINE: "Invia ordine a NetSuite",
            INVIA_ORDINE_CONFERMA: "Confermi l'invio dell'ordine a NetSuite?",
            INVIA_ORDINE_ESEGUITO: "L'ordine è stato messo in coda per l'invio a NetSuite.",
            INVIA_ORDINE_NON_ESEGUITO_GIA_ESECUZIONE: "L'ordine è già in coda per l'invio a NetSuite.",
        },
        TAB_METADATA: {
            NESSUN_METADATO_DISPONIBILE: "Nessun metadato disponibile.",
            FINANCE_MODULE_DATA: "Dati del modulo Finance",
            TAXES_SOURCE: "Origine delle tasse",
            CALCOLATE_DALLO_SHOP: "Calcolate dallo shop",
            CALCOLATE_DA_CLOUDSTORE: "Calcolate da CloudStore",
            TASSAZIONE_SPESE_DI_SPEDIZIONE: "Tassazione spese di spedizione",
            TASSAZIONE_ARTICOLI: "Tassazione articoli",
            OPZIONI_DI_PROCESSAMENTO: "Opzioni di processamento",
            ORDINE_PARZIALE: "Ordine parziale",
            MAGAZZINI_SELEZIONATI_PER_SCARICO: "Magazzini selezionati per lo scarico",
            CONTROLLO_PREZZI_INBOUND_ORDER: "Controllo prezzi Order Inbound",
            PRICE_WARNING: "Price Warning",
            UNIT_PRICE_WARNING: "Unit price Tax Excluded Warning",
            TOTAL_PRICE_WARNING: "Total price Tax Excluded Warning",
            RIUSCITO: "Riuscito",
            NON_RIUSCITO: "Non riuscito",
            ACCETTATO_DA_CLOUDSTORE: "Accettato da CloudStore",
            NON_ACCETTATO_DA_CLOUDSTORE: "Non accettato da CloudStore",
            CONTROLLO_DISPONIBILITA_EFFETTIVA_ARTICOLI: "Controllo disponibilità effettiva articoli",
            ESITO: "Esito",
            MESSAGGIO_DI_ERRORE: "Messaggio di errore",
        },
        SALVATAGGIO_INFORMAZIONI_SPEDIZIONE_COMPLETATO: "Salvataggio informazioni di spedizione completato con successo!",
        SALVATAGGIO_INFORMAZIONI_FATTURAZIONE_COMPLETATO: "Salvataggio informazioni di fatturazione completato con successo!",
        INFORMAZIONI_AGGIUNTIVE: "Informazioni aggiuntive",
        NESSUNA_INFORMAZIONE_AGGIUNTIVA: "Nessuna informazione aggiuntiva per questo ordine.",
        DETTAGLI_ULTIMO_INVIO_A_NETSUITE: "Dettagli ultimo invio a NetSuite",
        NESSUN_INVIO_A_NETSUITE: "Nessun invio a NetSuite disponibile per questo ordine.",
        NESSUN_ARTICOLO_PRESENTE: "Non sono presenti articoli nell'ordine.",
        ARTICOLO_NON_PRESENTE: "Articolo non presente nell'inventario.",
        APPLICA_A_SKU_STESSO_PARENT: "Applica a tutte le variazioni dello stesso prodotto presenti nell'ordine",
    },
    PAGE_CATEGORY :{
        CATEGORY:"Category",
        TABS:{
            SUBCATEGORY:{
                TITLE:"Subcategory",
                SUBCATEGORY_OF:"Subcategory list of",
                SUBCATEGORY_NEW:"New SubCategory",
            },
            SHOPS:{
                TITLE:"Shops associations",
                NO_CAT_SHOP:"No mapping was found for the category",
                DELETE_CAT_TITLE:"Do you want to delete the selected category?",
                SELECT_CAT:"Select category",
                TITLE_CAT_SHOP:"Add news category association",
                ADD_NEW_CAT_SHOP: "Add news category association",
                SELECTED_CAT:"Category",
            },
            TRANSLATIONS:{
                TITLE:"Translations",
                TRANSLATION: "Traslation",
                COLUMNS : {
                    LANG:"Lingua",
                    TRANSLATION:"Traduzioni"
                },
                NEW_TRANSLATION:"Add new translation",
                NEW_LANGUAGE:"Select language",
                NEW_LANGUAGE_TO_ADD:"New language to add",
                NEW_TRANSLATION_ADD_CONFERMATION:"Are you sure you want to add the new translations?",
                REMOVE_TRANSLATION_CONFIRMATION:"Are you sure you want to delete the translation?",
                ADD_NEW_CAT_TRANSLATION: "Press the button to add news translations",
            }
        },
        SELECT_CAT_FROM_LIST: "Search or select category from the search menu",
        SEARCH_CAT_TITILE:"Serch categories",
        SEARCH_PLACEHOLDER:"At least 3 characters",
        ADD_CATEGORY:{
            TITLE: 'Add new category',
            NEW_CAT:'New category name',
            SUCCESS:"New category added successfully",
        },
        UPDATE_SHOP_CATEGORY:{
            TITLE: 'Browse the categories of the selected shop',
            SELECTED_CATEGORY:'Selected category',
            SELECTED_CATEGORY_NEW : 'Select new category',
        },
        REMOVE_CATEGORY: "Remove Category",
        REMOVE_CATEGORY_TEXT: "Warning: You are about to remove the category __CATEGORY__. Proceeding with this operation will delete both the category and all connected subcategories.",
        REMOVE_CATEGORY_SUCCESS:"Category __CATEGORY__ removal successful",
    },
    PAGE_SHOPS_WAREHOUSES_BINDINGS: {
        MAGAZZINI_NON_SELEZIONATI: "I seguenti shop non hanno nessun magazzino selezionato",
        CONFERMA_SALVATAGGIO: <><p>Stiamo per aggiornare la configurazione dei magazzini; questa operazione comporta il ricalcolo delle quantità disponibili per lo Shop per il quale si sta cambiando la configurazione dei magazzini. L'operazione potrebbe richiedere un po' di tempo ed è monitorabile dalle code dei task a lunga esecuzione.</p><p>Contestualmente è possibile creare la procedura di aggiornamento massivo delle quantità sul canale di vendita. È preferibile <strong>NON</strong> eseguire questa operazione se il canale di vendita è appena stato attivato e non possiede ancora un catalogo pubblicato.</p><p><strong>Vuoi comunque procedere alla sincronizzazione del canale di vendita?</strong></p></>,
        SALVA_SENZA_SINCRONIZZARE: "Salva senza sincronizzare",
        SALVA_E_SINCRONIZZA: "Salva e sincronizza",
        SALVATAGGIO_AVVENUTO_CORRETTAMENTE: "Configurazione salvata correttamente",
        EVIDENZIA_RIGA: "Evidenzia questa riga",
        EVIDENZIA_COLONNA: "Evidenzia questa colonna",
        SELEZIONA_RIGA: "Seleziona questa riga (alt+click)",
        SELEZIONA_COLONNA: "Seleziona questa colonna (alt+click)",
        NOTIFY_SAVE_ERRORS: "Si sono verificati i seguenti errori nell'esecuzione dell'operazione",
        SALVATAGGIO_SHOP: "Salvataggio shop",
        RICALCOLO_QUANTITA: "Ricalcolo quantità",
        AGGIORNAMENTO_CANALI: "Aggiornamento canali di vendita",
        NOME_SHOP: "Nome shop",
        STEP: "Step",
        ERRORE: "Errore"
    },
    PAGE_RETURNS:{
        DETAILS: "Return details",
        RETURN:"Return",
        LOADING: "loading...",
        NOT_FOUND: "Not found",
        ON_ERROR: "on error",
        INFO: "Return information",
        ORIGIN_ORDER: "Origin order",
        REASON:"Reason",
        RESOLUTION:"Resolution",
        ITEMS: "Items",
        TABLE:{
            QUANTITY:"Quantity",
            STATUS:"Status",
            DATE:"Date",
            CREATION_DATE:"Creation date",
            ID:"Identifier",
            RETURN:"Return",
            CUSTOMER_CODE: "Customer code",
            UPDATE_DATE:"Update date",
            VAI_A_DETTAGLIO: "Goto details",
        }
    },
    PAGE_WAREHOUSES_MOVEMENTS: {
        WARNING_MANDATORY_FILTERS: "Selezionare il filtro data e almeno uno tra i filtri sku e magazzino."
    },
    PAGE_LOGS:{
        TRACKING:{
            TRACKING:"Tracking",
            ELEMENT_CREATION:"Element Creation",
            ELEMENT_PROCESSING: "Element Processing"
        },
        SETTINGS:{
            NEW_SETTINGS:"Add new configuraztion",
            FREQUENCY:"Frequency",
            SEARCH_TERMS:"Search terms",
            NO_SERCH_TERMS:"No serch terms",
            GROUP:"Group",
            LEVELS:"Levels",
            EMAILS: "Emails",
            COMPONENT:"Component",
            INSERT_MAILS: "Emails address",
            MODIFY_CONFIG:"Modify configuration",
            DELETE_CONFIG:"Delete configuration",
            DELETE_CONFIRM_MESSAGE:"Proceed to delete the activity log configuration",
            DELETE_CONFIRM:"Configuration removal successful",
        },
        WAREHOUSES_DETAILS:"Warehouse details"
    },
    PAGE_QUEUES:{
        SEARCH_TERM_PLACEHOLDER:"Search queue",
        FAVORITES:"Favorite",
        CORE:"Core queues",
        MODULE:"Module queues",
        EXTERNAL:"External queues",
        SEARCH_RESULTS:"Search results",
        RERUN_QUEUE:"Rerun queues",
        QUEUE_ERROR:"The requested queue was not found.",
        QUEUE_NOT_FOUND:"An error occurred while loading the queue, please try again.",
        QUEUE_EMPTY_BOX:"No items found for the selected queue",
        QUEUE_EMPTY_EXECUTIONS_DETAILS:"No executions were found for the specific queue",
        PANORAMICA: "Panoramica",
        NO_FAVORITES1 : "No favorites selected. To add queues to favorites click on the ",
        NO_FAVORITES2 : "icon of the queue itself",
        REWORK_CONFERMATIONS:"Are you sure you want to rework the selected queue item?",
        REWORK_CONFERMATIONS_ERROR:"Error reprocessing queue item",
        TABLE: {
            CREATION_DATE:"Creation date",
            UPDATE_DATE:"Update date",
            GO_TO_DETAIL: "Go to detail",
            COPY_ID: "Copy ID",
            EXECUTIONS: "Executions",
            STATUS: "Status",
            STATUS_ELABORATED:"Elaborato",
            STATUS_PENDING: "Pending",
            STATUS_TO_BE_PROCESSED: "Processed",
            STATUS_PROCESSED_SUCCESSFUL:"Process completed",
            STATUS_PROCESSED_FAILED: "Process failed",
            RECORDS: "Records #",
            GOTO_DETAILS:"Go to details",
            REWORK:"Rework again",
            PROCESSING_TRAKING:"processing tracking",
            EXTERNAL_CODE:"External code"
        },
        STATUS: {
            TO_BE_PROCESSED: "Pending",
            PROCESSING: "Processing",
            PROCESSED_SUCCESSFUL: "Processed",
            PROCESSED_FAILED: "Failed"
        },
        DETAILS: {
            TAB_INFO:"Information",
            TAB_INFO_TITLE_1:"Queue Information",
            TAB_INFO_TITLE_2:"Queue Execution",
            TAB_PAYLOAD:"Payload",
            TAB_DETAIL:"Detail",
            EXTERNAL_CODE:"External code",
            CREATION_DATE:"Creation date",
            UPDATE_DATA:"Last update date",
            STATUS:"Status",
            REPORT:"Report",
            TIMELINE_ELEMENT:"There is no information for this specific execution"
        }
    },
    TABLE: {
        PAGINA_NUMERO_DI: "Pagina __NUMBER__ di __TOTAL__",
        RISULTATI: "risultati",
        RISULTATI_FILTRATI_DA: "risultati (filtrati da __TOTAL__ totali)",
        VAI_A: "Vai a...",
        PAGINA: "Pagina",
        NESSUN_RISULTATO: "Nessun risultato.",
        SI_E_VERIFICATO_UN_ERRORE: "Si è verificato un errore, clicca sul pulsante sotto per riprovare."
    },
    DATE_PICKER: {
        DEFAULT_DATE_RANGES: {
            OGGI: "Oggi",
            IERI: "Ieri",
            ULTIMA_SETTIMANA: "Ultima settimana",
            PRIMO_MESE: "Dal primo del mese",
            MESE_PRECEDENTE: "Nel mese precedente"
        },
        SALVA_FILTRO_PERSONALIZZATO: "Salva filtro personalizzato"
    },
    REACT_SELECT: {
        PLACEHOLDER: "Select a value...",
        NESSUN_RISULTATO: "No results found."
    },
    CONFIGURAZIONE: {
        NUOVA_CONFIGURAZIONE: "New Configuration"
    },
    FILTERS: {
        SELEZIONARE_ALMENO_UN_FILTRO: "Selezionare almeno un filtro per visualizzare i risultati.",
        NESSUN_RISULTATO: "Nessun risultato trovato.",
        ERRORE: "Si è verificato un errore, si prega di riprovare."
    },
    ADVANCED_FILTERS: {
        FILTRO_AVANZATO: "Filtro avanzato",
        LABELS: {
            BRAND: "Brand",
            CATEGORIE: "Categorie",
            STAGIONI: "Stagioni",
            CANALE_DI_VENDITA: "Canale di vendita",
            QUANTITY: "Quantità",
            SELEZIONA_LE_CATEGORIE: "Seleziona le categorie...",
            CATEGORIE_SELEZIONATE: "Categorie selezionate",
            DATA: "Data",
            SKU: "Sku",
            STATO: "Stato",
            ORDER_TYPE : "Type of order",
            ORDER : "Order",
            ORDERS : "Orders in",
            WAREHOUSES: 'Warehouses',
            SELEZIONA_ELEMENTI: "Seleziona gli elementi...",
            ELEMENTI_SELEZIONATI: "Elementi selezionati",
            COMPONENTS:"Components",
            GROUP:"Group",
            CUSTOMER:"Customer",
        },
        NESSUN_FILTRO_APPLICATO: "Nessun filtro applicato.",
        ERRORE_CARICAMENTO_FILTRI: "Si è verificato un errore nel caricamento dei filtri.",
        SHOP: "Shops in ",
        BRAND: "Brands in ",
        SEASON: "Seasons in ",
        E_ALTRI: " and others",
        E_ALTRE: " and others",
        DATA: "Data",
        DATA_DA: "da",
        DATA_A: "a",
        IL_GIORNO: "il giorno",
        QUANTITA_NO: "NON devono avere quantità disponibili",
        QUANTITA_SI: "devono avere almeno una quantità disponibile",
        FILTRI_PERSONALIZZATI: "Filtri personalizzati",
        NESSUN_FILTRO_PERSONALIZZATO: "Nessun filtro personalizzato disponibile.",
        SALVA_FILTRO_PERSONALIZZATO: "Salva filtro personalizzato",
        SALVA_FILTRO_PLACEHOLDER: "Custom filter",
        APPLICA: "Applica",
        ANNULLA: "Annulla",
        RESETTA: "Resetta",
        FILTRO_NON_APPLICATO: "Filtro non ancora applicato",
        CATEGORIES: "Categories in",
        SKU: "Skus in",
        QUALSIASI_STATO: "All statuses",
        IN_STATO: "In status"
    },
    DOCUMENT_TYPES: {
        COMMERCIAL_INVOICE: "Fattura",
        AIRWAY_BILL: "Lettera di vettura aerea",
        RETURN_INSTRUCTIONS: "Istruzioni di reso",
        RETURN_LABEL: "Etichette per il reso",
        CITES_DECLARATION: "Dichiarazione di Washington",
        ORDER_DESCRIPTION: "Descrizione dell'ordine",
        SHIPPING_DESCRIPTION: "Descrizione della spedizione",
        SHIPPING_LABEL: "Etichette di spedizione",
        OTHER: "Indefinito",
    },
    INPUT_TAGS:{
        ADD_TAGS:"Add a tag",
        REMOVE:"Click to remove",
        TITLE: "Tags"
    },
    QUEUES: {
        STATUSES: {
            TO_BE_PROCESSED: "In attesa",
            PROCESSING: "In esecuzione",
            PROCESSED_SUCCESSFUL: "Elaborato",
            PROCESSED_FAILED: "Fallito"
        },
        CARD:{
            ACCOUNT:"Account",
            SHOP:"Shop",
            AMAZON: "Amazon",
            BC:"Business Conditions",
            WHS:"Warehouses",
            ERP:"ERP Module",
            NETSUITE:"NetSuite",
            SAVAIT:"Savait",
            FXFF:"FXFF",
            TON:"Tony",
            RETAILPRO:"RetailPro",
            EFASHION:"e-Fashion",
            NEW_EXTERNAL:"New external queues",
            THE_CORNER:"The Corner",
            NEW_QUEUE:"Nuove code"
        },
        DESCRIPTIONS: {
            UNKNOWN_QUEUE: "Custom Queue",
            INTERNAL_QUEUE_IN_CLOUDSTORE: "Internal Queue in CloudStore",
            EXTERNAL_QUEUE_TO_CLOUDSTORE: "External Queue to CloudStore",
            ITEMS_TO_BE_PROCESSED: "Items to be processed",
            ITEMS_IN_ERROR: "Items in error",
            NO_ERROR: "No errors!",
            DEFAULT_DESCRIPTION: "There is no description available for the queue",
            OUTBOUND: {
                MOD_M2_PRICE_QUANTITY: {
                    LABEL: "Outbound prices and quantities",
                    DESCRIPTION: "Export of price and quantity information to the ESB."
                },
                MOD_M2_ITEMS: {
                    LABEL: "Outbound items",
                    DESCRIPTION: "Export of item registry information to the ESB."
                },
                TON_ITEM_NS: {
                    LABEL: "Outbound item",
                    DESCRIPTION: "Export of item registry from CloudStore to NetSuite"
                },
                SVT_ORDER: {
                    LABEL: "Outbound orders to Account Partner",
                    DESCRIPTION: "Outbound partner orders from Marketplace Account to Account Partner"
                },
                SVT_AC2SS: {
                    LABEL: "Outbound orders to Savait Servant",
                    DESCRIPTION: "Outbound partner orders from Marketplace Account to Savait Servant"
                },
                ERP_ORDERS: {
                    LABEL: "Outbound sales orders",
                    DESCRIPTION: "Sending of sales orders received from CloudStore to the connected ERP"
                },
                NS_OUTBOUND_SALES_ORDERS: {
                    LABEL: "Outbound sales orders",
                    DESCRIPTION: "Sending to NetSuite of sales orders received from CloudStore"
                },
                NS_OUTBOUND_TRANSFER_ORDERS: {
                    LABEL: "Outbound transfer orders",
                    DESCRIPTION: "Sending to NetSuite of transfer orders received from CloudStore"
                },
                OTHER_OUTBOUND_PACKAGES: {
                    LABEL: "Sending packaging information",
                    DESCRIPTION: "Sending of packaging information to Shops with this flow active",
                    EXTERNAL_KEY: "Order to be shipped",
                    PARTITON_ID:"Shop"
                },
                OTHER_OUTBOUND_SHIPMENTS: {
                    LABEL: "Sending shipments",
                    DESCRIPTION: "Sending of shipments to Shops with this flow active"
                },
                OTHER_OUTBOUND_RETURN: {
                    LABEL: "Sending returns without RMA",
                    DESCRIPTION: "Sending of information related to returns without RMA to Shops with this flow active"
                },
                OTHER_OUTBOUND_RETURN_REQUEST: {
                    LABEL: "Return request response",
                    DESCRIPTION: "Sending of the response to return requests to Shops with this flow active"
                },
                AMZN_FEED_REQUEST_FULFILLMENT: {
                    LABEL: "Feed shipments",
                    DESCRIPTION: "Sending of shipment information"
                },
                AMZN_FEED_REQUEST_INVENTORY: {
                    LABEL: "Feed inventory",
                    DESCRIPTION: "Sending inventory item updates"
                },
                AMZN_FEED_RESPONSE: {
                    LABEL: "Feed response",
                    DESCRIPTION: "Reading Amazon's responses to various feeds"
                },
                TON_OUTBOUND_NS:{
                    LABEL: "Element outgoing to NetSuite",
                    DESCRIPTION: "Element outgoing to NetSuite"
                },
                MKPLSVT_SS_ORDER:{
                    LABEL:"Outbound Orders to SCSC",
                    DESCRIPTION:"Outbound Orders to SCSC",
                },
                MKPLSVT_ORDER:{
                    LABEL:"Outbound Orders to CS Connector",
                    DESCRIPTION:"Outbound Orders to CS Connector",
                }
            },
            INBOUND: {
                TON_RP: {
                    LABEL: "Inbound warehouse movements",
                    DESCRIPTION: "Import of warehouse movements generated by Retail Pro"
                },
                EFASHION_ITEMS: {
                    LABEL: "Inbound items from eFashion",
                    DESCRIPTION: "Processing of items to be imported into CloudStore from eFashion"
                },
                NS_INBOUND_CASHSALE_INVOICE: {
                    LABEL: "Inbound invoices / receipts",
                    DESCRIPTION: "Import of information related to receipts and invoices"
                },
                NS_INBOUND_CUSTOMER: {
                    LABEL: "Inbound customers",
                    DESCRIPTION: "Import of customers who have access to the B2B module"
                },
                NS_INBOUND_FULFILLMENTS: {
                    LABEL: "Inbound shipments",
                    DESCRIPTION: "Import of information related to shipments"
                },
                NS_INBOUND_ITEM_AVAILABILITY: {
                    LABEL: "Inbound quantity updates",
                    DESCRIPTION: "Import of remaining available quantities of items"
                },
                NS_INBOUND_ORDERS: {
                    LABEL: "Inbound order updates",
                    DESCRIPTION: "Import of updates related to orders"
                },
                NS_INBOUND_ITEMS: {
                    LABEL: "Inbound items",
                    DESCRIPTION: "Import of item registries"
                },
                RP_INBOUND_MOVIMENTI: {
                    LABEL: "Inbound movements",
                    DESCRIPTION: "Import of warehouse movements created on Retail Pro"
                },
                OTHER_INBOUND_ITEMS: {
                    LABEL: "Import items",
                    DESCRIPTION: "Import of item registries"
                },
                OTHER_INBOUND_ITEM_IMAGES: {
                    LABEL: "Import item images",
                    DESCRIPTION: "Import of item images"
                },
                OTHER_BULK_INBOUND_ITEMS: {
                    LABEL: "Import items flow",
                    DESCRIPTION: "Mass import of item registry information"
                },
                OTHER_BULK_INBOUND_ITEM_IMAGES: {
                    LABEL: "Import item images flow",
                    DESCRIPTION: "Mass import of item images"
                },
                OTHER_BULK_INBOUND_WHS_MOVEMENTS: {
                    LABEL: "Import warehouse movements flow",
                    DESCRIPTION: "Mass import of warehouse movements"
                },
                OTHER_INBOUND_WHS_MOVEMENTS: {
                    LABEL: "Import warehouse movements",
                    DESCRIPTION: "Import of warehouse movements"
                }
            },
            NETSUITE: {
                CONSULT_SEND_ORDER: "Consult the send to NetSuite",
                SEND_ORDER: "Send order to NetSuite",
                SEND_ORDER_NOT_AVAILABLE: "The order is already queued to be sent to NetSuite",
                SEND_ORDER_TITLE: "Order submission",
                SEND_ORDER_CONTENT_OK: "The order has been queued to be sent to <strong>NetSuite</strong>.",
                SEND_ORDER_CONTENT_KO: "It was not possible to prepare the order to be sent to <strong>NetSuite</strong>, or it is already queued to be sent."
            },
            OTHER: {
                REPORT: {
                    LABEL: "Report",
                    DESCRIPTION: "Processing of reports configured by the user"
                },
                LISTING: {
                    LABEL: "Listing",
                    DESCRIPTION: "Creation of listings for items subject to automatic publication"
                },
                LRT: {
                    LABEL: "Long-running tasks",
                    DESCRIPTION: "Queue processing long-running tasks"
                },
                LISTING_EXCLUSION_RULES: {
                    LABEL: "Listing exclusion rules",
                    DESCRIPTION: "Application of exclusion rules to the listing of a subset of catalog items"
                }, 
                AVAILABILITY_PRICE:{
                    LABEL:"Outbound Availability and Prices",
                    DESCRIPTION:"",
                },
                AVAILABILITY_PRICE_RESULT:{
                    LABEL:" Inbound Availability and Prices Result",
                    DESCRIPTION:"",
                }
                
            },
            BC:{
                BUSINESS_CONDITION_1: {
                    LABEL: "Shop business conditions",
                    DESCRIPTION: "Application of business conditions to shops of a subset of catalog items"
                },
                BUSINESS_CONDITION_2: {
                    LABEL: "Item business conditions",
                    DESCRIPTION: "Application of business conditions to items of a subset of catalog items"
                }
            }
        }
        
        
    },
    STREAMS: {
        AVAILABILITY_OUTBOUND: "Aggiornamento quantità",
        ORDER_INBOUND: "Scarico ordini",
        ORDER_REPLY_OUTBOUND: "Invio conferma ordini",
        PACKAGING_OUTBOUND: "Invio informazioni packaging",
        PAYMENT_OUTBOUND: "Invio informazioni pagamenti",
        SHIPMENT_INBOUND: "Scarico spedizioni",
        SHIPMENT_OUTBOUND: "Invio spedizioni",
        RETURN_INBOUND: "Scarico resi",
        RETURN_OUTBOUND: "Invio resi",
        RETURN_REQUEST_INBOUND: "Scarico richieste di reso",
        RETURN_REQUEST_REPLY_OUTBOUND: "Invio conferma resi",
        REPLY_POLICIES: {
            REPLY_ALWAYS: "Automatic without inventory control",
            REPLY_NEVER:"Manual",
            REPLY_IF_IN_CATALOG:"Automatic only if in inventory",
            REPLY_IF_IN_CATALOG_AND_AVAILABLE:"Automatic if available"
        },
    },
    OVERRIDE_WAREHOUSE_ALGORITHM: {
        EREDITA_STRATEGIA_DEFAULT: "Eredita la strategia di default",
        NONE: "NONE",
        NO_OP: "Nessuna operazione",
        WHS_PRIORITY: "Priorità magazzini",
        TEMP_COMMIT: "Magazzino di appoggio",
        SHOP_DRIVEN: "SHOP_DRIVEN",
    },
    FORMS: {
        ERRORI_NEL_FORM: "Ci sono errori nei campi del form, si prega di controllare.",
        CAMPO_OBBLIGATORIO: "Campo obbligatorio",
        LUNGHEZZA_COMPRESA: "La lunghezza del campo deve essere compresa tra __MIN__ e __MAX__ caratteri.",
        VALORE_COMPRESO: "Il valore del campo deve essere compreso tra __MIN__ e __MAX__.",
        EMAIL_NON_VALIDA:"mail non valida"
    },
     LANGUAGES : {
        DE: "German",
        DK: "Danish",
        ES: "Spanish",
        EU: "European Union",
        FR: "French",
        EN: "English",
        IT: "Italian",
        RU: "Russian",
        SE: "Swedish",
        CN: "Chinese",
        KR: "Korean",
    },
    TREE_SEARCH : {
        OTHER_RESULTS : " Show more results (__REMINIG__ remaining )",
    },
    COMPONENT_PRICEBOOKS: {
        CURRENCY: "Valuta",
        STOCK_PRICEBOOK: "Prezzo pieno",
        SALE_PRICEBOOK: "Prezzo scontato",
        AGGIUNGI: "Aggiungi",
        RIMUOVI: "Rimuovi",
        SELECT_PRICEBOOK:"Select Price Book",
        INCLUDES_VAT:"Include Vat"
    },
    COUNTRIES: {
        SPAGNA: "Spain",
        REGNO_UNITO: "U.K.",
        FRANCIA: "France",
        BELGIO: "Belgium",
        PAESI_BASSI: "Netherlands",
        GERMANIA: "Germany",
        ITALIA: "Italy",
        SVEZIA: "Sweden",
        POLONIA: "Poland"
    },
    HOME_WIDGETS: {
        ORDINI_DI_OGGI: "Ordini di oggi",
        NESSUN_ORDINE_OGGI: "Nessun nuovo ordine."
    },
    YOOX_SALE_CHANNEL : {
        BE: 'Benelux',
        CEU: 'Central Europe',
        DE: 'Germany, Austria',
        EEU: 'Eastern',
        ES: 'Spain',
        FR: 'France',
        GR: 'Greece',
        IT: 'Italy',
        NL: 'Netherlands',
        SEU: 'Southern Europe',
    },
    GLF_SALE_CHANNEL:{
        FR: 'Private Sales Channel (Francia)' 
    },
    DECATHLON_SALE_CHANNEL:{
        IT: 'Italy',
    },
    MIRAKL_SALE_CHANNEL_DESC:{
        BE_DESC: 'Belgium, Luxembourg',
        CEU_DESC: 'Poland, Romania, Slovakia, Hungary, Czech Republic',
        DE_DESC: 'Germany, Austria',
        DK_DESC: 'Denmark, Finland, Ireland, Sweden',
        EEU_DESC: 'Estonia, Latvia, Lithuania',
        ES_DESC: 'Spain',
        FR_DESC: 'France',
        GR_DESC: 'Greece',
        IT_DESC: 'Italy',
        NL_DESC: 'Netherlands',
        SEU_DESC: 'Bulgaria, Croatia, Cyprus, Malta, Slovenia, Portugal' 
    },
    COUNTRIES_CODE: {
        AC: "Ascension Island",
        AD: "Andorra",
        AE: "United Arab Emirates",
        AF: "Afghanistan",
        AG: "Antigua and Barbuda",
        AI: "Anguilla",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antarctica",
        AR: "Argentina",
        AS: "American Samoa",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Åland Islands",
        AZ: "Azerbaijan",
        BA: "Bosnia and Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgium",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrain",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint Barthélemy",
        BM: "Bermuda",
        BN: "Brunei",
        BO: "Bolivia",
        BQ: "Caribbean Netherlands",
        BR: "Brazil",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Bouvet Island",
        BW: "Botswana",
        BY: "Belarus",
        BZ: "Belize",
        CA: "Canada",
        CC: "Cocos (Keeling) Islands",
        CD: "Congo (Kinshasa)",
        CF: "Central African Republic",
        CG: "Congo (Brazzaville)",
        CH: "Switzerland",
        CI: "Côte d'Ivoire",
        CK: "Cook Islands",
        CL: "Chile",
        CM: "Cameroon",
        CN: "China",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Cape Verde",
        CW: "Curaçao",
        CX: "Christmas Island",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DE: "Germany",
        DJ: "Djibouti",
        DK: "Denmark",
        DM: "Dominica",
        DO: "Dominican Republic",
        DZ: "Algeria",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egypt",
        EH: "Western Sahara",
        ER: "Eritrea",
        ES: "Spain",
        ET: "Ethiopia",
        FI: "Finland",
        FJ: "Fiji",
        FK: "Falkland Islands",
        FM: "Micronesia",
        FO: "Faroe Islands",
        FR: "France",
        GA: "Gabon",
        GB: "United Kingdom",
        GD: "Grenada",
        GE: "Georgia",
        GF: "French Guiana",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Greenland",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadeloupe",
        GQ: "Equatorial Guinea",
        GR: "Greece",
        GS: "South Georgia and the South Sandwich Islands",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Heard Island and McDonald Islands",
        HN: "Honduras",
        HR: "Croatia",
        HT: "Haiti",
        HU: "Hungary",
        ID: "Indonesia",
        IE: "Ireland",
        IL: "Israel",
        IM: "Isle of Man",
        IN: "India",
        IO: "British Indian Ocean Territory",
        IQ: "Iraq",
        IR: "Iran",
        IS: "Iceland",
        IT: "Italy",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordan",
        JP: "Japan",
        KE: "Kenya",
        KG: "Kyrgyzstan",
        KH: "Cambodia",
        KI: "Kiribati",
        KM: "Comoros",
        KN: "Saint Kitts and Nevis",
        KP: "North Korea",
        KR: "South Korea",
        KW: "Kuwait",
        KY: "Cayman Islands",
        KZ: "Kazakhstan",
        LA: "Laos",
        LB: "Lebanon",
        LC: "Saint Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lithuania",
        LU: "Luxembourg",
        LV: "Latvia",
        LY: "Libya",
        MA: "Morocco",
        MC: "Monaco",
        MD: "Moldova",
        ME: "Montenegro",
        MF: "Saint Martin",
        MG: "Madagascar",
        MH: "Marshall Islands",
        MK: "North Macedonia",
        ML: "Mali",
        MM: "Myanmar (Burma)",
        MN: "Mongolia",
        MO: "Macau",
        MP: "Northern Mariana Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldives",
        MW: "Malawi",
        MX: "Mexico",
        MY: "Malaysia",
        MZ: "Mozambique",
        NA: "Namibia",
        NC: "New Caledonia",
        NE: "Niger",
        NF: "Norfolk Island",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Netherlands",
        NO: "Norway",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "New Zealand",
        OM: "Oman",
        PA: "Panama",
        PE: "Peru",
        PF: "French Polynesia",
        PG: "Papua New Guinea",
        PH: "Philippines",
        PK: "Pakistan",
        PL: "Poland",
        PM: "Saint Pierre and Miquelon",
        PN: "Pitcairn Islands",
        PR: "Puerto Rico",
        PS: "Palestine",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Réunion",
        RO: "Romania",
        RS: "Serbia",
        RU: "Russia",
        RW: "Rwanda",
        SA: "Saudi Arabia",
        SB: "Solomon Islands",
        SC: "Seychelles",
        SD: "Sudan",
        SE: "Sweden",
        SG: "Singapore",
        SH: "Saint Helena",
        SI: "Slovenia",
        SJ: "Svalbard and Jan Mayen",
        SK: "Slovakia",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Suriname",
        SS: "South Sudan",
        ST: "São Tomé and Príncipe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Syria",
        SZ: "Eswatini",
        TC: "Turks and Caicos Islands",
        TD: "Chad",
        TF: "French Southern Territories",
        TG: "Togo",
        TH: "Thailand",
        TJ: "Tajikistan",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turkmenistan",
        TN: "Tunisia",
        TO: "Tonga",
        TR: "Turkey",
        TT: "Trinidad and Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzania",
        UA: "Ukraine",
        UG: "Uganda",
        UM: "United States Minor Outlying Islands",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VA: "Vatican City",
        VC: "Saint Vincent and the Grenadines",
        VE: "Venezuela",
        VG: "British Virgin Islands",
        VI: "U.S. Virgin Islands",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis and Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "South Africa",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        UNDEFINED: "Undefined"
    },
    CURRENCY : {
        AED: "United Arab Emirates Dirham",
        AFN: "Afghani",
        ALL: "Lek",
        AMD: "Armenian Dram",
        ANG: "Netherlands Antillean Guilder",
        AOA: "Kwanza",
        ARS: "Argentine Peso",
        AUD: "Australian Dollar",
        AWG: "Aruban Florin",
        AZN: "Azerbaijan Manat",
        BAM: "Convertible Mark",
        BBD: "Barbados Dollar",
        BDT: "Taka",
        BGN: "Bulgarian Lev",
        BHD: "Bahraini Dinar",
        BIF: "Burundi Franc",
        BMD: "Bermudian Dollar",
        BND: "Brunei Dollar",
        BOB: "Boliviano",
        BRL: "Brazilian Real",
        BSD: "Bahamian Dollar",
        BTN: "Ngultrum",
        BWP: "Pula",
        BYN: "Belarusian Ruble",
        BZD: "Belize Dollar",
        CAD: "Canadian Dollar",
        CDF: "Congolese Franc",
        CHF: "Swiss Franc",
        CLP: "Chilean Peso",
        CNY: "Yuan Renminbi",
        COP: "Colombian Peso",
        CRC: "Costa Rican Colon",
        CUC: "Peso Convertible",
        CUP: "Cuban Peso",
        CVE: "Cabo Verde Escudo",
        CZK: "Czech Koruna",
        DJF: "Djibouti Franc",
        DKK: "Danish Krone",
        DOP: "Dominican Peso",
        DZD: "Algerian Dinar",
        EGP: "Egyptian Pound",
        ERN: "Nakfa",
        ETB: "Ethiopian Birr",
        EUR: "Euro",
        FJD: "Fiji Dollar",
        FKP: "Falkland Islands Pound",
        GBP: "Pound Sterling",
        GEL: "Lari",
        GHS: "Ghana Cedi",
        GIP: "Gibraltar Pound",
        GMD: "Dalasi",
        GNF: "Guinea Franc",
        GTQ: "Quetzal",
        GYD: "Guyana Dollar",
        HKD: "Hong Kong Dollar",
        HNL: "Lempira",
        HRK: "Kuna",
        HTG: "Gourde",
        HUF: "Forint",
        IDR: "Rupiah",
        ILS: "New Israeli Sheqel",
        INR: "Indian Rupee",
        IQD: "Iraqi Dinar",
        IRR: "Iranian Rial",
        ISK: "Iceland Krona",
        JMD: "Jamaican Dollar",
        JOD: "Jordanian Dinar",
        JPY: "Yen",
        KES: "Kenyan Shilling",
        KGS: "Som",
        KHR: "Riel",
        KMF: "Comoro Franc",
        KPW: "North Korean Won",
        KRW: "Won",
        KWD: "Kuwaiti Dinar",
        KYD: "Cayman Islands Dollar",
        KZT: "Tenge",
        LAK: "Kip",
        LBP: "Lebanese Pound",
        LKR: "Sri Lanka Rupee",
        LRD: "Liberian Dollar",
        LSL: "Loti",
        LYD: "Libyan Dinar",
        MAD: "Moroccan Dirham",
        MDL: "Moldovan Leu",
        MGA: "Malagasy Ariary",
        MKD: "Denar",
        MMK: "Kyat",
        MNT: "Tugrik",
        MOP: "Pataca",
        MRU: "Ouguiya",
        MUR: "Mauritius Rupee",
        MVR: "Rufiyaa",
        MWK: "Malawi Kwacha",
        MXN: "Mexican Peso",
        MYR: "Malaysian Ringgit",
        MZN: "Mozambique Metical",
        NAD: "Namibia Dollar",
        NGN: "Naira",
        NIO: "Cordoba Oro",
        NOK: "Norwegian Krone",
        NPR: "Nepalese Rupee",
        NZD: "New Zealand Dollar",
        OMR: "Rial Omani",
        PAB: "Balboa",
        PEN: "Sol",
        PGK: "Kina",
        PHP: "Philippine Peso",
        PKR: "Pakistan Rupee",
        PLN: "Zloty",
        PYG: "Guarani",
        QAR: "Qatari Rial",
        RON: "Romanian Leu",
        RSD: "Serbian Dinar",
        RUB: "Russian Ruble",
        RWF: "Rwanda Franc",
        SAR: "Saudi Riyal",
        SBD: "Solomon Islands Dollar",
        SCR: "Seychelles Rupee",
        SDG: "Sudanese Pound",
        SEK: "Swedish Krona",
        SGD: "Singapore Dollar",
        SHP: "Saint Helena Pound",
        SLL: "Leone",
        SOS: "Somali Shilling",
        SRD: "Surinam Dollar",
        SSP: "South Sudanese Pound",
        STN: "Dobra",
        SYP: "Syrian Pound",
        SZL: "Lilangeni",
        THB: "Baht",
        TJS: "Somoni",
        TMT: "Turkmenistan New Manat",
        TND: "Tunisian Dinar",
        TOP: "Pa’anga",
        TRY: "Turkish Lira",
        TTD: "Trinidad and Tobago Dollar",
        TWD: "New Taiwan Dollar",
        TZS: "Tanzanian Shilling",
        UAH: "Hryvnia",
        UGX: "Uganda Shilling",
        USD: "US Dollar",
        UYU: "Peso Uruguayo",
        UZS: "Uzbekistan Sum",
        VES: "Bolívar Soberano",
        VND: "Dong",
        VUV: "Vatu",
        WST: "Tala",
        XAF: "CFA Franc BEAC",
        XCD: "East Caribbean Dollar",
        XOF: "CFA Franc BCEAO",
        XPF: "CFP Franc",
        YER: "Yemeni Rial",
        ZAR: "Rand",
        ZMW: "Zambian Kwacha",
        ZWL: "Zimbabwe Dollar"
    }

}

export default en