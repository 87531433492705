import axios, { AxiosResponse } from "axios"
import { checkSessionExpired } from "@api/Utils"
import { EntityListResponsePackageType, EntityListResponseShop } from '@csapi/provisioning/api'
import { CS_SHOPS_IDS } from "@utils/ShopsUtils"
import { BindingPoint } from "@pages/ShopsWarehousesBindings/ShopsWarehousesBindings"
import { UpdateWhsAndSyncResponse } from "../../../src/interfaces/Shops"

export type SubmitChannelShopResponse = {
    name: string,
    marketplace: string,
    success: boolean,
    error?: unknown
}

const getShops = async ():Promise<EntityListResponseShop> =>{
    try {
        const response:AxiosResponse<EntityListResponseShop,any> = await axios.get('api/shops')
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getShop = async(csShopId: CS_SHOPS_IDS, shopId: string):Promise<unknown> =>{
    try {
        const response:AxiosResponse<unknown,any> = await axios.get(`api/shops/${csShopId}/${shopId}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getChannelShops = async(csShopId: CS_SHOPS_IDS):Promise<unknown[]> =>{
    try {
        const response:AxiosResponse<unknown[],any> = await axios.get(`api/shops/channel/${csShopId}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getShopPackageTypes = async(id: string):Promise<EntityListResponsePackageType> =>{
    try {
        const response:AxiosResponse<EntityListResponsePackageType,any> = await axios.get(`api/shops/package-types/${id}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}



const setShopEnabled = async(shopId: string, enabled: boolean) =>{
    try {
        const response = await axios.patch(`api/shops/${shopId}/enabled`, {enabled})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const createShop = async(shop: unknown)=>{
    try {
        const response = await axios.post(`api/shops/create`, {shop})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

const updateShop = async(shop: unknown)=>{
    try {
        const response = await axios.put(`api/shops/update`, {shop})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

const submitChannelShops = async(cs_shop_id: CS_SHOPS_IDS, shops: unknown[])=>{
    try {
        const response = await axios.post(`api/shops/channel`, {cs_shop_id, shops})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

const updateWhsAndSync = async(bindingPoints: BindingPoint[], sync: boolean) =>{
    try {
        const response:UpdateWhsAndSyncResponse = await axios.post(`api/shops/update-whs-and-sync`, {bindingPoints, sync})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

const renewShopToken = async(shopId: string, csShopId: string, tokenId: string)=>{
    try {
        const response = await axios.post(`api/shops/${shopId}/renew-token/`, {tokenId, csShopId})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

export { getShops, getShop, getChannelShops, setShopEnabled, createShop, updateShop, submitChannelShops, updateWhsAndSync, getShopPackageTypes, renewShopToken }