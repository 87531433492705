
import CSDiv from "@components/HTMLElements/CSDiv"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import {Customer} from "@csapi/provisioning/api"
import Localization from "@localization/Index"
import TimeLine from "@components/TimeLine/TimeLine"
import { parseISO } from "date-fns"
import TimeLineElement from "@components/TimeLine/TimeLineElement"


type CustomerOverviewTabProps={
    customerId: string
    customer: Customer | undefined
}

const CustomerBCTab = (props: CustomerOverviewTabProps) =>{
    const { customer } = props
    const customerBC= customer?.business_conditions

    return <>
            <div className="row mt-5">
                {(!customerBC|| customerBC.length < 1) && 
                    <CSDiv fillVertical className="d-flex flex-column align-items-center justify-content-center">
                        <i className="bi-info-circle text-primary font-5x mb-3"></i>
                    <span className="">{Localization.PAGE_CUSTOMERS.TABS.BC.EMPTY_BOX}</span>
                </CSDiv>}
                {customerBC && customerBC.length > 0 &&                        
                    customer?.business_conditions?.map((element)=>{
                            
                        const { bc_id, creation_dt, status, last_update_dt, priority } = element
                        if(status ==='CONFIRMED' || status ==="TO_BE_CANCELED"){
                            const creation_date = creation_dt?.$date?parseISO(creation_dt?.$date):undefined
                            const lastUpdate_date = last_update_dt?.$date?parseISO(last_update_dt?.$date):undefined
                            const link = `businessconditions/${bc_id?.$oid!}`
                            const creationContent =
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <a href={link} target="_new" className="cs-link">{bc_id?.$oid??'n.d.'}</a>
                                    <span>{Localization.PAGE_CUSTOMERS.TABS.BC.TL_CREATION_DATE}</span>
                                </div>
                            const updateContent = 
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <a href={link} target="_new" className="cs-link">{bc_id?.$oid??'n.d.'}</a>
                                    <span>{Localization.PAGE_CUSTOMERS.TABS.BC.TL_LAST_UPDATE_DATA}</span>
                                </div>
                            const creation = <TimeLineElement datetime={creation_date}  content={creationContent } variant="primary"></TimeLineElement>
                            const updateElemnt = <TimeLineElement  datetime={lastUpdate_date} content={updateContent} variant="warning"></TimeLineElement>
                            
                            return  <SectionGroup title={<div>{`${Localization.PAGE_CUSTOMERS.TABS.BC.TITLE} : ${status ==='CONFIRMED'?Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.CONFIRMED : Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.TO_BE_CANCELED} `}</div>} >
                                        <div className="mt-5">  <TimeLine>{creation} {updateElemnt}</TimeLine></div>
                                    </SectionGroup>
                        } 
                    })
                    }   
                </div>
            </>

}

export default CustomerBCTab

