import axios, { AxiosResponse } from "axios"
import { checkSessionExpired } from "@api/Utils"
import { NotFoundItemInArray } from '../../../src/interfaces/Items'
import { EntityListResponseItem, EntityListResponseItemSummary, EntityListResponseObject, EntitySingleResponseItem, Item } from '@csapi/provisioning/api'
import { CurrentFilters } from "@utils/AdvancedFiltersUtils"

const getMetadata = async (field:string):Promise<EntityListResponseObject> =>{
    try {
        const response:AxiosResponse<EntityListResponseObject,any> = await axios.get(`api/items/metadata/${field}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}


export interface MOCKCatalogItem {
    mnf_code?: string,
    brand?: string,
    sku_parents?: MOCKCatalogItem[],
    images?: string[],
    sku_parent?: string,
    skus?: MOCKCatalogItem[],
    sku?: string,
    name?: string,
    description?: string,
    size?: string
}
/**
 * TODO: pagination, sort, pagesize etc
 * TODO: THIS IS CURRENTLY A MOCK DATA!
 * @param term a term string search
 * @param filters the filters applied to the search
 * 
 */
const getCatalog = async (term: string, filters: CurrentFilters) =>{
    await new Promise(resolve => setTimeout(resolve, 1000));
    return [
        {
            mnf_code: "SS23_AAA",
            brand: "Adidas",
            description: "Scarpe",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_AAA_123",
                    name: "Scarpe Blu",
                    description: "Scarpe blu con logo e profilo bianco",
                    images: ["https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg"],
                    skus: [
                        {
                            sku: "SS23_AAA_123_39",
                            size: "39"
                        },
                        {
                            sku: "SS23_AAA_123_40",
                            size: "40"
                        },
                        {
                            sku: "SS23_AAA_123_41",
                            size: "41"
                        }
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        },
        {
            mnf_code: "SS23_BBB",
            brand: "Fendi",
            description: "Maglietta",
            images: [
                "https://cdn.assetsmanagment.com/2/31D6D185-9C98-4F84-BA16-BD28BA07DE63.jpg",
                "https://cdn.assetsmanagment.com/2/0CB3D59A-6223-421E-B99A-7642309882CB.jpg"
            ],
            sku_parents: [
                {
                    sku_parent: "SS23_BBB_001",
                    name: "Maglietta rossa",
                    description: "Maglietta rossa con logo bianco",
                    skus: [
                        {
                            sku: "SS23_BBB_001_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_001_50",
                            size: "50"
                        },
                    ]
                },
                {
                    sku_parent: "SS23_BBB_002",
                    name: "Maglietta bianca",
                    description: "Maglietta bianca con logo rosso",
                    skus: [
                        {
                            sku: "SS23_BBB_002_48",
                            size: "48"
                        },
                        {
                            sku: "SS23_BBB_002_50",
                            size: "50"
                        },
                    ]
                }
            ]
        }
    ]
}

/**
 * 
 * @param term the term to search for (like / lowercase)
 * @returns the array of skus found in the catalog
 */
const searchSku = async (term:string) =>{
    await new Promise(resolve => setTimeout(resolve, 300))

    return [
        `${term}`,
    ]
}

const getItemsFromCodeList = async (codeList: string[], type: string) =>{
    try {
        const response:AxiosResponse<EntityListResponseItemSummary,any> = await axios.post(`api/items/listbyCodeList?type=${type}`, codeList)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getItemsbyParentSku = async (skuParent: string) =>{
    try {
        const response:AxiosResponse<EntityListResponseItem, any> =  await axios.get(`api/items/getItemsByParentSKU/${skuParent}`)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }

}

const getItemBySku = async (sku: string) =>{
    try {
        const response:AxiosResponse<EntitySingleResponseItem, any> =  await axios.get(`api/items/getItemBySKU/${sku}`)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }

}

const getItemById = async(id: string)=>{
    try {
        const response:AxiosResponse<EntitySingleResponseItem, any> =  await axios.get(`api/items/getItemById/${id}`)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const getItemsByIdList = async(idList: string[])=>{
    try {
        const response:AxiosResponse<Array<Item | NotFoundItemInArray>, any> =  await axios.post(`api/items/getItemsByIdList`, idList)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }
}

export { getMetadata, getCatalog, searchSku, getItemsFromCodeList, getItemBySku, getItemById, getItemsByIdList, getItemsbyParentSku}