
import CSDiv from "@components/HTMLElements/CSDiv"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import { Customer} from "@csapi/provisioning/api"


import { useEffect, useState } from "react"
import AdditionalInfo from "@components/AdditionalInfo/AdditionalInfo"


type CustomerOverviewTabProps={
    customerId: string
    customer: Customer | undefined
}

const CustomerAddInfoTab = (props: CustomerOverviewTabProps) =>{
    const { customer } = props
    const [updatedCustomer, setUpdatedCustomer] = useState<Customer | undefined>(customer)

    useEffect(() => {
        setUpdatedCustomer(customer)
    }, [customer])


    return <>
            <div className="row mt-5">
                {customer  &&                        
                    <CSDiv id="CustomerOrdersContainer" className="overflow-auto" fillVertical offsetVertical={100}>
                    <SectionGroup>
                        <AdditionalInfo content={customer.additional_info} />
                    </SectionGroup>
                        
                    </CSDiv>
                }    
            </div>
            </>

}

export default CustomerAddInfoTab

