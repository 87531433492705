import CSDiv from "@components/HTMLElements/CSDiv"
import Localization from "@localization/Index"

const QueueError = ()=>{
    return <CSDiv fillVertical className="d-flex flex-column align-items-center justify-content-center">
    <i className="bi-dash-circle text-danger font-5x mb-3"></i>
    <span className="">{Localization.PAGE_QUEUES.QUEUE_ERROR}</span>
</CSDiv>
}

export default QueueError