import { Order } from "@csapi/provisioning/api"
import { Shop } from "@csapi/provisioning/api"
import useWindowSize from "@hooks/UseWindowSize"
import Localization from "@localization/Index"
import ShopIcon from "@components/ShopIcon/ShopIcon"
import { getOrderStatus, OrderStatus } from "@utils/OrderUtils"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import ListItem from "@components/ListItem/ListItem"
import ListItemElement from "@components/ListItem/ListItemElement"


type OrdersListItemsProps = {
    loading?: boolean,
    order?: Order,
    shop?: Shop,
    onClick?: Function,
    onContextMenu?: Function
}

const OrdersListItem = (props: OrdersListItemsProps) =>{
    const { order, loading=false, shop, onClick, onContextMenu } = props
    const [width, _] = useWindowSize()

    const status = getOrderStatus(order?.order_status as OrderStatus)
    const orderDate = (!!order?.order_dt?.$date)?format(parseISO(order?.order_dt?.$date), 'dd/MM/yyyy HH:mm'):"N.D."
    const shopIconSize = (width>1700)?24:16
    const hasDocuments = Object.keys(order?.documents ?? {}).length > 0
    const hasShipping = order?.order_status == OrderStatus.SHIPPED

    return <ListItem skeleton={loading} onClick={()=>{onClick?.()}} onContextMenu={(e)=>{return onContextMenu?.(e,order?._id?.$oid)}}>
                <ListItemElement colspan={6} label={shop?.name ?? ""} icon={<ShopIcon id={shop?.cs_shop_id?.$oid ?? ""} size={shopIconSize} />} value={order?.shop_order_id ?? ""} /> 
                <ListItemElement colspan={4} label={Localization.PAGE_ORDERS.STATO_ORDINE} icon={`${status.icon} text-${status.color}`} value={
                    <span className={`text-${status.color}`}>{order?.order_status ?? "N.D."}</span>
                } />
                <ListItemElement colspan={4} label={Localization.PAGE_ORDERS.DATA} icon={`bi-calendar3 text-muted`} value={orderDate} />
                <ListItemElement colspan={4} label={Localization.PAGE_ORDERS.NUMERO_ARTICOLI} icon={`bi-boxes text-muted`} value={order?.tot_qty?.toString() ?? "N.D."} />
                <ListItemElement colspan={4} label={Localization.PAGE_ORDERS.CONTROVALORE} icon={`bi-cash-stack text-muted`} value={`${order?.tot_amount?.amount?.toFixed(2) ?? "N.D."} ${order?.tot_amount?.currency ?? ""}`} />
                <ListItemElement colspan={2} position="end" value={<>
                    <i className={`bi-truck font-2x text-${hasShipping?`success`:`muted`} me-4`} />
                    <i className={`bi-file-earmark-arrow-down font-2x text-${hasDocuments?`success`:`muted`} me-2`} />
                </>} />
            </ListItem>
}

export default OrdersListItem