import CSDiv from "@components/HTMLElements/CSDiv"
import Localization from "@localization/Index"

const QueueNotFound = ()=>{
    return <CSDiv fillVertical className="d-flex flex-column align-items-center justify-content-center">
        <i className="bi-exclamation-triangle text-warning font-5x mb-3"></i>
        <span className="">{Localization.PAGE_QUEUES.QUEUE_NOT_FOUND}</span>
    </CSDiv>
}

export default QueueNotFound