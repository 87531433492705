import { PropsWithChildren, useEffect, useRef, useState} from "react"

type CSDivProps = JSX.IntrinsicElements["div"] & {
    offsetVertical?: number,
    fillVertical?: boolean,
    data_cy?: string
}

const CSDiv = (props:PropsWithChildren<CSDivProps>) =>{
    const { offsetVertical=0, fillVertical=false, children, style,data_cy } = props
    const [containerHeight, setContainerHeight] = useState<number|undefined>(undefined)


    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if(!fillVertical || !containerRef.current) return
        function handleResize() {
            if(style?.height) return 

            const rect = containerRef.current?.getBoundingClientRect()
            if(rect) {
                const { innerHeight } = window
                setContainerHeight(innerHeight-rect.top -offsetVertical)
            }
        }

        window.addEventListener("resize", handleResize)
        handleResize()

        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const divprops = {...props}
    delete divprops.fillVertical
    delete divprops.offsetVertical
    return <div {...divprops} data-cy={data_cy} style={{...style, height:style?.height ?? containerHeight}} ref={containerRef}>
        {children}
    </div>
}

export default CSDiv