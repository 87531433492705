import React from 'react'

import SectionGroup from '@components/SectionGroup/SectionGroup'
import { QueueEntryObject, QueueEntryObjectStatusEnum, SingleMetadata } from '@csapi/provisioning'
import Localization from '@localization/Index'
import ListItemElement from '@components/ListItem/ListItemElement'
import { format, parseISO } from 'date-fns';
import { QueueItem, statusIcons } from '@utils/QueueUtils'
import TimeLine from '@components/TimeLine/TimeLine'
import TimeLineElement from '@components/TimeLine/TimeLineElement'
import CSDiv from '@components/HTMLElements/CSDiv'
import { useNavigate } from 'react-router-dom'
import styles from './css/QueueCard.module.scss'


type Props = {
  details: QueueEntryObject
  metadata: SingleMetadata
  configDetails: QueueItem
}

const QueueDetailsInfo: React.FC<Props> = ({ details, metadata, configDetails}) => {
const navigate = useNavigate()
  
const creationDate = (metadata?.creation_dt?.$date)?format(parseISO(metadata?.creation_dt?.$date), 'dd/MM/yyyy HH:mm'):"N.D."
const updateDate = (metadata?.last_update_dt?.$date)?format(parseISO(metadata?.last_update_dt?.$date), 'dd/MM/yyyy HH:mm'):"N.D."

const getStatusIcon = (statusKey: keyof typeof statusIcons): string => {
  return statusIcons[statusKey] || statusIcons.ND
}

const getStatusTranslation = (status: QueueEntryObjectStatusEnum | undefined): string | undefined => {
  if(!status) return ''
  return Localization.PAGE_QUEUES.STATUS[status];
}

const getNestedProperty = (object: any, path: string) => {
  return path.split('.').reduce((obj, key) => {
  return (obj && obj[key] !== undefined) ? obj[key] : undefined
  }, object)
}



const sortExecutions = details?.executions?.sort((a, b)=>{
  const aDate = parseISO(a.executed_dt?.$date!).getTime()
  const bDate = parseISO(b.executed_dt?.$date!).getTime()
  return bDate - aDate
})

const getCustomDetails = () => {
  
  if ( configDetails?.custom_details_info) {
    
    return configDetails.custom_details_info.map((column, index) => {
        const dataToProcess = getNestedProperty(details, column.attribute)
        const processedValue = column.value(dataToProcess)
        const displayValue = typeof processedValue === 'number' ? processedValue.toString() : processedValue
      
        return (
          <div className={`p-2 pt-4 ${styles[column.className ??'']}`} key={`${column.attribute}${index}`}>
            <ListItemElement 
                key={`${column.attribute}${index}_item`}
                icon={`${column.icon} text-muted`} 
                colspan={24} 
                label={column.translations} 
                value={displayValue}
                onClick={()=>column?.onClick && column.onClick(displayValue,navigate)}
            />
          </div>  
        )
    })
  }
}

return (
  <>
    {details ? (
      <div className="row mt-field d-flex align-items-stretch">
        <div className="col-md-8 d-flex flex-column" >
          
          <SectionGroup className='flex-grow-1' title={Localization.PAGE_QUEUES.DETAILS.TAB_INFO_TITLE_1}> 
            <div className='p-2 pt-4'><ListItemElement colspan={24} label={Localization.PAGE_QUEUES.DETAILS.EXTERNAL_CODE} icon={`bi-tag text-muted`} value={details.external_key} /></div>
            <div className='p-2 pt-4'><ListItemElement colspan={24} label={Localization.PAGE_QUEUES.DETAILS.CREATION_DATE} icon={`bi-calendar3 text-muted`} value={creationDate} /></div>
            <div className='p-2 pt-4'><ListItemElement colspan={24} label={Localization.PAGE_QUEUES.DETAILS.UPDATE_DATA} icon={`bi-calendar3 text-muted`} value={updateDate} /></div>
            <div className='p-2 pt-4'><ListItemElement icon={getStatusIcon(details?.status as keyof typeof statusIcons)} colspan={24} label={Localization.PAGE_QUEUES.TABLE.STATUS} value={getStatusTranslation(details?.status??undefined)??''} /></div>
            {getCustomDetails()}
          </SectionGroup>
        </div>
        <div className="col-md-16 d-flex flex-column" >
          <SectionGroup className='flex-grow-1' title={Localization.PAGE_QUEUES.DETAILS.TAB_INFO_TITLE_2}> 
              { sortExecutions && sortExecutions.length>0 ? <TimeLine>
                {
                  sortExecutions?.map(execution => {
                    const datetime = execution.executed_dt?.$date? parseISO(execution.executed_dt?.$date): undefined
                    return <TimeLineElement key={`queue_execution_${datetime}`} datetime={datetime} content={<div>{execution?.reason??Localization.PAGE_QUEUES.DETAILS.TIMELINE_ELEMENT}</div>}></TimeLineElement>
                  })
                }
              </TimeLine> 
              :
                <CSDiv fillVertical className="d-flex flex-column align-items-center justify-content-center">
                    <i className="bi-info-circle text-primary font-5x mb-3"></i>
                    <span className="">{Localization.PAGE_QUEUES.QUEUE_EMPTY_EXECUTIONS_DETAILS}</span>
                </CSDiv>
              }
          </SectionGroup>
        </div>
      </div> 
    ) : (
      <div></div>
    )}
  </>
)

}

export default QueueDetailsInfo
