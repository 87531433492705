import styles from './css/CSSelect.module.scss'

export type CSSelectProps = {
    data_cy?:string
}

type SelectProps = CSSelectProps & React.SelectHTMLAttributes<HTMLSelectElement>


const CSSelect = (selectProps:SelectProps) =>{

    const {children,data_cy,  ...props } = selectProps

    return (
        <div className={`${styles["select-wrapper"]}`}>
            <select className={`form-select ${styles.select}`} {...props}>
                {children}
            </select>
        </div>
    )
}

export default CSSelect