import { PropsWithChildren } from "react"
import styles from './css/SectionSubGroup.module.scss'


const SectionSubGroup = (props: PropsWithChildren<Omit<JSX.IntrinsicElements["div"],"title"> & {title?: string | JSX.Element, subTitle?: string, backgroundIcon?: string, toolbar?: JSX.Element}>) =>{
    const { title, subTitle, children, style, className="", backgroundIcon, toolbar, ...rest } = props

    const hasTitleOrToolbar = title || toolbar

    return <div style={style} className={`${styles.container} ${className} ${backgroundIcon?styles["with-icon"]:""}`} {...rest}>
                {backgroundIcon && <i className={`${backgroundIcon} ${styles.backgroundIcon}`}></i>}
                <div style={{position: 'relative', height: '100%'}}>
                    {hasTitleOrToolbar && <div className={`${styles['title-container']}`}>
                        <div>
                            <div className={styles.title}>{title}</div>
                            {subTitle && 
                            <div className={`text-gray-500`}>
                                {subTitle}
                            </div>
                            }
                        </div>
                        {toolbar && <div>{toolbar}</div>}
                    </div>}
                    <div style={{height: '100%'}}>
                        {children}
                    </div>
                </div>
            </div>
}

export default SectionSubGroup