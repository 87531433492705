import CSInput from "@components/Form/CSInput"
import Localization from "@localization/Index"
import { Ref, useRef, useState } from "react"
import { Add } from "./SearchableList"
import styles from './css/SearchableListAdd.module.scss'

type Props = {
    add: Add
    controlId: string
}

const SearchableListAdd = (props: Props) =>{
    const { controlId, add } = props
    const { label=Localization.AGGIUNGI, disabled=false, onAdd } = add
    const [newValue, setNewValue] = useState("")

    const inputRef = useRef<HTMLInputElement>(null)

    const onChange = (e:any)=>{
        setNewValue(e.target.value)
    }

    const addNewValue = ()=>{
        onAdd?.(newValue)
        setNewValue("")
        inputRef.current?.focus()
    }

    const onKeyPress = (e:any)=>{
        if(e.key==="Enter") {
            addNewValue()
        }
    }

    return <div className={styles.container}>
        <CSInput 
            type="text"
            ref={inputRef}
            solid
            controlId={`SearchableListAdd_${controlId}`}
            placeholder={label}
            value={newValue}
            onChange={onChange}
            onKeyPress={onKeyPress}
            width={"100%"}
            disabled={disabled}
            buttons={[
                {
                    icon: 'bi-plus-square',
                    action: ()=>{addNewValue()}
                }
            ]}
        />

    </div>
}

export default SearchableListAdd