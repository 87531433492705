import styles from './css/SupportAvatar.module.scss'

type Props = {
    shortIdentifier?: string
}

const SupportAvatar = (props:Props)=>{
    const { shortIdentifier } = props
    return <i className="bi-circle text-primary"  style={{position: 'relative', fontSize: '50px', lineHeight: 1}}>
            {shortIdentifier && <div className={`text-primary ${styles["short-identifier"]}`}>{shortIdentifier}</div>}
        </i>
}

export default SupportAvatar