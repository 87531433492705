import { Customer, CustomerStatusEnum, EntityListResponseCustomer, EntitySingleResponseCustomer } from "@csapi/provisioning"
import axios, { AxiosResponse } from "axios"
import { checkSessionExpired } from "./Utils"

interface GetCustomersParams {
    searchTerm?: string
    status?: string
    pageIndex?: number,
    pageSize?: number
    sort?: string | undefined
}

const getCustomers = async (params: GetCustomersParams) :Promise<EntityListResponseCustomer> =>{
    try {
        const { searchTerm = undefined, status = undefined, sort=undefined, pageIndex=0, pageSize=20 } = params
        const response:AxiosResponse<EntityListResponseCustomer,any> = await axios.get('api/customers', {
            params: {searchTerm, status,sort, pageIndex, pageSize}
        })
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const createCustomer = async(customer: Customer) =>{
    try {
        const params = { customer: customer }
        const response = await axios.put(`api/customers/create/`,{params})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const updateCustomer = async(id:string, customer: Customer) =>{
    try {
        const params = {id:id, customer: customer }
        const response = await axios.put(`api/customers/update/`,{params})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const updateCustomerStatus = async(id:string, status: string) =>{
    try {
        const params = {id:id, status: status }
        const response = await axios.put(`api/customers/updateStatus/`,{params})
        return response.data
    } catch(error:any) {
        throw error
    }
}


const getCustomer = async (id:string) =>{
    try {
      const response:AxiosResponse<EntitySingleResponseCustomer,any> = await axios.get(`api/customers/${id}`)
      return response.data
    } catch(error: any) {
      if(error.response?.status === 404) {
          return undefined
      }
      checkSessionExpired(error)
      throw error
  }
}

const resetCustomerPwd = async (username : string) => {
    try{
        const params = {
            username : username
        }
        const response = await axios.post('api/customers/resetpassword',{params})
        return response.data

    } catch(error:any) {
        throw error
    }
}

export { getCustomers, createCustomer, getCustomer, updateCustomer,updateCustomerStatus, resetCustomerPwd }