import CSDiv from '@components/HTMLElements/CSDiv'
import { PropsWithChildren } from 'react'
import styles from './css/Overlay.module.scss'

type OverlayProps = {
    onClose: Function
}

const Overlay = (props:PropsWithChildren<OverlayProps>) => {
    const { onClose, children } = props
    
    return (
        <div className={`${styles.overlay} d-flex justify-content-center align-items-center`} onClick={()=>{onClose()}}>
            <div className={`d-flex bg-white w-75 ${styles.container}`} style={{overflowY: 'auto'}} onClick={(e)=>{
                e.stopPropagation()
            }}>
                {children}
            </div>
        </div>
    )
}

export default Overlay
