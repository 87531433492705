import { AuthContext } from "@providers/AuthProvider";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Header from "@components/Header/Header"
import SideMenu from "@components/SideMenu/SideMenu";
import Localization from "@localization/Index";
import { ModuleIdEnum } from '@utils/ModulesUtils'
import { UserRoleEnum } from "@interfaces/User";

type Props = {
    children: JSX.Element
    modules?: (keyof typeof ModuleIdEnum)[]
    roles?: (keyof typeof UserRoleEnum)[]
}

const Protected = (props:Props):JSX.Element => {

    const auth = React.useContext(AuthContext)
    
    const { children, modules, roles=[UserRoleEnum.SUPPORT, UserRoleEnum.BASE] } = props
    const location = useLocation()
    if(auth.userData===null) {
        return <></>
    }
    if(!auth.userData) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    // check if the user has access to at least one of the modules listed in the modules property
    const modulesNotAllowed = modules && !auth.userData.modules?.find(module=>{
        return module._id && modules.includes(module._id) && module.enabled
    })

    // check if the user has the role needed for this page
    const roleNotAllowed = roles && !roles.includes(auth.userData.role)

    Localization.setLanguage(auth.userData?.defaultLang ?? 'it')

    return (
        <div style={{paddingTop:'50px'}}>
            <Header />
            <div className="d-flex" style={{marginTop: '-6px'}}>
                <div className="d-none d-md-block" style={{width: '280px', minWidth: '280px'}}>
                    <SideMenu />
                </div>
                <div className="container-fluid" style={{paddingLeft: 0}}>
                    <div style={{backgroundColor: '#F9F9F9'}}>
                        <div className="" style={{padding: "35px", backgroundColor: '#fff', borderRadius: '12px', boxShadow: "rgba(0, 0, 0, 0.03) 0px 3px 4px 0px"}}>
                        {!modulesNotAllowed && !roleNotAllowed && children}
                        {modulesNotAllowed && <p>Forbidden</p>}
                        {roleNotAllowed && <p>Forbidden</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Protected