import { getItemsByIdList } from "@api/Items";
import { Item, Order } from "@csapi/provisioning";
import { OrderItemWithInfo } from "@interfaces/Orders";
import Localization from "@localization/Index";
import { NotFoundItemInArray } from "../../../src/interfaces/Items";

enum OrderStatus {
    PENDING= "PENDING", 
    CONFIRMED= "CONFIRMED", 
    CANCELED= "CANCELED", 
    REFURBISHED= "REFURBISHED", 
    SHIPPED= "SHIPPED", 
    PARTIALLY_SHIPPED= "PARTIALLY_SHIPPED", 
    OTHER= "OTHER"
}

const orderStatuses = [
    { status: OrderStatus.PENDING, icon: "bi-pencil-square", color: "warning"},
    { status: OrderStatus.CONFIRMED, icon: "bi-check-square", color: "success"},
    { status: OrderStatus.CANCELED, icon: "bi-dash-square", color: "danger"},
    { status: OrderStatus.REFURBISHED, icon: "bi-arrow-repeat", color: "muted"},
    { status: OrderStatus.SHIPPED, icon: "bi-truck", color: "primary"},
    { status: OrderStatus.PARTIALLY_SHIPPED, icon: "bi-truck-flatbed", color: "info"},
    { status: OrderStatus.OTHER, icon: "bi-question-circle", color: "dark"},
]

enum OrderDocumentTypes {
    COMMERCIAL_INVOICE = "COMMERCIAL_INVOICE",
    AIRWAY_BILL = "AIRWAY_BILL",
    RETURN_INSTRUCTIONS = "RETURN_INSTRUCTIONS",
    RETURN_LABEL = "RETURN_LABEL",
    CITES_DECLARATION = "CITES_DECLARATION",
    ORDER_DESCRIPTION = "ORDER_DESCRIPTION",
    SHIPPING_DESCRIPTION = "SHIPPING_DESCRIPTION",
    SHIPPING_LABEL = "SHIPPING_LABEL",
    OTHER = "OTHER",
}
const orderDocumentsProperties = [
    {id: OrderDocumentTypes.COMMERCIAL_INVOICE, text: Localization.DOCUMENT_TYPES.COMMERCIAL_INVOICE, icon: "bi-receipt"},
    {id: OrderDocumentTypes.AIRWAY_BILL, text: Localization.DOCUMENT_TYPES.AIRWAY_BILL, icon: "bi-airplane"},
    {id: OrderDocumentTypes.RETURN_INSTRUCTIONS, text: Localization.DOCUMENT_TYPES.RETURN_INSTRUCTIONS, icon: "bi-arrow-counterclockwise"},
    {id: OrderDocumentTypes.RETURN_LABEL, text: Localization.DOCUMENT_TYPES.RETURN_LABEL, icon: "bi-upc-scan"},
    {id: OrderDocumentTypes.CITES_DECLARATION, text: Localization.DOCUMENT_TYPES.CITES_DECLARATION, icon: "bi-house-up"},
    {id: OrderDocumentTypes.ORDER_DESCRIPTION, text: Localization.DOCUMENT_TYPES.ORDER_DESCRIPTION, icon: "bi-clipboard-data"},
    {id: OrderDocumentTypes.SHIPPING_DESCRIPTION, text: Localization.DOCUMENT_TYPES.SHIPPING_DESCRIPTION, icon: "bi-truck"},
    {id: OrderDocumentTypes.SHIPPING_LABEL, text: Localization.DOCUMENT_TYPES.SHIPPING_LABEL, icon: "bi-upc"},
    {id: OrderDocumentTypes.OTHER, text: Localization.DOCUMENT_TYPES.OTHER, icon: "bi-question-circle"}
]

const getOrderDocumentProperties = (orderDocumentType: OrderDocumentTypes) =>{
    return orderDocumentsProperties.find(orderDocumentProperties=>orderDocumentProperties.id === orderDocumentType) ?? orderDocumentsProperties.find(orderDocumentProperties=>orderDocumentProperties.id === OrderDocumentTypes.OTHER)!
}


/**
 * Get all the order status rendering elements
 * @param status the order status
 */
const getOrderStatus = (status?:OrderStatus)=>{
    return orderStatuses.find(orderStatus=>orderStatus.status===status) ?? orderStatuses.find(orderStatus=>orderStatus.status===OrderStatus.OTHER)!
}

const getOrderItemsWithInfo = async (order:Order, lang:string)=>{
    try {
        const orderItemsIds = order.items?.map(item=>item.item_id?.$oid) as string[]
        const items = await getItemsByIdList(orderItemsIds)
        const orderItems:OrderItemWithInfo[] = items.map(item=>{
            const notFoundItem = item as NotFoundItemInArray
            if(notFoundItem.index!== undefined) {
                // manage return value of not found element
                const orderItemNotFoundOriginal = order.items?.[notFoundItem.index]
                const { item_id, price_adjustments, total_price_tax_incl, price, qty, unit_price_tax_incl, packaging_info  } = orderItemNotFoundOriginal!

                return {
                    id: item_id?.$oid ?? "",
                    sku: "",
                    img: "",
                    skuParent: "N.D.",
                    description: "N.D.",
                    brand: "N.D.",
                    variation: price_adjustments?.[0]?.reason_id,
                    variationType: price_adjustments?.[0]?.reason_title,
                    qty,
                    cost: {amount: price, currency: total_price_tax_incl?.currency ?? "EUR"},
                    unit_price: {amount: unit_price_tax_incl?.amount, currency: unit_price_tax_incl?.currency ?? "EUR"},
                    packageTypeId: packaging_info?.entries?.[0]?.package_type_id?.$oid
                }
            }

            const foundItem = item as Item

            const foundOrderItemOriginal = order.items?.find(i=>i.item_id?.$oid===foundItem.item_id?.$oid)
            const { price_adjustments, total_price_tax_incl, price, qty, unit_price_tax_incl, packaging_info  } = foundOrderItemOriginal!

            const title = foundItem?.locs?.singles?.title?.[lang] ?? (foundItem?.locs?.singles?.title?.["it"] ?? foundItem?.props?.['title'])

            return {
                id: foundItem.item_id?.$oid ?? "",
                sku: foundItem.sku,
                img: foundItem.imgs?.[0].url ?? "",
                skuParent: (foundItem.props?.parent_sku ?? "N.D.") as string,
                description: (title ?? "N.D.") as string,
                brand: (foundItem.props?.brand ?? "N.D.") as string,
                variation: price_adjustments?.[0]?.reason_id,
                variationType: price_adjustments?.[0]?.reason_title,
                qty,
                cost: {amount: price, currency: total_price_tax_incl?.currency ?? "EUR"},
                unit_price: {amount: unit_price_tax_incl?.amount, currency: unit_price_tax_incl?.currency ?? "EUR"},
                cat_ids: foundItem.cats,
                barcode: foundItem.barcodes?.[0].code ?? (foundItem.props?.barcode as unknown as string),
                packageTypeId: packaging_info?.entries?.[0]?.package_type_id?.$oid
            }
        })

        return orderItems
    } catch(err) {
        console.log(err)
        throw err
    }
}

export { OrderStatus, getOrderStatus, orderStatuses, OrderDocumentTypes, orderDocumentsProperties, getOrderDocumentProperties, getOrderItemsWithInfo }