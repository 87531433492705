import {Fragment} from "react"
import { LinkContainer } from "react-router-bootstrap"

type BreadcrumbsProps = {
    elements: Array<{text: string, link?: string}>
}

export type BreadcrumbItemProps = {
    text: string,
    link?: string
}

const BreadcrumbItem = (props:BreadcrumbItemProps) =>{
    const {text, link} = props
    return (
        <span className="text-muted">
            {link?<LinkContainer to={link}>
                <a className="cs-breadcrumb-link text-muted" href={link}>{text}</a>
            </LinkContainer>
            :text}
        </span>
    )
}

const BreadcrumbDivider = ()=>{
    return (
        <span className="ps-1 pe-1">{"-"}</span>
    )
}


const Breadcrumbs = (props:BreadcrumbsProps)=> {
    const {elements} = props

    return (
        <div data-cy="breadcrumb" className="d-flex justify-content-start align-items-center pt-1">
            {elements.map((element,index)=>{
                return <Fragment key={`bc_${index}`}>
                    {index>0?<BreadcrumbDivider />:null}
                    <BreadcrumbItem text={element.text} link={element.link} />
                </Fragment>
            })}
        </div>
    )
}

export default Breadcrumbs