import PageHeader from "@components/Page/PageHeader"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import Localization from "@localization/Index"
import { Container, Tab, Tabs } from "react-bootstrap"
import CustomerOverviewTab from "./CustomerOverviewTab"
import CustomerDetailsTab from "./CustomerDetailsTab"
import CustomerBCTab from "./CustomerBCTab"
import CustomerAddInfoTab from "./CustomerAddInfoTab"
import CustomerPwdMangerTab from "./CustomerPwdMangerTab"
import { useCustomerContext } from "./CustomerContext"
import { useParams } from "react-router-dom"
import FlagsIcon from "@components/FlagsIcon/FlagsIcon"
import { useEffect } from "react"
import styles from './css/Customers.module.scss'
import { format, parseISO } from "date-fns"


enum PageState {
    LOADING = "LOADING",
    IDLE = "IDLE",
    ERROR = "ERROR",
    NOT_FOUND = "NOT_FOUND",
}


const Customer = () => {
    const { customer, pageState, customerEntity } = useCustomerContext()
    const { id } = useParams()
    const isCustomerLoading = pageState === PageState.LOADING
    const isCustomerError = pageState === PageState.ERROR
    const isCustomerNotFound = pageState === PageState.NOT_FOUND
    const isCustomerAvailable = !isCustomerLoading && !isCustomerError && !isCustomerNotFound

    const returnTitle = isCustomerLoading ? Localization.PAGE_CUSTOMERS.LOADING : isCustomerNotFound ? Localization.PAGE_CUSTOMERS.NOT_FOUND : isCustomerError ? Localization.PAGE_CUSTOMERS.ON_ERROR : `N° ${customer?._id?.$oid ?? 'N.D.'}`

    const breadcrumbs = [
        { text: Localization.MENU.IL_TUO_CLOUDSTORE, link: '/' },
        { text: Localization.MENU.CUSTOMERS, link: '/Customers' },
        { text: `${Localization.PAGE_CUSTOMERS.CUSTOMER} ${returnTitle}` },   
    ]

    const getBillingAddress = () => {
        const add = customer?.billing_info?.address
    
        if (!add) {
            return ' - '
        }
    
        const addressParts = [
            add.address_lines?.[0],
            add.postal_code,
            add.city,
            add.province_code
        ].filter(part => part && part !== 'undefined' && part.trim() !== '')
    
        if (addressParts.length === 0) {
            return ' - '
        }
    
        return addressParts.join(' ')
    }
    

    const statusBadge= (status: string) =>{
        let variant = ''
        switch (status) {
            case "ACTIVE": 
                variant = "success"; break;
            case "INACTIVE":
                variant = "danger"; break;
            default:
                variant = "muted"; break;
        }
        return <div className="mb-1">
                    <div key={status} className={` badge fw-600 text-${variant} bg-${variant}-light`} >
                        {status==='ACTIVE'? Localization.PAGE_CUSTOMERS.STATUS.ACTIVE : Localization.PAGE_CUSTOMERS.STATUS.INACTIVE}
                    </div>
                </div>
        
    }

return (
    <>
    <PageHeader title={`${Localization.PAGE_CUSTOMERS.CUSTOMER_DETAILS}`} breadcrumbs={breadcrumbs} />
            
    <Container fluid>
        <div className="pb-1 d-flex flex-column justify-content-start">
            <div className="d-flex align-items-start mt-2">
                <div className="col-md-6">
                    <SectionGroup>
                        <div className="d-flex align-items-center justify-content-center w-100">
                            <i className="bi-person-badge text-muted" style={{fontSize: "10rem"}}></i>
                        </div>
                        <div className="d-flex align-items-center justify-content-center w-100 text-muted">
                            <div className="h3" >{customer?.first_name} {customer?.last_name} </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center w-100 text-muted">
                            <div>{customer?.security?.username}</div>
                        </div>
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col">
                                    <h5 className="mb-3 h2">Dettagli</h5>
                                    <hr/>
                                    <div className="mb-1">
                                        <div className="mb-1 text-muted"><small>{Localization.PAGE_CUSTOMERS.TABLE.NAME}</small></div>
                                        <div className={` ${styles['ellipsis']}  ${styles['customer-field']}`} title={customer?.first_name}> {customer?.first_name}</div>
                                    </div>
                                    <div className="mb-1">
                                        <div className="text-muted"><small>{Localization.PAGE_CUSTOMERS.TABLE.SURNAME}</small></div>
                                        <div className={`  ${styles['ellipsis']}  ${styles['customer-field']}`}  title={customer?.last_name}> {customer?.last_name}</div>
                                    </div>
                                    <div className="mb-1">
                                        <div className="text-muted"><small>{Localization.PAGE_CUSTOMERS.TABLE.USERNAME}</small></div>
                                        <div className={`  ${styles['ellipsis']} ${styles['customer-field']} `} title={customer?.security?.username}> {customer?.security?.username}</div>
                                    </div>
                                    <div className="mb-1">
                                        <div className=" text-muted"><small>{Localization.PAGE_CUSTOMERS.TABLE.CODE}</small></div>
                                        <div className={` ${styles['ellipsis']}  ${styles['customer-field']}`} title={customer?.customer_code}> {customer?.customer_code}</div>
                                    </div>
                                    <div className="mb-1">
                                        <div className=" text-muted"><small>{Localization.PAGE_CUSTOMERS.TABLE.STATUS}</small></div>
                                        <div> {statusBadge(customer?.status??'')}</div>
                                    </div>
                                    <div className="mb-1">
                                        <div className=" text-muted"><small>{Localization.PAGE_CUSTOMERS.TABLE.CF}</small></div>
                                        <div className={`  ${styles['ellipsis']}  ${styles['customer-field']}`}> {customer?.billing_info?.fc || customer?.billing_info?.fc !==''?customer?.billing_info?.fc : '-'}</div>
                                    </div>
                                    <div className="mb-1">
                                        <div className=" text-muted"><small>{Localization.PAGE_CUSTOMERS.TABLE.BILLING_ADDRESS}</small></div>
                                        <div className={`  ${styles['ellipsis']}  ${styles['customer-field']}`}> {getBillingAddress()}</div>
                                    </div>
                                    <div className="mb-1">
                                        <div className=" text-muted"><small>{Localization.PAGE_CUSTOMERS.CREATION_DATE}</small></div>  
                                        <div className={`  ${styles['ellipsis']}  ${styles['customer-field']}`}> {customerEntity?._metadata?.creation_dt?.$date ? format(parseISO(customerEntity?._metadata?.creation_dt?.$date), 'dd/MM/yyyy HH:mm') :'-'} </div>      
                                    </div>
                                    <div className="mb-1">
                                        <div className=" text-muted"><small>{Localization.PAGE_CUSTOMERS.UPDATED_DATE}</small></div>  
                                        <div className={`  ${styles['ellipsis']}  ${styles['customer-field']}`}> {customerEntity?._metadata?.last_update_dt?.$date ? format(parseISO(customerEntity?._metadata?.last_update_dt?.$date), 'dd/MM/yyyy HH:mm') :'-'}</div>      
                                    </div>  

                                    {customer?.billing_info?.address?.country_code?.toLocaleLowerCase &&
                                    <div className="mb-1">
                                        <div className=" text-muted"><small>{Localization.PAGE_CUSTOMERS.TABLE.COUNTRY}</small></div>
                                        <div className="d-flex align-items-center">
                                            <FlagsIcon flagId={customer?.billing_info?.address?.country_code?.toLocaleLowerCase()} size={28} round={true} />
                                            <div className="mb-0 ms-2">{Localization.COUNTRIES_CODE[customer?.billing_info?.address?.country_code as keyof typeof Localization.COUNTRIES_CODE]}</div>
                                        </div>
                                    </div>
                                    
                                    }
                                </div>
                            </div>
                        </div>
                    </SectionGroup>
                </div>
                <div className="align-items-center justify-content-center w-100 " style={{marginLeft:"35px"}}>
                
                    <Tabs
                        id="category-tabs"
                        defaultActiveKey={"t1"}
                        className='cs-tabs mt-3'>
                        <Tab eventKey={"t1"} title={Localization.PAGE_CUSTOMERS.TABS.OVERVIEW.TITLE} mountOnEnter>
                            <CustomerOverviewTab/>
                        </Tab>  
                        <Tab eventKey={"t2"} title={Localization.PAGE_CUSTOMERS.TABS.DETAILS.TITLE} mountOnEnter>
                            <CustomerDetailsTab customerId={id??''}  customer={customer}></CustomerDetailsTab>
                        </Tab>
                        <Tab eventKey={"t3"} title={Localization.PAGE_CUSTOMERS.TABS.BC.TITLE} mountOnEnter>
                            <CustomerBCTab customerId={id??''} customer={customer} ></CustomerBCTab>
                        </Tab>
                        { customer?.additional_info &&
                            <Tab eventKey={"t4"} title={Localization.PAGE_CUSTOMERS.TABS.ADD_INFO.TITLE} mountOnEnter>
                                <CustomerAddInfoTab customerId={id??''} customer={customer}></CustomerAddInfoTab>
                            </Tab>
                        }   
                        <Tab eventKey={"t5"} title={Localization.PAGE_CUSTOMERS.TABS.PWD_MAN.TITLE} mountOnEnter>
                            <CustomerPwdMangerTab customerId={id??''}  customer={customer}></CustomerPwdMangerTab>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    </Container>
    </>
)
}

export default Customer