import React from 'react'
import SectionGroup from '@components/SectionGroup/SectionGroup'
import Localization from '@localization/Index'
import { format, parseISO } from 'date-fns'
import { statusIndicator } from './ModuleDetailsUtils'
import styles from '../css/Modules.module.scss'

interface ModuleDetailsGeneralInfoProps {
  id: string
  status?: string | undefined
  startDate?: string | undefined
  loading?: boolean,
}

const ModuleDetailsGeneralInfo: React.FC<ModuleDetailsGeneralInfoProps> = ({ id, status, startDate, loading=false}) => {
    
  if(loading) return <div className={`pt-4 pb-4 mt-3 mb-3 d-flex ${styles["container-loading"]} loading-skeleton`}></div>
  return (
    <>
      <SectionGroup title={Localization.PAGE_MODULES.TITLES_DETAILS[id as keyof typeof Localization.PAGE_MODULES.TITLES_DETAILS] ?? "N.D."} toolbar={statusIndicator( status ??'' )}>
        { startDate &&
          <small className="px-2 py-1 d-flex justify-content-end align-items-center">
            {Localization.PAGE_MODULES.UPDATE_DATE}: {startDate ? format(parseISO(startDate), 'dd/MM/yyyy HH:mm') : 'N.D.'}
          </small>
        }
        <div className="pt-4 d-flex justify-content-end align-items-center">
          {Localization.PAGE_MODULES.DESCRIPTIONS_LONG[id as keyof typeof Localization.PAGE_MODULES.DESCRIPTIONS_LONG]}
        </div>
      </SectionGroup>
    </>
  )
}

export default ModuleDetailsGeneralInfo
