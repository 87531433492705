import { PropsWithChildren } from "react"
import styles from './css/TimeLine.module.scss'

type Props = {

}

const TimeLine = (props: PropsWithChildren<Props>) =>{
    const { children } = props

    return <div className={styles.timeline}>
        {children}
    </div>
}

export default TimeLine