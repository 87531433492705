import styles from './css/SideMenuGroupItem.module.scss'
import { UserRoleEnum } from "@interfaces/User"
import { ModuleIdEnum } from "@utils/ModulesUtils"
import React from "react"
import { AuthContext } from "@providers/AuthProvider"
import { LinkContainer } from 'react-router-bootstrap'

type Props = {
    name: string,
    to: string,
    selected: Boolean
    modules?: (keyof typeof ModuleIdEnum)[]
    roles?: (keyof typeof UserRoleEnum)[]
}

const SideMenuGroupItem = (props:Props) =>{
    const auth = React.useContext(AuthContext)
    const { name, to, selected, roles=[UserRoleEnum.SUPPORT, UserRoleEnum.BASE], modules } = props

    // check if the user has access to at least one of the modules listed in the modules property
    const modulesNotAllowed = modules && !auth.userData.modules?.find(module=>{
        return module._id && modules.includes(module._id) && module.enabled
    })

    // check if the user has the role needed for this page
    const roleNotAllowed = roles && !roles.includes(auth.userData.role)

    if(modulesNotAllowed || roleNotAllowed) return <></>

    return (
        <div className={`${styles.container} ${selected?styles.selected:''}`}>
            <div className={`${styles.bullet} ${selected?styles.selected:''}`}>
                <LinkContainer to={to} style={{fontWeight: '600', fontSize: '1.10rem'}}>
                        <span role="button" className={`${selected?"text-primary":"text-gray-700"} text-decoration-none`} >
                            {name}
                        </span>
                </LinkContainer>
            </div>
        </div>
    )
}

export default SideMenuGroupItem