import SectionGroup from '@components/SectionGroup/SectionGroup'
import PageHeader from '@components/Page/PageHeader'
import ModuleDetailsGeneralInfo from './ModuleDetailsGeneralInfo'

import Localization from '@localization/Index';
import CSDiv from '@components/HTMLElements/CSDiv'
import { DefaultModuleDetailProps } from './ModuleDetailsUtils';

const ModuleLoadingError = (props : DefaultModuleDetailProps) => {
    const { id } = props
    
  const breadcrumbs = [
    { text: Localization.MENU.IL_TUO_CLOUDSTORE, link: '/' },
    { text: Localization.MENU.MODULI, link: '/modules' },
    { text: Localization.PAGE_MODULES.TITLES_DETAILS[id as keyof typeof Localization.PAGE_MODULES.TITLES_DETAILS] }
  ];

  return (
    <SectionGroup>
      <PageHeader title={Localization.PAGE_MODULES.TITLES.DETAILS_TITLE} breadcrumbs={breadcrumbs} />
      <ModuleDetailsGeneralInfo id={id} />
      <CSDiv fillVertical className="d-flex flex-column align-items-center justify-content-center">
        <i className="bi-dash-circle text-danger font-5x mb-3"></i>
        <span>{Localization.PAGE_MODULES.ERROR_LOADING}</span>
      </CSDiv>
    </SectionGroup>
  );
}

export default ModuleLoadingError;
