import { LinkContainer } from "react-router-bootstrap"

type Props = {}

const HeaderLogs = (_:Props) =>{

    return (
        <LinkContainer to="/logs">
            <a className="position-relative pe-3 cs-link" href="/logs">
                <i className="bi-bell text-muted" style={{fontSize: '1.75rem'}} />
            </a>
        </LinkContainer>
    )
}

export default HeaderLogs