import SectionGroup from '@components/SectionGroup/SectionGroup'
import { useState, useEffect } from 'react'
import { PageStates } from "@utils/PageStateUtils"
import { getModuleDetails, updateModuleDetails } from '@api/Modules'
import Localization from '@localization/Index'
import PageHeader from '@components/Page/PageHeader'
import CSSwitch from '@components/Form/CSSwitch'
import { askConfirmation, closeWaiting, showWaiting, toastError } from '@utils/NotificationsUtils'
import { DefaultModuleDetailProps, ModuleT} from './ModuleDetailsUtils'
import ModuleDetailsGeneralInfo from './ModuleDetailsGeneralInfo'
import { ModuleWarehouses, ModuleWarehousesAlgorithmEnum } from '@csapi/provisioning/api'
import CSReactSelect, { CSOption } from '@components/Form/CSReactSelect'
import SectionSubGroup from '@components/SectionGroup/SectionSubGroup'
import ModuleLoadingError from './ModuleLoadingError'



const ModuleDetailsWarehouses = (props :DefaultModuleDetailProps) => {
  const { id: rawId } = props
  const id = rawId?.toUpperCase()

  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(false)
  const [startDate, setStartDate] = useState<string | undefined>(undefined)
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [module, setModule] = useState<ModuleWarehouses | undefined>(undefined)
  const [pageState, setPageState] = useState<PageStates>(PageStates.INIT)
  const [loading, setLoading] = useState<boolean>(true)
  const [whsAlgoSelect , setWhsAlgoSelect] = useState<CSOption>()
  const [algoDescription, setAlogDescription] = useState('')
  
  const breadcrumbs = [
    {text: Localization.MENU.IL_TUO_CLOUDSTORE, link: '/'},
    {text: Localization.MENU.MODULI, link: '/modules'},
    {text: Localization.PAGE_MODULES.TITLES_DETAILS[id as keyof typeof Localization.PAGE_MODULES.TITLES_DETAILS]}
  ]
  
  const updateModule = async () => {
    const confirmResponse = await askConfirmation({
      title: <p>{Localization.PAGE_MODULES.UPDATE_CONFIRM.replace("__MODULE__",`${Localization.PAGE_MODULES.TITLES_DETAILS[id as keyof typeof Localization.PAGE_MODULES.TITLES_DETAILS] ?? "N.D."}`)}</p>
    })
    if (!confirmResponse.isConfirmed) return false
    try {
      showWaiting(Localization.ATTENDERE)
      if (id && module) {
        await updateModuleDetails(id, module)
      }
      closeWaiting()
      loadModule()
    } catch (e) {
      closeWaiting()
      toastError(Localization.ERRORE_GENERICO)
    }
  }

  const loadModule = async () => {
    console.log(`Loading module`)
    try {
      if (!id) {
        setPageState(PageStates.ERROR)
        return
      }
      setPageState(PageStates.LOADING)

      const resp = await getModuleDetails(id)
      if (!resp?.content) {
        setPageState(PageStates.ERROR)
        return
      }
      const moduleData = resp.content as ModuleT
      setModule(moduleData)
      setIsEnabled(moduleData?.enabled)
      setStartDate(moduleData?.last_update_dt?.$date)
      setStatus(moduleData?.status)
      setWhsAlgoSelect(documentTypesForSelect?.find(algo => algo.value===module?.algorithm))
      setAlogDescription(Localization.PAGE_MODULES.WAREHOUSES.ALGO_DESC[module?.algorithm as keyof typeof Localization.PAGE_MODULES.WAREHOUSES.ALGO_DESC])
      setPageState(PageStates.IDLE)
    } catch (e: any) {
      console.error(e)
      setPageState(PageStates.ERROR)
    }
  }


  const documentTypesForSelect:CSOption[] = 
      [
        {
        label: Localization.PAGE_MODULES.WAREHOUSES.NO_OP,
        value: 'NO_OP'
        },
        {
          label: Localization.PAGE_MODULES.WAREHOUSES.WHS_PRIORITY,
          value: 'WHS_PRIORITY'
        },
        {
          label: Localization.PAGE_MODULES.WAREHOUSES.TEMP_COMMIT,
          value: 'TEMP_COMMIT'
        }
    ]

  useEffect(() => {
    loadModule()
  }, [])

  const handleToggle = () => {
    setIsEnabled(!isEnabled)
  }

  
  const changeWhsAlgo = (algo_whs_options: CSOption) => {
    if (module && algo_whs_options.value) {
        module.algorithm = algo_whs_options.value as ModuleWarehousesAlgorithmEnum
        setWhsAlgoSelect(documentTypesForSelect?.find(algo => algo.value===algo_whs_options.value))
        setAlogDescription(Localization.PAGE_MODULES.WAREHOUSES.ALGO_DESC[algo_whs_options.value as keyof typeof Localization.PAGE_MODULES.WAREHOUSES.ALGO_DESC])
    }
  }
  
  

  return (
    <> 
      {pageState === PageStates.DATA_LOADING && <SectionGroup><ModuleDetailsGeneralInfo id={id} status={status} startDate={startDate}  loading /></SectionGroup>} 
      {pageState === PageStates.ERROR && <ModuleLoadingError id={id} />}  
      {pageState === PageStates.IDLE &&
        <div>
          <PageHeader title={Localization.PAGE_MODULES.TITLES.DETAILS_TITLE} breadcrumbs={breadcrumbs} />
          <ModuleDetailsGeneralInfo id={id} status={status} startDate={startDate} />
          <SectionGroup title={Localization.PAGE_MODULES.GENERAL_CONFIG}>  
            <SectionSubGroup title={Localization.PAGE_MODULES.ACTIVATE_MODULE}>  
              <div className="d-flex w-100 custom-flex-container">
                <CSSwitch
                  className="mt-field pt-3"
                  label={Localization.PAGE_MODULES.ENABLE_DISABLED}
                  controlId={'status-switch'} 
                  onChange={handleToggle}
                  checked={isEnabled ?? false}
                />
              </div>
            </SectionSubGroup>
            <SectionSubGroup title={Localization.PAGE_MODULES.WAREHOUSES.ALGORITMO}>
            <div className="row">
              <div className="col-md-8">  
                  <div className="d-flex w-100 custom-flex-container ">
                    <CSReactSelect
                      controlId='Whs_algo'
                      className="mt-field"
                      label={Localization.PAGE_MODULES.WAREHOUSES.SELECT_ALGO}
                      value = {whsAlgoSelect}
                      options={documentTypesForSelect}
                      onChange={(algo_whs_options :CSOption)=> {return changeWhsAlgo(algo_whs_options)}}
                      client
                      helpInline={<p>{algoDescription}</p>}
                    />
                  </div>
                  
                </div>
              </div>
            </SectionSubGroup>        
          </SectionGroup>
          <div className="d-flex w-100 mt-5 justify-content-end">
            <button onClick={updateModule} className='m-1 btn mx-2 btn-primary'>{Localization.MODIFICA}</button>
          </div> 
        </div>
      }  
    </>
  )
}

export default ModuleDetailsWarehouses
