type Props = {
    icon?: string,
    label?: string | JSX.Element,
    value?: string | JSX.Element
    valuePosition?: "RIGHT" | "BOTTOM"
}

const SectionGroupInlineItem = (props:Props) =>{
    const {icon, label, value, valuePosition="RIGHT"} = props

    if(valuePosition==="RIGHT") return <div className="d-flex justify-content-between align-items-center mb-2 pt-3 pb-3" style={{borderBottom: '1px solid #f1f1f4'}}>
                <div className="d-flex justify-content-left align-items-center">
                    {icon && <i className={`${icon} font-1-5x text-muted me-2`}></i>}
                    <label className="text-muted">{label}</label>
                </div>
                <div className="text-end">{value}</div>
            </div>
    
    return <div className="d-flex flex-column justify-content-center align-items-start mb-2 pt-3 pb-3" style={{borderBottom: '1px solid #f1f1f4'}}>
        <div className="d-flex justify-content-left align-items-center">
            {icon && <i className={`${icon} font-1-5x text-muted me-2`}></i>}
            <label className="text-muted">{label}</label>
        </div>
        <div>{value}</div>
    </div>
}

export default SectionGroupInlineItem