import CSDiv from "@components/HTMLElements/CSDiv"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import { Customer} from "@csapi/provisioning/api"
import CSInput from "@components/Form/CSInput"
import Localization from "@localization/Index"
import CSSwitch from "@components/Form/CSSwitch"
import { useCustomerContext } from "./CustomerContext"


type CustomerOverviewTabProps={
    customerId: string
    customer: Customer | undefined
}

const CustomerPwdMangerTab = (props: CustomerOverviewTabProps) =>{
    const { customer } = props
    const { changeStatus, resetPassword } = useCustomerContext()

    return <>
                <div className="row mt-5">
                    {customer  &&                        
                        
                        <CSDiv id="CustomerOrdersContainer" className="overflow-auto" fillVertical offsetVertical={100}>
                            <SectionGroup>
                                <div className="row">
                                    <div className="col-sm-8 col-md-8 mt-field">
                                        <CSInput 
                                            type="text"
                                            controlId={`fc`}
                                            label={Localization.PAGE_CUSTOMERS.TABLE.USERNAME}
                                            placeholder=""
                                            value={customer?.security?.username}
                                            disabled={true}
                                            width="100%"
                                        />
                                    </div> 
                                    <div className="col-sm-6 col-md-6 mt-field">
                                    <CSSwitch
                                        className="mt-field pt-3"
                                        label={Localization.PAGE_CUSTOMERS.TABS.PWD_MAN.E_D_USER}
                                        controlId={'enabledisableUser'} 
                                        onChange={changeStatus}
                                        checked={customer.status ==='ACTIVE'}
                                    />
                                    </div>
                                    <div className="col-sm-6 col-md-6 mt-field">
                                        <button onClick={resetPassword}  className=' btn   mt-field pt-3 btn-warning '>{Localization.PAGE_CUSTOMERS.TABS.PWD_MAN.RESET_PWD} </button>
                                    </div>
                                </div>        
                            </SectionGroup>
                        </CSDiv>
                    }    
                </div>
            </>

}

export default CustomerPwdMangerTab

