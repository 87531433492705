import { ForwardedRef, forwardRef } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Form from "react-bootstrap/esm/Form"
import FormLabel from "react-bootstrap/esm/FormLabel"
import CSLabel from "./CSLabel"
import styles from './css/CSInput.module.scss'

type Props = {
    type: string,
    controlId: string,
    autoFocus?: boolean
    label?: string,
    value?: string | number,
    solid?: boolean
    onChange?: (e:any)=>void,
    onClick?: (e:any)=>void,
    onBlur?: (e:any)=>void,
    onKeyPress?: (e:any)=>void,
    hasError?: boolean,
    errorText?: string,
    disabled?: boolean,
    readonly?: boolean,
    mandatory?: boolean,
    width?: string
    className?: string
    maxlength?: number,
    min?: number
    max?: number
    step?: number,
    buttons?: {icon: string, action?: (e:any)=>void, tooltip?: string | JSX.Element}[]
    helpInline?: JSX.Element
    placeholder?: string
    icon?: string
    data_cy?: string
    onClear?: ()=>void
}

const CSInput = (props:Props, ref:ForwardedRef<HTMLInputElement>) => {

    const {autoFocus=false, mandatory=false, min, max, step, maxlength, className, type, controlId, label, value, onChange, onBlur, onClick, onKeyPress, hasError, errorText, disabled=false, readonly=false, width="150px", buttons, helpInline, placeholder, solid=false, icon, onClear, data_cy } = props

    const controlStyle:React.CSSProperties = {width}
    if(hasError) {
        controlStyle.borderColor = '#dc3545'
    }

    return (
        <Form.Group className={className} style={{position: 'relative'}} data-cy={`select-${data_cy}`}>
            {label && <CSLabel mandatory={mandatory} label={label} helpInline={helpInline} />}
            {!readonly ? <div style={{position: 'relative', width}}>
                                {icon && <div className="cs-input-icon">
                                    <i className={icon} />
                                </div>}
                                <Form.Control 
                                type={type}
                                id={controlId}
                                autoFocus={autoFocus}
                                maxLength={maxlength}
                                onChange={(e)=>{onChange?.(e)}}
                                onBlur={(e)=>{onBlur?.(e)}}
                                onClick={(e)=>{onClick?.(e)}}
                                onKeyPress={(e)=>{onKeyPress?.(e)}}
                                ref={ref}
                                value={value}
                                disabled={disabled}
                                min={min}
                                max={max}
                                step={step}
                                className={`${styles.input} ${solid?styles.solid:``} ${icon?styles.icon:``} ${buttons?styles[`withbuttons-${buttons.length}`]:``}`}
                                style={controlStyle}
                                placeholder={placeholder}
                            />
                            {((buttons && buttons.length>0) || onClear) &&
                                <div className={styles.buttons}>
                                    {onClear && value && ((typeof value === 'string' && value.length>0) || (value === undefined)) &&
                                        <span key={`${controlId}_btns_clearable`} role="button" className={`ms-2 ${styles.button}`}>
                                        <i className={'bi-x'} onClick={()=>{onClear()}}></i>
                                    </span>
                                    }
                                    {buttons?.map(button=>{
                                        const { icon, action, tooltip } = button
                                        const tooltipComponent = tooltip?(
                                            <Tooltip id="tooltip" style={{position:'fixed'}}>
                                                {tooltip}
                                            </Tooltip>
                                        ):<></>

                                        if(tooltip) {
                                            return <OverlayTrigger key={`${controlId}_btns_${icon}`} placement="top" overlay={tooltipComponent}>
                                                        <span role="button" className={`ms-2 ${styles.button}`}>
                                                            <i className={icon} onClick={action}></i>
                                                        </span>    
                                                </OverlayTrigger>
                                        }
                                        
                                        return <span key={`${controlId}_btns_${icon}`} role="button" className={`ms-2 ${styles.button}`}>
                                                <i className={icon} onClick={action}></i>
                                            </span>
                                    })}
                                </div>
                            }
                        </div>
                : 
            <div style={{minWidth: width}}>
                <p className="text-muted small"><strong>{value || <>&nbsp;</>}</strong></p>
            </div>}
            <FormLabel className='cs-text text-danger' style={{position: 'absolute', bottom:'-25px'}} visuallyHidden={!hasError}>
                <small>{errorText}</small>
            </FormLabel>
        </Form.Group>
    )
}

export default forwardRef(CSInput)