import axios from "axios"

const login = async (username: string, password: string) =>{
    try {
        await axios.post('api/auth/login', {username, password})
        return
    } catch(error:any) {
        throw error
    }
}

const logout = async () =>{
    try {
        await axios.post('api/auth/logout')
        return
    } catch(error:any) {
        throw error
    }
}

const getSession = async () =>{
    try {
        const response = await axios.get('api/auth/get-session')
        return response.data
    } catch(error:any) {
        throw error
    }
}

const getEnvironment = async () =>{
    try {
        const response = await axios.get('api/auth/get-environment')
        return response.data
    } catch(error:any) {
        throw error
    }
}

export {login, logout, getSession, getEnvironment }