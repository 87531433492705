import { Category, EntityId, EntityListResponseCategory } from '@csapi/provisioning/api'
import {setLocalStorageItem, getLocalStorageItem} from '@utils/LocalStorage'
import {getCategories as _getCategories} from '@api/Categories'
import { TreeNode } from '@components/Form/CSReactTreeSelect'
import { DEFAULT_LANG } from '@utils/Constants'
import { SystemCategory } from '@csapi/system/api'
import { getShopCategoriesFromId } from './ShopCategoriesUtils'


/**
 * get from local storage the flat list of categories if exists, else load them from server
 * @returns the flat list of categories
 */
const getCategories = async ()=>{
    let categories = getLocalStorageItem('user.categories') as Category[]
    if(!categories) {
        // fetch categories from server
        const categoriesResponse = await _getCategories()
        categories = categoriesResponse.content ?? []
        // set categories in the local storage
        setLocalStorageItem('user.categories', categories)
    }

    return categories
}

/**
 * return the categories in form of a bidimensional array, where the first dimension is the level and the second is the categories in that level
 */
const getCategoriesByLevel = async()=>{
    let categoriesByLevel = getLocalStorageItem('user.categories.bylevel') as Category[][]
    if(!categoriesByLevel) {
        categoriesByLevel = [[]]
        const categories = await getCategories()
        let currentMaxLevel = 0
        categories.forEach(category=>{
            const { parent_id } = category
            if(!parent_id) {
                // root
                categoriesByLevel[0].push(category)
                return
            }

            let currentCategoryLevel = 0
            let currentParentId:EntityId | undefined = parent_id

            while(currentParentId!==undefined) {
                const parent = categories.find(category=>category._id?.$oid === currentParentId?.$oid )
                if(!parent) {
                    currentParentId = undefined
                    break
                }
                currentParentId = parent.parent_id
                currentCategoryLevel++
                if(currentCategoryLevel>currentMaxLevel) {
                    currentMaxLevel = currentCategoryLevel
                    categoriesByLevel.push([])
                }
            }

            categoriesByLevel[currentCategoryLevel].push(category)
        })
        setLocalStorageItem('user.categories.bylevel', categoriesByLevel)
    }

    return categoriesByLevel
}

/**
 * get the categories mapped as an object for the tree component
 */
const getCategoriesForTree = async (language:string=DEFAULT_LANG)=>{
    const allCategories = (await getCategories()).sort((c1,c2)=>{
        const compareC1 = c1.name?.locs?.[language]?.toLowerCase() ?? "N.D."
        const compareC2 = c2.name?.locs?.[language]?.toLowerCase() ?? "N.D."
        if(compareC1 > compareC2) return 1
        if(compareC1 < compareC2) return -1
        return 0
    })
    // convert to the tree version
    const rootCategories = allCategories.filter(category=>!category.parent_id)

    const categories = getCategoriesFromParent(language, allCategories, rootCategories)
    // get the root categories

    // return categories as tree
    return categories 

}

/**
 * 
 * @param language the language to be used
 * @param allCategories the list of all categories
 * @param currentLevelCategories the list of categories of current level in the recursion
 * @returns the complete tree of categories children of the current level categories given
 */
const getCategoriesFromParent = (language: string, allCategories: Category[], currentLevelCategories:Category[]):TreeNode[] => {
    const categories:TreeNode[] = []
    currentLevelCategories.forEach(category => {
        const node: TreeNode = {
            value: category._id?.$oid!,
            label: category.name?.locs?.[language] ?? "N.D.",
            checked: false
        }
        const subCategories = allCategories.filter(subCategory => subCategory.parent_id?.$oid === category._id?.$oid)
        if(subCategories.length > 0) {
            node.children = getCategoriesFromParent(language, allCategories, subCategories)
        }
        categories.push(node)
    })
    return categories
}

/**
 * 
 * @param allCategories the list of all categories
 * @param currentLevelCategories the list of categories of current level in the recursion
 * @returns the complete tree of categories children of the current level categories given
 */
const getSystemCategoriesFromParent =  (allCategories: SystemCategory[], currentLevelCategories: SystemCategory[], showCheckbox:boolean): TreeNode[] => {
    const categories: TreeNode[] = [];
    
    // Itera sulle categorie del livello corrente
    for (const category of currentLevelCategories) {
        const node: TreeNode = {
            value: category._id?.$oid!,
            label: category.name ?? 'N.D',
            showCheckbox: showCheckbox??true,
            checked:false
        };

        // Trova le sottocategorie di questa categoria
        const subCategories = allCategories.filter(subCategory => subCategory.parent?.$oid === category._id?.$oid)

        // Se ci sono sottocategorie, chiama ricorsivamente la funzione per ottenere i loro nodi
        if (subCategories.length > 0) {
            node.children =  getSystemCategoriesFromParent(allCategories, subCategories, showCheckbox)
        }
        
        // Aggiungi il nodo alla lista delle categorie
        categories.push(node);
    }
    
    return categories;
};



/**
 * get the labels of the category ids given
 * @param language the language to be used
 * @param categories the list of category id
 */
const getLabelsFromCategories = (language: string, categories:string[]) => {
    const allCategories =  getLocalStorageItem('user.categories') as Category[]
    if(!allCategories) return []
    const labels = allCategories.filter(category => categories.includes(category._id?.$oid!)).map(category=>{
        return category.name?.locs?.[language] ?? "N.D."
    })
    return labels
}


export { getCategories, getCategoriesForTree, getLabelsFromCategories, getCategoriesFromParent, getSystemCategoriesFromParent, getCategoriesByLevel}