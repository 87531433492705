import { ShopIconProps } from '@interfaces/Icons'
import { getCSShop } from '@utils/ShopsUtils'
import { memo } from 'react'

const ShopIcon = (props: ShopIconProps) => {
    
    const { id, size=18, extended=false } = props

    const selectedShop = getCSShop(id)
    if(!selectedShop) return <></>

    const name = (extended)?selectedShop.ICONS.EXTENDED: selectedShop.ICONS.SHORT

    return (
        <img src={`/shops/${name}.png`} style={{height: `${size}px`, aspectRatio: 'auto'}} />
    )
}

export default memo(ShopIcon)