import { useEffect, useRef, useState } from "react"

type CallBackType<T> = (updatedValue: T) => void

type SetStateType<T> = T | ((prev: T) => T)

type RetType = <T>(
    initialValue: T | (() => T)
) => [T, (newValue: SetStateType<T>, callback?: CallBackType<T>) => void]

const useCallbackState: RetType = <T>(initialValue: T | (() => T)) => {
    const [state, _setState] = useState<T>(initialValue)
    const callbackQueue = useRef<CallBackType<T>[]>([])

    useEffect(() => {
        callbackQueue.current.forEach((cb) => cb(state))
        callbackQueue.current = []
    }, [JSON.stringify(state)])

    const setState = (newValue: SetStateType<T>, callback?: CallBackType<T>) => {
        if (callback && typeof callback === "function") {
            callbackQueue.current.push(callback);
        }
        if(typeof newValue === "function") {
            let newFunction = newValue as ((prev:T)=>T)
            
            _setState((prev:T)=>{
                const newState = newFunction(prev)
                return newState
            })
        } else {
            _setState(newValue)
        }
    };
    return [state, setState]
};

export default useCallbackState