import { ModuleB2B, ModuleBusinessConditions, ModuleERP, ModuleFinance, ModuleFxFF, ModuleMarketplaceSavait, ModuleNetSuite, ModulePOS, ModuleWarehouses } from "@csapi/provisioning"
import Localization from "@localization/Index"

export type ModuleT =  
| ModuleB2B 
| ModuleBusinessConditions 
| ModuleERP 
| ModuleFinance 
| ModuleFxFF 
| ModuleMarketplaceSavait 
| ModuleNetSuite 
| ModulePOS 
| ModuleWarehouses


export const statusMap = {
    NOT_APPLICABLE: { text: Localization.PAGE_MODULES.STATUS.NOT_APPLICABLE, icon: "bi-x-circle", className: "text-warning" },
    PENDING: { text: Localization.PAGE_MODULES.STATUS.PENDING, icon: "bi-hourglass", className: "text-warning" },
    VALIDATING: { text: Localization.PAGE_MODULES.STATUS.VALIDATING, icon: "bi-search", className: "text-info" },
    CONFIRMED: { text: Localization.PAGE_MODULES.STATUS.CONFIRMED, icon: "bi-check-circle", className: "text-success" },
    AUTH_ERROR: { text: Localization.PAGE_MODULES.STATUS.AUTH_ERROR, icon: "bi-exclamation-circle", className: "text-danger" },
    ERROR: { text: Localization.PAGE_MODULES.STATUS.ERROR, icon: "bi-exclamation-triangle", className: "text-danger" }
  }
  
export type DefaultModuleDetailProps= {
    id: string
}


export const statusIndicator = (status: string  ) => {
  const statusInfo = status ? statusMap[status as keyof typeof statusMap] : null;
  if (!statusInfo) return <div></div>;

  return (
    <div className="d-flex justify-content-end align-items-center">
      <h1 className={`d-flex justify-content-end align-items-center ${statusInfo.className}`}>
        {statusInfo.text}
      </h1>
      <i className={`px-2 ${statusInfo.icon} ${statusInfo.className}`} style={{ fontSize: "18px" }} />
    </div>
  )
}