import { ItemWithInfo } from '@interfaces/Items'
import Localization from '@localization/Index'
import { useNavigate } from 'react-router-dom'
import styles from './css/ItemHeader.module.scss'

type Props = {
    item?: ItemWithInfo,
    onClick?: (e: React.MouseEvent<HTMLDivElement>)=>unknown
}

const ItemHeader = (props:Props) =>{
    const gotoSkuParent = (e: React.MouseEvent<HTMLDivElement>)=>{
        if(!skuParent) return
        navigate(`/items/${encodeURIComponent(skuParent)}?t=4`) //FIXME: change tab number to match the one corresponding to the sku tab in item details
    }

    const { item, onClick=gotoSkuParent } = props
    const navigate = useNavigate()


    const { sku, skuParent, description, brand, img,  } = item!

    return <>
        <div className='d-flex align-items-center justify-content-start'>
            {sku && <>
                <div>
                    <span onClick={onClick} role="button" className={styles.imageContainer} style={{backgroundImage: `url(${img})`}}></span>
                </div>
                <div className='ms-3'>
                    <div role="button" className={styles.product} onClick={onClick}>
                        {sku}
                    </div>
                    <div className={`${styles.subproduct} text-muted`}>{brand} / {description}</div>
                </div>
                </>}
            {!sku && <>
                <div>
                    <div className={`${styles.notFoundImage} d-flex align-items-center justify-content-center`}>
                        <i className='bi-question text-muted font-4x'></i>
                    </div>
                </div>
                <div className='ms-3'>
                    <div className='d-flex align-items-center justify-content-start'>{Localization.PAGE_ORDER.ARTICOLO_NON_PRESENTE}</div>
                </div>
                </>}
        </div>
    </>
}

export default ItemHeader