import React, { useState } from 'react'
import { AuthContext } from "@providers/AuthProvider"
import { DEFAULT_LANG, SUPPORTED_LANGUAGES } from '@utils/Constants'
import NavDropdown from 'react-bootstrap/esm/NavDropdown'
import Spinner from 'react-bootstrap/esm/Spinner'
import { changeLanguage } from '@api/User'
import Localization from '@localization/Index'
import styles from './css/HeaderLanguage.module.scss'

type Props = {

}

const HeaderLanguage = (props:Props) => {

    const auth = React.useContext(AuthContext)
    const currentLanguage = auth.userData?.defaultLang ?? DEFAULT_LANG

    const availableLanguages = SUPPORTED_LANGUAGES.filter(language=>language!==currentLanguage)
    const [loading, setLoading] = useState(false)

    const setLanguage = async (language:string) =>{
        if(!language) return false
        try {
            setLoading(true)
            const response = await changeLanguage(language)
            console.log(response)
            Localization.setLanguage(auth.userData?.defaultLang ?? language)
            await auth.setUserData()
            return
        } catch(err) {

        } finally {
            setLoading(false)
        }
    }

    return (
        <>
        {!loading && 
        <NavDropdown className={`justify-content-end me-2 ${styles.navdropdown}`} id="cs-header-language-dropdown"  title={
            <span className="text-secondary me-1">
                <img alt="" src={`/flags/${currentLanguage}.png`} style={{width: '1.75rem'}} />
            </span>
        }>
            {availableLanguages.map(language=>{
                return (
                    <NavDropdown.Item key={`header-language-${language}`} style={{minWidth: '16px'}} onClick={()=>{setLanguage(language)}}>
                        <div className="d-flex align-items-center text-muted">
                            <img alt="" src={`/flags/${language}.png`} style={{width: '1.75rem'}} />
                        </div>
                    </NavDropdown.Item>
                )
            })}
        </NavDropdown>
        }
        {loading && <Spinner animation="border" variant="primary" className='me-4' size='sm' />}
        </>

    )
}

export default HeaderLanguage