import styles from './css/TimeLineElement.module.scss';
import format from "date-fns/format"

type Props = {
    datetime?: Date
    color?: string
    variant?: string
    title?: JSX.Element
    content: JSX.Element
    display?: "DATETIME"|"DATE"|"TIME"
}

const TimeLineElement = (props: Props) =>{
    const { datetime, color, variant, title, content, display="DATETIME" } = props


    return <div className={styles.timelineElement}>
        <div className={`d-flex flex-column align-items-end justify-content-start ${styles.timelineElementDateTime}`}>
            {["DATETIME", "DATE"].includes(display) && 
                <div className={styles.timelineElementDate}>{datetime?format(datetime, "dd/MM/yyyy"):""}</div>
            }
            {["DATETIME", "TIME"].includes(display) && 
                <div className={styles.timelineElementTime}>{datetime?format(datetime, "HH:mm:ss"):""}</div>
            }
        </div>
        <div className={styles.timelineElementBadge}>
            <i className={`bi-circle ${variant?`text-${variant}`:""}`} style={{...(color && {color})}}></i>
        </div>
        <div className={`ms-3 text-muted ${styles.timelineElementContent}`}>
            {title && <div>{title}</div>}
            {content && <div>{content}</div>}
        </div>
    </div>
}

export default TimeLineElement