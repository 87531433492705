import { AuthContext } from "@providers/AuthProvider"
import React from "react"
import NavDropdown from "react-bootstrap/esm/NavDropdown"
import UserMenuItem from "@components/UserMenu/UserMenuItem"
import Localization from "@localization/Index"
import styles from './css/UserMenu.module.scss'
import { UserRoleEnum } from "@interfaces/User"
import BaseAvatar from "./BaseAvatar"
import SupportAvatar from "./SupportAvatar"

type Props = {

}

const UserMenu = (props:Props) =>{

    const auth = React.useContext(AuthContext)

    const { userData } = auth
    const { companyname, firstname, lastname, username } = userData

    const mainName = firstname && lastname ? `${firstname} ${lastname}`:companyname

    const avatar = (userData.role === UserRoleEnum.SUPPORT) ? <SupportAvatar shortIdentifier={userData.shortIdentifier} /> :  <BaseAvatar />
    
    return (
        <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-left">
                <NavDropdown align={"end"} id="cs-header-user-dropdown" className={styles.navdropdown} title={avatar}>
                        <NavDropdown.ItemText className="px-3">
                            <div className="px-3 pt-2 d-flex align-items-center justify-content-start">
                                {avatar}
                                <div className="ms-4 d-flex flex-column align-items-left justify-content-start">
                                    <span className={styles.mainName}>{mainName}</span>
                                    <span className={`text-muted ${styles.username}`}>{username}</span>
                                </div>
                            </div>
                        </NavDropdown.ItemText>
                        <NavDropdown.Divider className={styles.separator}></NavDropdown.Divider>
                        <UserMenuItem to="/userprofile" label={Localization.CAMBIA_PASSWORD} />
                        <UserMenuItem to="/subusers" label={Localization.SOTTOUTENTI} />
                        <UserMenuItem to="/billing" label={Localization.BILLING} />
                        <NavDropdown.Divider className={styles.separator}></NavDropdown.Divider>
                        <UserMenuItem to="/modules" label={Localization.MODULI} />
                        <NavDropdown.Divider className={styles.separator}></NavDropdown.Divider>
                        <UserMenuItem to="/logout" label={Localization.LOGOUT} />
                </NavDropdown>
            </div>
        </div>
    )
}

export default UserMenu