import CSDiv from "@components/HTMLElements/CSDiv"
import Localization from "@localization/Index"

const QueueEmptyBox = ()=>{
    return <CSDiv fillVertical className="d-flex flex-column align-items-center justify-content-center">
        <i className="bi-info-circle text-primary font-5x mb-3"></i>
        <span className="">{Localization.PAGE_QUEUES.QUEUE_EMPTY_BOX}</span>
    </CSDiv>
}

export default QueueEmptyBox