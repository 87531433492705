
import CSDiv from "@components/HTMLElements/CSDiv"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import { Address, Customer, CustomerShippingInfo,} from "@csapi/provisioning/api"
import { useEffect, useState } from "react"
import { PageStates } from "@utils/PageStateUtils"
import Localization from "@localization/Index"
import CSInput from "@components/Form/CSInput"
import { CheckFieldArgs, checkForm, FormErrors } from "@utils/FormUtils"
import CSFlagsSelect from "@components/Form/CSFlagsSelect"
import { CSOption } from "@components/Form/CSReactSelect"
import CSSwitch from "@components/Form/CSSwitch"
import { askConfirmation, showWaiting, toastError, toastSuccess } from "@utils/NotificationsUtils"
import { useCustomerContext } from "./CustomerContext"
import CSSpan from "@components/HTMLElements/CSSpan"
import ShippingAddressesModal from "./ShippingAddressesModal"
import { CustomerBillingInfo } from '../../../../src/csapi/provisioning/api';


type CustomerOverviewTabProps={
    customerId: string
    customer: Customer | undefined
}

const CustomerDetailsTab = (props: CustomerOverviewTabProps) =>{
    const { customer } = props
    const { updateCustomer } = useCustomerContext()
    const [formErrors, setFormErrors] = useState<FormErrors>({})
    const [pageState, setPageState] = useState<PageStates>(PageStates.INIT)
    const [updatedCustomer, setUpdatedCustomer] = useState<Customer | undefined>(customer)
    const [billingIsSameShipping, setBillingIsSameShipping] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [newShippingAddress, setNewShippingAddress] = useState<CustomerShippingInfo>({
        address: {},
        is_default: false
    })
    const [mode, setMode] = useState<"LIST" | "ADD">("ADD")

    useEffect(() => {
        setUpdatedCustomer(customer)
        checkBillingIsSameShipping()
    }, [customer])

    const checkBillingIsSameShipping = () => {

        let compareShipping = customer?.shipping_infos?.find(s => s.is_default === true)
        const isSame = compareAddresses(customer?.billing_info?.address, compareShipping ? compareShipping.address : customer?.shipping_info?.address)
        setBillingIsSameShipping(isSame)
    }
    
    const compareAddresses = (address1?: Address, address2?: Address): boolean => {
        return JSON.stringify(address1) === JSON.stringify(address2)
    }
    
    
    function setDeepProperty(obj: any, path: Array<string | number>, value: any): any {
        path.reduce((acc, part, index) => {
            if (index === path.length - 1) {
                acc[part] = value
            } else {
                if (!acc[part]) acc[part] = {}
                return acc[part]
            }
            return acc
        }, obj)
    }
    
    const setNestedCustomerProperty = (path: Array<string | number>, value: any) => {
        setUpdatedCustomer(prev => {
            if (!prev) return prev 
            const newObj = { ...prev }
            setDeepProperty(newObj, path, value)
            return newObj
        })
    }

    const updateCust = async () => {

        const customerToSave = {...updatedCustomer}

        const fieldsCheckArray: CheckFieldArgs[] = [
            {id: "customer_code", value: customerToSave?.customer_code, required:true },
            {id: "first_name", value: customerToSave?.first_name,  required:true },
            {id: "last_name", value: customerToSave?.last_name, required:true },
            {id: "email", value: customerToSave?.contacts?.email, required:true},
        ]

        const response = checkForm(fieldsCheckArray)

        if(Object.keys(response).length>0) {
            setFormErrors(response)
            console.log("ERROR", response)
            return setPageState(PageStates.FORM_ERROR)
        }


        const confirmResponse = await askConfirmation({
            title: <p>{Localization.PAGE_CUSTOMERS.TABS.DETAILS.UPDATE_MESSAGE}</p>
        })
        if (!confirmResponse.isConfirmed) return false

        showWaiting(Localization.ATTENDERE)
            
            if(!customer){
                toastError(Localization.PAGE_CUSTOMERS.OPERATION_ERROR) 
                return
            }

            setPageState(PageStates.SUBMITTING)

            // Logica per eliminare shipping_info se shipping_infos è definito e contiene almeno un elemento
            if (customerToSave.shipping_infos && customerToSave.shipping_infos.length > 0) {
                delete customerToSave.shipping_info
            }
        
            
            if (billingIsSameShipping && customerToSave?.shipping_infos?.[0]?.address) {
                if (!customerToSave.shipping_infos[0].address) {
                    customerToSave.shipping_infos[0].address = {};
                    customerToSave.shipping_infos[0].is_default= true;
                }
                customerToSave.shipping_infos[0].address = {...customerToSave?.billing_info?.address};
            }

            updateCustomer(customerToSave)

    }

    const handleShowModal = (mode:"LIST"|"ADD") :void => {setShowModal(true); setMode(mode)} 
    const handleCloseModal = () => setShowModal(false)

    const handleAddNewAddress = () => {
        if (updatedCustomer) {
            // Inizializza updatedShippingInfos
            let updatedShippingInfos: CustomerShippingInfo[] = [];
    
            // Controlla se shipping_infos esiste ed ha elementi
            if (updatedCustomer.shipping_infos && updatedCustomer.shipping_infos.length > 0) {
                // Clona shipping_infos esistente
                updatedShippingInfos = [...updatedCustomer.shipping_infos];
            } else {
                // Se shipping_infos non esiste, crealo
                // Aggiungi shipping_info come primo elemento se esiste
                if (updatedCustomer.shipping_info) {
                    updatedShippingInfos.push(updatedCustomer.shipping_info);
                }
            }
    
            // Gestisci l'indirizzo predefinito
            if (newShippingAddress.is_default) {
                // Imposta is_default a false per tutti gli indirizzi esistenti
                updatedShippingInfos.forEach(info => {
                    info.is_default = false;
                });
                // Imposta is_default a false anche per shipping_info se esiste
                if (updatedCustomer.shipping_info) {
                    updatedCustomer.shipping_info.is_default = false;
                }
            }
    
            // Aggiungi il nuovo indirizzo all'array updatedShippingInfos
            updatedShippingInfos.push(newShippingAddress);
    
            // Aggiorna l'oggetto customer
            setUpdatedCustomer({
                ...updatedCustomer,
                shipping_info: undefined, // Rimuovi shipping_info
                shipping_infos: updatedShippingInfos,
            });
    
            // Resetta il form per il nuovo indirizzo
            setNewShippingAddress({ address: {}, contacts: {}, is_default: false });
    
            toastSuccess("AGGIUNTO");
            handleCloseModal();
        }
    }
    
    const shippingAddress = billingIsSameShipping ? updatedCustomer?.billing_info :  (updatedCustomer?.shipping_infos ? updatedCustomer?.shipping_infos?.find(shipment  => shipment.is_default===true) : updatedCustomer?.shipping_info)

    return <>
        <div className="row mt-5">                
            {customer && updatedCustomer &&
            <CSDiv id="CustomerDetailsContainer" className="overflow-auto"  offsetVertical={100}>
                <SectionGroup title={Localization.PAGE_CUSTOMERS.TABLE.ANAGRAFICA_CLIENTE}>
                    <div className="row">
                    <div className="col-sm-12 col-md-12 mt-field">
                        <CSInput 
                            type="text"
                            controlId={`username`}
                            label={Localization.PAGE_CUSTOMERS.TABLE.USERNAME}
                            placeholder=""
                            value={updatedCustomer.security?.username}
                            onChange={(e) => setNestedCustomerProperty(["security","username"],e.target.value) }
                            width="100%"
                        />
                    </div> 
                    <div className="col-sm-12 col-md-12 mt-field">
                        <CSInput 
                            type="text"
                            controlId={`customer_code`}
                            label={Localization.PAGE_CUSTOMERS.TABLE.CODE}
                            placeholder=""
                            value={updatedCustomer.customer_code}
                            onChange={(e) => setNestedCustomerProperty(["customer_code"],e.target.value) }
                            width="100%"
                            mandatory
                            errorText={formErrors.customer_code}
                            hasError={pageState===PageStates.FORM_ERROR  && !!formErrors.customer_code}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 mt-field">
                        <CSInput 
                            type="text"
                            controlId={`first_name`}
                            label={Localization.PAGE_CUSTOMERS.TABLE.NAME}
                            placeholder=""
                            value={updatedCustomer.first_name}
                            onChange={(e) => setNestedCustomerProperty(["first_name"],e.target.value) }
                            width="100%"
                            mandatory
                            errorText={formErrors.first_name}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.first_name}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 mt-field">
                        <CSInput 
                            type="text"
                            controlId={`last_name`}
                            label={Localization.PAGE_CUSTOMERS.TABLE.SURNAME}
                            placeholder=""
                            value={updatedCustomer.last_name}
                            onChange={(e) => setNestedCustomerProperty(["last_name"], e.target.value) }
                            width="100%"
                            mandatory
                            errorText={formErrors.last_name}
                            hasError={pageState===PageStates.FORM_ERROR  && !!formErrors.last_name}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 mt-field">
                        <CSInput 
                            type="text"
                            controlId={`email`}
                            label={Localization.PAGE_CUSTOMERS.TABLE.EMAIL}
                            placeholder=""
                            value={updatedCustomer.contacts?.email}
                            onChange={(e) => setNestedCustomerProperty(["contacts","email"],e.target.value) }
                            width="100%"
                            mandatory
                            errorText={formErrors.email}
                            hasError={pageState===PageStates.FORM_ERROR  && !!formErrors.email}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 mt-field">
                        <CSInput 
                            type="text"
                            controlId={`pec`}
                            label={Localization.PAGE_CUSTOMERS.TABLE.EMAILPEC}
                            placeholder=""
                            value={updatedCustomer.contacts?.pec}
                            onChange={(e) => setNestedCustomerProperty(["contacts","pec"], e.target.value) }
                            width="100%"
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 mt-field">
                        <CSInput 
                            type="text"
                            controlId={`phone`}
                            label={Localization.PAGE_CUSTOMERS.TABLE.PHONE}
                            placeholder=""
                            value={updatedCustomer.contacts?.phone}
                            onChange={(e) => setNestedCustomerProperty(["contacts","phone"],e.target.value) }
                            width="100%"
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 mt-field">
                        <CSInput 
                            type="text"
                            controlId={`mobile`}
                            label={Localization.PAGE_CUSTOMERS.TABLE.CELL}
                            placeholder=""
                            value={updatedCustomer.contacts?.mobile}
                            onChange={(e) => setNestedCustomerProperty(["contacts","mobile"], e.target.value) }
                            width="100%"
                        />
                    </div>
                    </div>
                </SectionGroup>
                <SectionGroup title={Localization.PAGE_CUSTOMERS.TABLE.BILLING_ADDRESS}>
                    <div className="row">
                        <div className="col-sm-8 col-md-8 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`fc`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.CF}
                                placeholder=""
                                value={updatedCustomer.billing_info?.fc}
                                onChange={(e) => setNestedCustomerProperty(["billing_info","fc"],e.target.value) }
                                width="100%"
                            />
                        </div> 
                        <div className="col-sm-8 col-md-8 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`sdi_code`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.SDI}
                                placeholder=""
                                value={updatedCustomer.billing_info?.sdi_code}
                                onChange={(e) => setNestedCustomerProperty(["billing_info","sdi_code"],e.target.value) }
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-8 col-md-8 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`vat_number`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.VAT}
                                placeholder=""
                                value={updatedCustomer.billing_info?.vat_number}
                                onChange={(e) => setNestedCustomerProperty(["billing_info","vat_number"],e.target.value) }
                                width="100%"
                            />
                        </div>
                    </div> 
                    <div className="row">   
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`address_lines`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.ADDRESS_LINE}
                                placeholder=""
                                value={updatedCustomer?.billing_info?.address?.address_lines?.[0]??''}
                                onChange={(e) => setNestedCustomerProperty(['billing_info', 'address', 'address_lines','0'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`city`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.CITY}
                                placeholder=""
                                value={updatedCustomer?.billing_info?.address?.city}
                                onChange={(e) => setNestedCustomerProperty(['billing_info', 'address', 'city'], e.target.value)}
                                width="100%"
                            />
                        </div> 
                    </div>
                    <div className="row">
                        <div className="col-sm-7 col-md-7 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`province`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.PROVINCE}
                                placeholder=""
                                value={updatedCustomer?.billing_info?.address?.province}
                                onChange={(e) => setNestedCustomerProperty(['billing_info', 'address', 'province'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-4 col-md-4 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`province_code`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.PROVINCE_CODE}
                                placeholder=""
                                value={updatedCustomer?.billing_info?.address?.province_code}
                                onChange={(e) => setNestedCustomerProperty(['billing_info', 'address', 'province_code'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-5 col-md-5 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`postal_code`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.POSTAL_CODE}
                                placeholder=""
                                value={updatedCustomer?.billing_info?.address?.postal_code??''}
                                onChange={(e) => setNestedCustomerProperty(['billing_info', 'address', 'postal_code'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-8 col-md-8 mt-field ">
                            <CSFlagsSelect
                                mode="COUNTRY"
                                errorText={Localization.FORMS.CAMPO_OBBLIGATORIO} 
                                value ={{ value:customer?.billing_info?.address?.country_code??'IT',
                                    label:Localization.COUNTRIES_CODE[customer?.billing_info?.address?.country_code?.toUpperCase()  as keyof typeof Localization.COUNTRIES_CODE]??Localization.COUNTRIES_CODE.IT }}
                                onChange={(CSCustomerSelect: CSOption) =>{ setNestedCustomerProperty(['billing_info', 'address', 'country_code'] , CSCustomerSelect.value.toUpperCase())}}
                                label={Localization.PAGE_CUSTOMERS.TABLE.COUNTRY_CODE}
                                controlId="billing_info_country"
                            /> 
                        </div>
                        <div className="mt-field">
                        <CSSwitch
                            className="align-middle"
                            label={Localization.PAGE_CUSTOMERS.TABLE.SWITCH_DESC}
                            controlId={'same_address'} 
                            checked={billingIsSameShipping} 
                            onChange={()=>{setBillingIsSameShipping(!billingIsSameShipping)}}
                        />
                    </div>
                    </div>    
                </SectionGroup>
                {!billingIsSameShipping &&
                    <SectionGroup title={Localization.PAGE_CUSTOMERS.TABLE.SHIPPING_ADDRESS}
                    toolbar={
                        <>
                            <CSSpan role="button" onClick={() => handleShowModal('ADD')} title={Localization.PAGE_CUSTOMERS.ADD_CUSTOMER_ADDRESS}>
                                <i className="bi-plus-circle font-2x text-primary p-1" />
                            </CSSpan>
                            <CSSpan role="button" onClick={() => handleShowModal('LIST')} title={Localization.PAGE_CUSTOMERS.CUSTOMER_ADDRESS_LIST}>
                                <i className="bi-list font-2x text-primary p-1" />
                            </CSSpan>
                        </>
                    }>
                    
                    <div className="row">
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="text"
                                controlId="contact_name"
                                label={Localization.PAGE_CUSTOMERS.CONTACT_NAME}
                                value={ shippingAddress && 'contact_name' in shippingAddress ?  shippingAddress?.contact_name : '' }
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0', 'contact_name'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        {/* Campi per contacts */}
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="email"
                                controlId="contact_email"
                                label={Localization.PAGE_CUSTOMERS.TABLE.EMAIL}
                                value={ shippingAddress && 'contacts' in shippingAddress ?  shippingAddress?.contacts?.email : ''}
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0','contacts','email'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_contact_phone"
                                label={Localization.PAGE_CUSTOMERS.TABLE.PHONE}
                                value={ shippingAddress && 'contacts' in shippingAddress ?  shippingAddress?.contacts?.phone : ''}
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0','contacts','phone'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_contact_mobile"
                                label={Localization.PAGE_CUSTOMERS.TABLE.CELL}
                                value={ shippingAddress && 'contacts' in shippingAddress ?  shippingAddress?.contacts?.mobile : ''}
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0','contacts','mobile'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`address_lines`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.ADDRESS_LINE}
                                placeholder=""
                                value={shippingAddress?.address?.address_lines?.[0]??''}
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0','address', 'address_lines','0'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`city`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.CITY}
                                placeholder=""
                                value={shippingAddress?.address?.city}
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0','address','city'], e.target.value)}
                                width="100%"
                            />
                        </div> 
                    </div>
                    <div className="row">
                        <div className="col-sm-7 col-md-7 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`province`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.PROVINCE}
                                placeholder=""
                                value={shippingAddress?.address?.province}
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0', 'address', 'province'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-4 col-md-4 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`province_code`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.PROVINCE_CODE}
                                placeholder=""
                                value={shippingAddress?.address?.province_code}
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0', 'address', 'province_code'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-5 col-md-5 mt-field">
                            <CSInput 
                                type="text"
                                controlId={`postal_code`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.POSTAL_CODE}
                                placeholder=""
                                value={shippingAddress?.address?.postal_code??''}
                                onChange={(e) => setNestedCustomerProperty(['shipping_infos','0', 'address', 'postal_code'], e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-8 col-md-8 mt-field ">
                            <CSFlagsSelect
                                mode="COUNTRY"
                                onChange={(CSCustomerSelect: CSOption) =>{ setNestedCustomerProperty(['shipping_infos','0', 'address', 'country_code'] , CSCustomerSelect.value.toUpperCase())}}
                                controlId={`customer_conutry_shipping`}
                                label={Localization.PAGE_CUSTOMERS.TABLE.COUNTRY_CODE}
                                value ={{ value:shippingAddress?.address?.country_code??'IT',
                                    label:Localization.COUNTRIES_CODE[shippingAddress?.address?.country_code?.toUpperCase()  as keyof typeof Localization.COUNTRIES_CODE]??Localization.COUNTRIES_CODE.IT }}
                            /> 
                        </div>
                    </div>    
                </SectionGroup> } 
                
                <ShippingAddressesModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    updatedCustomer={updatedCustomer}
                    newShippingAddress={newShippingAddress}
                    setNewShippingAddress={setNewShippingAddress}
                    handleAddNewAddress={handleAddNewAddress}
                    mode={mode}
                />
                <div className="d-flex w-100 mt-5 justify-content-end ">
                    <button onClick={updateCust}  className='m-1 btn mx-2 btn-primary '>{Localization.MODIFICA} </button>
                </div>

            </CSDiv>
            }

        </div>
    </>

}

export default CustomerDetailsTab

