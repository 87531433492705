import { LogSettingFrequencyEnum, LogSettingLevelsEnum } from "@csapi/provisioning/api";
import Localization from "@localization/Index";

enum LogsLevel {
    INFO= "INFO", 
    WARN= "WARN", 
    ERROR= "ERROR", 
    OTHER = "OTHER",
    ALL ="ALL"
}

enum LogsLevelPure {
    INFO= "INFO", 
    WARN= "WARN", 
    ERROR= "ERROR"
}

export interface LogSetting {
    frequency?: LogSettingFrequency
    levels?: Array<LogsLevel>
    component_id?: string
    group_id?: string
    subscribers?: Array<string>;
    search_term?: string
}



const logsLevels = [
    { level: LogsLevel.ALL, icon: "bi-infinity", color: "success", barredColor:"#17C653"},
    { level: LogsLevel.INFO, icon: "bi-info-circle", color: "info", barredColor:"#7239EA"},
    { level: LogsLevel.WARN, icon: "bi-exclamation-circle", color: "warning", barredColor:"#F6C000"},
    { level: LogsLevel.ERROR, icon: "bi-dash-circle", color: "danger", barredColor:"#F8285A"},
    { level: LogsLevel.OTHER, icon: "bi-question-circle", color: "dark", barredColor:"#78829d"}
]


enum LogSettingFrequency  {
    IMMEDIATE= "IMMEDIATE",
    HOURLY= "HOURLY",
    DAILY= "DAILY",
    WEEKLY= "WEEKLY",
    MONTHLY= "MONTHLY"
} 

type LogSettingLevelsEnumType = typeof LogSettingLevelsEnum[keyof typeof LogSettingLevelsEnum];


const convertLevels = (levels: LogSettingLevelsEnumType[]): LogsLevel[] => {
    return levels.map(level => level as LogsLevel)
}


/**
 * Get all the logs level rendering elements
 * @param level the logs level 
 */
const getLogsLevels = (level?:LogsLevel)=>{
    return logsLevels.find(returnLevel=>returnLevel.level===level) ?? logsLevels.find(returnLevel=>returnLevel.level===LogsLevel.OTHER)!
}

export { LogsLevel , getLogsLevels, logsLevels, LogSettingFrequency, LogsLevelPure, convertLevels}