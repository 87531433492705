import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import ShopIcon from "@components/ShopIcon/ShopIcon"
import { Shop } from "@csapi/provisioning"
import Localization from "@localization/Index"
import { PageStates } from "@utils/PageStateUtils"
import { getCSShop} from "@utils/ShopsUtils"
import { useState } from "react"
import { Modal } from "react-bootstrap"


type ShopAssociationsShopStatus = {
    id: string;
    label: string;
    variant: string;
}

type Props = {
    shopAssociatedList?: Shop[] ,
    loading: boolean,
    onChange: (shopId: string) => void,
    readonly: boolean,
    title?: string,
    statuses?: ShopAssociationsShopStatus[],
    selectableShops?: Shop[],
    deleteAssociation:  (shopId: string) => void,
}

const ShopAssociations = ({
    loading,
    shopAssociatedList = [],
    onChange,
    readonly,
    title = Localization.PAGE_BUSINESS_CONDITIONS.ASSOCIAZIONE_SHOP_INFO,
    statuses,
    selectableShops,
    deleteAssociation,
}: Props) => {
    const [isModalAddAssociationShow, setIsModalAddAssociationShow] = useState(false)
    const [editSelectedShops, setEditSelectedShops] = useState<string[]>([])

    const openNewAssociationModal = () => {
        setEditSelectedShops([])
        setIsModalAddAssociationShow(true)
    }

    const deleteAss = (id:string) => {
        deleteAssociation(id)
    }

    const toggleShop = (id: string) => {
        onChange(id)
    }
    // Skeleton effect
    if (loading) return <></>

    const getBusinessConditionShopAssociationStatus = (id: string) => {
        return statuses?.find(bcs => bcs.id === id)
    }

    return (
        <>
            {shopAssociatedList.length === 0 &&
                <SectionSubGroup className="mt-field">
                    <div className="d-flex flex-column justify-content-start align-items-center">
                        <i className="bi-info-circle font-4x text-primary"></i>
                        <p>{title}</p>
                        {!readonly && <button type="button" onClick={openNewAssociationModal} className='m-1 btn btn-primary'>{Localization.INIZIA}</button>}
                    </div>
                </SectionSubGroup>
            }
            {shopAssociatedList.length > 0 &&
                <div className="mt-field w-100 row row-cols-md-3 row-cols-sm-1">
                    {shopAssociatedList.map((shop, index) => {
                        const { status } = shop
                        const csShop = getCSShop(shop.cs_shop_id?.$oid!)
                        const link = (csShop?.IS_CHANNEL) ? `/shops/channel/${csShop.CS_SHOP_ID}` : `/shops/${shop.cs_shop_id?.$oid!}/${shop._id?.$oid}`
                        const shopAssociationStatus = getBusinessConditionShopAssociationStatus(status!)

                        return (
                            <div className="p-0" key={`shop_association_${index}_${shop._id?.$oid!}_`}>
                                <SectionSubGroup className="m-2" style={{ padding: '.25rem 1rem', margin: '.5rem 0' }}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-left align-items-center">
                                            <ShopIcon id={shop.cs_shop_id?.$oid!} size={45} />
                                            <div className="ms-3 d-flex flex-column justify-content-center align-items-left" style={{ minHeight: '125px' }}>
                                                <a href={link} target="_new" className="cs-link">{shop.name}</a>
                                                <div className={`text-${shopAssociationStatus?.variant}`}>{shopAssociationStatus?.label ?? ""}</div>
                                            </div>
                                        </div>
                                        {!readonly && ["PENDING", "CONFIRMED"].includes(status!) && 
                                            <i role="button" className="bi bi-trash3 text-muted fs-4" onClick={() => deleteAss(shop._id?.$oid!)}></i>
                                        }
                                    </div>
                                </SectionSubGroup>
                            </div>
                        )
                    })}
                    {!readonly &&
                        <div className="p-0">
                            <SectionSubGroup style={{ padding: '.25rem 1rem', margin: '.5rem 0' }} className="m-2 bg-primary-light">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex justify-content-left align-items-center">
                                        <div className="ms-3 d-flex flex-column justify-content-center align-items-left text-muted" style={{ minHeight: '125px' }}>
                                            {Localization.PAGE_BUSINESS_CONDITIONS.AGGIUNGI_ASSOCIAZIONE}
                                        </div>
                                    </div>
                                    <i role="button" className="bi bi-plus-circle text-primary fs-4" onClick={openNewAssociationModal}></i>
                                </div>
                            </SectionSubGroup>
                        </div>
                    }
                </div>
            }
            <Modal show={isModalAddAssociationShow} size="lg" onHide={() => setIsModalAddAssociationShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{Localization.PAGE_BUSINESS_CONDITIONS.NUOVA_ASSOCIAZIONE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-field w-100 row row-cols-md-2 row-cols-sm-1">
                        {(selectableShops ?? []).map(shop => {
                            const isShopSelected = editSelectedShops.includes(shop._id?.$oid!)
                            const link = (`/shops/channel/${shop?.cs_shop_id?.$oid}`)
                            return (
                                <div className="p-2" key={`shop_new_association_${shop._id?.$oid!}_`}>
                                    <SectionSubGroup className={`m-2 ${isShopSelected ? "bg-primary-light" : ""}`} style={{ padding: '.25rem 1rem', margin: '.5rem 0' }}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex justify-content-left align-items-center">
                                                <ShopIcon id={shop.cs_shop_id?.$oid!} size={45} />
                                                <div className="ms-3 d-flex flex-column justify-content-center align-items-left" style={{ minHeight: '125px' }}>
                                                    <a href={link} target="_new" className="cs-link">{shop.name}</a>
                                                </div>
                                            </div>
                                            <i role="button" className={`bi ${isShopSelected ? "bi-check-square" : "bi-square"} text-muted fs-4`} onClick={() => toggleShop(shop._id?.$oid!)}></i>
                                        </div>
                                    </SectionSubGroup>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShopAssociations
