import styles from './css/OrderItemsTotal.module.scss'

type Props = {
    label: string,
    value: string
}

const OrderItemsTotal = (props:Props) =>{
    const { label, value } = props 
    return <div className={`row ${styles.container}`}>
        <div className={`col-md-20 text-end ${styles.cell}`}>
            {label}
        </div>
        <div className={`col-md-4 text-end ${styles.cellbolder}`}>
            {value}
        </div>
    </div>
}

export default OrderItemsTotal