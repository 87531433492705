import FormLabel from "react-bootstrap/esm/FormLabel"
import InputGroup from "react-bootstrap/esm/InputGroup"
import CSSelect from "./CSSelect"
import Form from "react-bootstrap/esm/Form"
import { CSOption } from "./CSReactSelect"
import Styles from './css/CSSelectInput.module.scss'
import CSLabel from "./CSLabel"

type CSSelectInputProps = {
  controlId: string,
  label?: string
  value?: CSSelectValue
  selectOptions: CSOption[]
  onChange: (values: CSSelectValue) => void
  mandatory?: boolean
  className?: string
  disabled?: boolean
}

export type CSSelectValue = { 
  selectValue?: string
  formControlValue?: string
}
  
const CSSelectInput = (props: CSSelectInputProps) => {
    
    const { mandatory=false, disabled=false, value, controlId, label, selectOptions, onChange, className } = props
    
    let options = selectOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>)
    )

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newSelectValue = event.target.value;
      console.log(newSelectValue);
      onChange({ selectValue: newSelectValue, formControlValue: value?.formControlValue });
    };
  
    const handleFormControlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFormControlValue = event.target.value;
      console.log(newFormControlValue);
      onChange({ selectValue: value?.selectValue, formControlValue: newFormControlValue });
    };

    return (
      <div className={className}>
        {label && <CSLabel mandatory={mandatory} label={label} />}
        <div>
          <InputGroup>
            <div className="d-inline-flex">
              <CSSelect
                id={`${controlId}_select`}
                style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0, maxWidth: '300px'}}
                value={value?.selectValue}
                disabled={disabled}
                onChange={handleSelectChange}
              >
                {options}
              </CSSelect>
            </div>
            <Form.Control
              id={`${controlId}_input`}
              className={Styles.input}
              value={value?.formControlValue ?? ""}
              onChange={handleFormControlChange}
              disabled={disabled}
            />
          </InputGroup>
        </div>
      </div>
    )
}

export default CSSelectInput

