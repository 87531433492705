import SectionGroup from '@components/SectionGroup/SectionGroup'
import { useState, useEffect } from 'react'
import { PageStates } from "@utils/PageStateUtils"
import { getModuleDetails, updateModuleDetails } from '@api/Modules'
import Localization from '@localization/Index'
import PageHeader from '@components/Page/PageHeader'
import CSSwitch from '@components/Form/CSSwitch'
import { askConfirmation, closeWaiting, showWaiting, toastError } from '@utils/NotificationsUtils'
import { DefaultModuleDetailProps } from './ModuleDetailsUtils'
import ModuleDetailsGeneralInfo from './ModuleDetailsGeneralInfo'
import { EntityId, ModulePOS, Shop } from '@csapi/provisioning'
import { CS_SHOPS_IDS, getUserShops } from '@utils/ShopsUtils'
import ShopAssociations from '@components/ShopsAssociations/ShopsAssociations'
import SectionSubGroup from '@components/SectionGroup/SectionSubGroup'
import CSDiv from '@components/HTMLElements/CSDiv'
import ModuleLoadingError from './ModuleLoadingError'

const ModuleDetailsERP = (props: DefaultModuleDetailProps) => {
  const { id: rawId } = props
  const id = rawId?.toUpperCase()

  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(false)
  const [startDate, setStartDate] = useState<string | undefined>(undefined)
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [module, setModule] = useState<ModulePOS | undefined>(undefined)
  const [pageState, setPageState] = useState<PageStates>(PageStates.INIT)
  const [selectableShopList, setSelectableShopList] = useState<Shop[]>()
  const [associatedShopList, setAssociatedShopList] = useState<Shop[]>()
  const [userShopList, setUserShopList] = useState<Shop[]>()

  const breadcrumbs = [
    { text: Localization.MENU.IL_TUO_CLOUDSTORE, link: '/' },
    { text: Localization.MENU.MODULI, link: '/modules' },
    { text: Localization.PAGE_MODULES.TITLES_DETAILS[id as keyof typeof Localization.PAGE_MODULES.TITLES_DETAILS] }
  ]

  const updateModule = async () => {
    const confirmResponse = await askConfirmation({
      title: <p>{Localization.PAGE_MODULES.UPDATE_CONFIRM.replace("__MODULE__", `${Localization.PAGE_MODULES.TITLES_DETAILS[id as keyof typeof Localization.PAGE_MODULES.TITLES_DETAILS] ?? "N.D."}`)}</p>
    })
    if (!confirmResponse.isConfirmed) return false
    try {
      showWaiting(Localization.ATTENDERE)
      if (id && module) {
        await updateModuleDetails(id, module)
      }
      closeWaiting()
      loadModule()
    } catch (e) {
      closeWaiting()
      toastError(Localization.ERRORE_GENERICO)
    }
  }

  const loadModule = async () => {

    const userShops = await getUserShops()
    setUserShopList(userShops)


    console.log(`Loading module`)
    try {
      if (!id) {
        setPageState(PageStates.ERROR)
        return
      }
      setPageState(PageStates.LOADING)

      const resp = await getModuleDetails(id)
      if (!resp?.content) {
        setPageState(PageStates.ERROR)
        return
      }
      const moduleData = resp.content as ModulePOS
      setModule(moduleData)
      setIsEnabled(moduleData?.enabled)
      setStartDate(moduleData?.last_update_dt?.$date)
      setStatus(moduleData?.status)

      defineSelectableShopList(moduleData, userShops)
      defineAssociatedShopList(moduleData, userShops)

      setPageState(PageStates.IDLE)

    } catch (e: any) {
      console.error(e)
      setPageState(PageStates.ERROR)
    }
  }

  const changeAssociations = (shopId: string) => {
    let eid: EntityId = { '$oid': shopId }

    if (module?.shops && !module.shops.some(entityId => entityId.$oid === shopId)) {
      const updatedModule = { ...module, shops: [...module.shops, eid] }
      setModule(updatedModule)
      console.log('Shop added:', shopId)
      if(userShopList){
        defineSelectableShopList(updatedModule, userShopList)
        defineAssociatedShopList(updatedModule, userShopList)
      }  
    } else {
      console.log('Shop already associated:', shopId)
    }
  }

  const deleteAssociations = (shopId: string) => {
    if (module?.shops) {
      const updatedShops = module.shops.filter(entityId => entityId.$oid !== shopId)
      const updatedModule = { ...module, shops: updatedShops }
      setModule(updatedModule)
      console.log('Shop removed:', shopId)

      if(userShopList){
        defineSelectableShopList(updatedModule, userShopList)
        defineAssociatedShopList( updatedModule, userShopList)
      }
    } else {
      console.log('Shop not associated:', shopId)
    }
  }

  useEffect(() => {
    loadModule()
  }, [])

  const handleToggle = () => {
    setIsEnabled(!isEnabled)
  }

  const defineSelectableShopList = (module: ModulePOS, shops: Shop[]) => {
    if (shops) {
      const shopList = shops.filter(shop => {
        const hasCertainShopId = shop.cs_shop_id?.$oid === CS_SHOPS_IDS.CS_PERSONAL_SHOP
        const isNotAssociated = !module.shops?.some(entityId => entityId.$oid === shop._id?.$oid)
        return hasCertainShopId && isNotAssociated
      })
      setSelectableShopList(shopList)
    }
  }

  const defineAssociatedShopList = ( module: ModulePOS, shops: Shop[]) => {
    if (shops && module?.shops) {
      const associatedShops = shops.filter(shop =>
        module.shops?.some(entityId => entityId.$oid === shop._id?.$oid)
      )
      setAssociatedShopList(associatedShops)
    }
  }

  return (
    <>
      {pageState === PageStates.DATA_LOADING && <SectionGroup><ModuleDetailsGeneralInfo id={id} status={status} startDate={startDate} loading /></SectionGroup>}
      {pageState === PageStates.ERROR && <ModuleLoadingError id={id} />}  
      {pageState === PageStates.IDLE &&
        <div>
          <PageHeader title={Localization.PAGE_MODULES.TITLES.DETAILS_TITLE} breadcrumbs={breadcrumbs} />
          <ModuleDetailsGeneralInfo id={id} status={status} startDate={startDate} />
          <SectionGroup title={Localization.PAGE_MODULES.GENERAL_CONFIG}>
            <SectionSubGroup title={Localization.PAGE_MODULES.ACTIVATE_MODULE}>
              <div className="d-flex w-100 custom-flex-container">
                <CSSwitch
                  className="mt-field pt-3"
                  label={Localization.PAGE_MODULES.ENABLE_DISABLED}
                  controlId={'status-switch'}
                  onChange={handleToggle}
                  checked={isEnabled ?? false}
                />
              </div>
            </SectionSubGroup>
            {isEnabled &&
              <SectionSubGroup title={Localization.PAGE_MODULES.POS.SHOP_ASSOCIATIONS}>
                <div className="row">
                  <ShopAssociations
                    title={""}
                    loading={false}
                    readonly={false}
                    shopAssociatedList={associatedShopList ?? []}
                    selectableShops={selectableShopList}
                    onChange={(shopId: string) => changeAssociations(shopId)}
                    deleteAssociation={(shopId: string) => deleteAssociations(shopId)}
                  />
                </div>
              </SectionSubGroup>
            }
          </SectionGroup>
          <div className="d-flex w-100 mt-5 justify-content-end">
            <button onClick={updateModule} className='m-1 btn mx-2 btn-primary'>{Localization.MODIFICA}</button>
          </div>
        </div>
      }
    </>
  )
}

export default ModuleDetailsERP
