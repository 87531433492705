import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import CSSwitch from '@components/Form/CSSwitch'
import CSInput from '@components/Form/CSInput'
import CSFlagsSelect from '@components/Form/CSFlagsSelect'
import Localization from '@localization/Index'
import { CustomerShippingInfo, Address, Contacts, Customer } from '@csapi/provisioning/api'
import { CSOption } from '@components/Form/CSReactSelect'
import ShippingAddressCard from './ShippingAddressCard'

type ShippingAddressesModalProps = {
    showModal: boolean
    handleCloseModal: () => void
    updatedCustomer: Customer
    newShippingAddress: CustomerShippingInfo
    setNewShippingAddress: React.Dispatch<React.SetStateAction<CustomerShippingInfo>>
    handleAddNewAddress: () => void
    mode : "LIST" | "ADD"
}

const ShippingAddressesModal: React.FC<ShippingAddressesModalProps> = ({ showModal, handleCloseModal, updatedCustomer,newShippingAddress,setNewShippingAddress,handleAddNewAddress, mode}) => {

    const [updateTrigger, setUpdateTrigger] = useState(0)

    const handleAddressUpdated = () => {
        setUpdateTrigger(prev => prev + 1)
    }
    
    const handleInputChange = (field: string, value: string) => {
        setNewShippingAddress(prev => ({
        ...prev,
        [field]: value,
        }))
    }

    const handleAddressChange = (field: string, value: string) => {
        setNewShippingAddress(prev => ({
            ...prev,
            address: {
                ...prev.address,
                [field]: field === 'address_lines' ? [value] : value,
            },
        }))
    }

    const handleContactsChange = (field: string, value: string) => {
        setNewShippingAddress(prev => ({
        ...prev,
        contacts: {
            ...prev.contacts,
            [field]: value,
        },
        }))
    }

    let shippingInf : CustomerShippingInfo[] | [] = []

    if(updatedCustomer?.shipping_infos)
        shippingInf  = updatedCustomer?.shipping_infos 
    else if (updatedCustomer?.shipping_info)
        shippingInf = [updatedCustomer?.shipping_info]
    else shippingInf = []


return (
    <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
            <Modal.Title>{Localization.PAGE_CUSTOMERS.SHIPPING_ADDRESSES}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            {
                mode === 'LIST' && shippingInf?.map((info: CustomerShippingInfo, index: number) => (
                <ShippingAddressCard key={index} address={info} customerToSave={updatedCustomer}  index={index} onAddressUpdated={handleAddressUpdated}/>))
            }
        
            { mode === 'ADD' &&
                <>
                    <div className="row">
                        <strong className="mt-2 col-sm-12 col-md-12">{Localization.PAGE_CUSTOMERS.ADD_NEW_ADDRESS}</strong>
                        <div className="col-sm-12 mt-2 col-md-12 text-end">
                            <CSSwitch
                                label={Localization.PAGE_CUSTOMERS.SHIPPING_ADDRESSES_DEFAULT}
                                controlId={'new-default-address'}
                                checked={newShippingAddress.is_default || false}
                                onChange={() => {
                                    setNewShippingAddress(prev => ({
                                    ...prev,
                                    is_default: !prev.is_default,
                                    }))
                                }}
                                />
                        </div>
                    </div>
                    <div className="row">
                    {/* Campo per contact_name */}
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_contact_name"
                                label={Localization.PAGE_CUSTOMERS.CONTACT_NAME}
                                value={newShippingAddress.contact_name || ''}
                                onChange={e => handleInputChange('contact_name', e.target.value)}
                                width="100%"
                            />
                        </div>
                        {/* Campi per contacts */}
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="email"
                                controlId="new_contact_email"
                                label={Localization.PAGE_CUSTOMERS.TABLE.EMAIL}
                                value={newShippingAddress.contacts?.email || ''}
                                onChange={e => handleContactsChange('email', e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_contact_phone"
                                label={Localization.PAGE_CUSTOMERS.TABLE.PHONE}
                                value={newShippingAddress.contacts?.phone || ''}
                                onChange={e => handleContactsChange('phone', e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_contact_mobile"
                                label={Localization.PAGE_CUSTOMERS.TABLE.CELL}
                                value={newShippingAddress.contacts?.mobile || ''}
                                onChange={e => handleContactsChange('mobile', e.target.value)}
                                width="100%"
                            />
                        </div>
                        {/* Campi per l'indirizzo */}
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_address_line"
                                label={Localization.PAGE_CUSTOMERS.TABLE.ADDRESS_LINE}
                                value={newShippingAddress.address?.address_lines?.[0] || ''}
                                onChange={e => handleAddressChange('address_lines', e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_city"
                                label={Localization.PAGE_CUSTOMERS.TABLE.CITY}
                                value={newShippingAddress.address?.city || ''}
                                onChange={e => handleAddressChange('city', e.target.value)}
                                width="100%"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-7 col-md-7 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_province"
                                label={Localization.PAGE_CUSTOMERS.TABLE.PROVINCE}
                                value={newShippingAddress.address?.province || ''}
                                onChange={e => handleAddressChange('province', e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-4 col-md-4 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_province_code"
                                label={Localization.PAGE_CUSTOMERS.TABLE.PROVINCE_CODE}
                                value={newShippingAddress.address?.province_code || ''}
                                onChange={e => handleAddressChange('province_code', e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-5 col-md-5 mt-field">
                            <CSInput
                                type="text"
                                controlId="new_postal_code"
                                label={Localization.PAGE_CUSTOMERS.TABLE.POSTAL_CODE}
                                value={newShippingAddress.address?.postal_code || ''}
                                onChange={e => handleAddressChange('postal_code', e.target.value)}
                                width="100%"
                            />
                        </div>
                        <div className="col-sm-8 col-md-8 mt-field">
                            <CSFlagsSelect
                            mode="COUNTRY"
                            onChange={(option: CSOption) =>
                                handleAddressChange('country_code', option.value.toUpperCase())
                            }
                            controlId="new_country_code"
                            label={Localization.PAGE_CUSTOMERS.TABLE.COUNTRY_CODE}
                            value={{
                                value: newShippingAddress.address?.country_code || 'IT',
                                label:
                                Localization.COUNTRIES_CODE[
                                    (newShippingAddress.address?.country_code?.toUpperCase() as keyof typeof Localization.COUNTRIES_CODE) ||
                                    'IT'
                                ],
                            }}
                            />
                        </div>
                    </div>
                </>    
            }
        </Modal.Body>
         {mode ==="ADD" &&<Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
                {Localization.ANNULLA}
            </Button>
            <Button variant="primary" onClick={handleAddNewAddress}>
                {Localization.AGGIUNGI}
            </Button>
        </Modal.Footer>}
    </Modal>
)
}

export default ShippingAddressesModal
