import CSSpan from "@components/HTMLElements/CSSpan"
import SectionGroupInlineItem from "@components/SectionGroup/SectionGroupInlineItem"
import Localization from "@localization/Index"
import { copyToClipboard } from "@utils/StringUtils"
import { JSONTree } from 'react-json-tree'

type Props = {
    content?: {
        [key: string]: unknown
    }
}

const AdditionalInfo = (props: Props) => {

    const { content } = props
    if(!content) return <AdditionalInfoEmpty />
    const additionalInfos = Object.keys(content)

    const copyValue = async(value:unknown) =>{
        if(value===undefined || value===null) return false
        if(typeof value === "object") {
            return await copyToClipboard({text: JSON.stringify(value)})
        }
        return await copyToClipboard({text: value as string})
    }

    return <>
        {additionalInfos.map((info:string)=>{
            const value = content[info]
            const canCopy = value != undefined && value != null
            
            const valueRender = getValueRender(value)
            
            return <SectionGroupInlineItem key={`additional_info_${info}`} label={info} valuePosition="BOTTOM" value={<div className="d-flex align-items-center justify-content-left">
            <CSSpan role="button" disabled={!canCopy} tooltip={Localization.COPIA_VALORE} onClick={()=>{copyValue(value)}}>
                <i className="bi-copy font-1-5x text-primary"></i>
            </CSSpan>
            <div style={{display: 'inline-grid'}}>{valueRender}</div>
            </div>} />
        })}
    </>
}

const AdditionalInfoEmpty = ()=>{
    return <>
        <p>{Localization.PAGE_ORDER.NESSUNA_INFORMAZIONE_AGGIUNTIVA}</p>
    </>
}

const getValueRender = (value: unknown)=>{
    const valueType = typeof value
    switch(valueType) {
        case 'object':
            if(value==='null') {
                return <span className="ps-2"><strong>null</strong></span>
            }
            const rootLabel = Array.isArray(value)?"Array": "Object"
            return <JSONTree data={{[rootLabel]: value}} 
                        hideRoot
                        theme={{
                            tree: ({ style }) => ({
                                style: { ...style, backgroundColor: undefined, border: '1px solid black', borderRadius: '10px', padding: '5px' }, // removing default background color from styles
                                className:  'myTreeClassName'
                            })
                        }}
                        shouldExpandNodeInitially={()=>false}
                        labelRenderer={([key]) => <span>{key}</span>}
                        valueRenderer={(raw) => <span>{raw as JSX.Element}</span>}
                        />
        case 'undefined':
            return <span className="ps-2"><strong>undefined</strong></span>
        case 'boolean':
            return <span className="ps-2"><strong>{value?'true':'false'}</strong></span>
        default:
            return <div className="ps-2" style={{width: '100%', minWidth: '0px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}><strong>{value as string}</strong></div>
    }
}

export default AdditionalInfo