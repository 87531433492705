import { getShops } from "@api/Shops"
import { Shop } from "@csapi/provisioning/api"
import { getLocalStorageItem, setLocalStorageItem } from "@utils/LocalStorage"
import MD5 from "md5";

enum CS_SHOPS_IDS {
    _24S = '6548e520d5340cb90a2c5e27',
    AMAZON_EU = '599ed8fa4344a5d7809a13c4',
    AMUZE = '620237cc82be87ce6bcd45d8',
    CETTIRE = '66058b86c6dfb244bf294fed',
    CS_PERSONAL_SHOP = '55b62dd274005d12b400e236',
    DECATHLON = '64357a09a2b9cbb3688bdfe4',
    EBAY = '53ea6ced777327762af14432',
    FARFETCH = '5b19010ce2e95d19cae7d18c',
    ITALIST = '6490554374c666de2b782869',
    MIINTO = '63298739e0e5f68e0fe4e19e',
   /* MIRAKL = '61a0ffc51e4cb2cb355c36b4',*/
    SAVAIT = '63936f79a8dffeed12025013',
    SHOPIFY = '59e9c29882b58204db50abed',
    SHOPIFY_POS = '63d0d6d52a49cee3b0a450a9',
    WINKELSTRAAT = '6579b638dc84dcadd3f83fb1',
    ZALANDO = '633bde07d19a6256bdc8b6eb',
    YOOX="61a0ffc51e4cb2cb355c36b4",
    GALERIES_LAFAYETTE_ID ="65f9500cb8c7c6fc82c8a24c"
}

export type CS_SHOP = {
    CS_SHOP_ID: CS_SHOPS_IDS
    NAME: string
    IS_CHANNEL?: boolean,
    CREATION_ENABLED?: boolean
    SHOP_COMPONENT_NAME?: string
    ICONS: {
        SHORT: string
        EXTENDED: string
    },
}
const CS_SHOPS:CS_SHOP[] = [
    {CS_SHOP_ID: CS_SHOPS_IDS._24S,
    NAME: "24S",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME: "_24S",
    ICONS: {
        SHORT: '24SShort',
        EXTENDED: '24SExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.AMAZON_EU,
    NAME: "Amazon EU",
    IS_CHANNEL: true,
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME: "Amazon",
    ICONS: {
        SHORT: 'AmazonShort',
        EXTENDED: 'AmazonExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.AMUZE,
    NAME: "Amuze Inc",
    ICONS: {
        SHORT: 'AmuzeShort',
        EXTENDED: 'AmuzeExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.CETTIRE,
    NAME: "Cettire",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME: "Cettire",
    ICONS: {
        SHORT: 'CettireShort',
        EXTENDED: 'CettireExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.CS_PERSONAL_SHOP,
    NAME: "CS Personal Shop",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME: "PersonalShop",
    ICONS: {
        SHORT: 'CSPersonalShopShort',
        EXTENDED: 'CSPersonalShopExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.DECATHLON,
    NAME: "Decathlon",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME:"Decathlon",
    ICONS: {
        SHORT: 'DecathlonShort',
        EXTENDED: 'DecathlonExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.EBAY,
    NAME: "Ebay Italia",
    ICONS: {
        SHORT: 'EbayItaliaShort',
        EXTENDED: 'EbayItaliaExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.FARFETCH,
    NAME: "Farfetch",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME: "Farfetch",
    ICONS: {
        SHORT: 'FarfetchShort',
        EXTENDED: 'FarfetchExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.ITALIST,
    NAME: "Italist",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME:"Italist",
    ICONS: {
        SHORT: 'ItalistShort',
        EXTENDED: 'ItalistExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.GALERIES_LAFAYETTE_ID,
        NAME: "Galleries Lafayette",
        CREATION_ENABLED: true,
        SHOP_COMPONENT_NAME:"GalleriesLafayette",
        ICONS: {
            SHORT: 'GLFShort',
            EXTENDED: 'GLFExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.MIINTO,
    NAME: "Miinto",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME: "Miinto",
    ICONS: {
        SHORT: 'MiintoShort',
        EXTENDED: 'MiintoExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.YOOX,
    NAME: "Yoox",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME:"Yoox",
    ICONS: {
        SHORT: 'YooxShort',
        EXTENDED: 'YooxExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.SAVAIT,
    NAME: "Savait",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME: "Savait",
    ICONS: {
        SHORT: 'SavaitShort',
        EXTENDED: 'SavaitExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.SHOPIFY,
    NAME: "Shopify",
    ICONS: {
        SHORT: 'ShopifyShort',
        EXTENDED: 'ShopifyExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.SHOPIFY_POS,
    NAME: "Shopify POS",
    ICONS: {
        SHORT: 'ShopifyPOSShort',
        EXTENDED: 'ShopifyPOSExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.WINKELSTRAAT,
    NAME: "Winkelstraat",
    CREATION_ENABLED: true,
    SHOP_COMPONENT_NAME: "Winkelstraat",
    ICONS: {
        SHORT: 'WinkelstraatShort',
        EXTENDED: 'WinkelstraatExtended'
    }},
    {CS_SHOP_ID: CS_SHOPS_IDS.ZALANDO,
    NAME: "Zalando",
    ICONS: {
        SHORT: 'ZalandoShort',
        EXTENDED: 'ZalandoExtended'
    }},
]

const THEME_SHOPS_STATUSES = [
    {status: "CONFIRMED", bg:"success", icon:"Success"},
    {status: "ERROR", bg:"danger", icon:"Error"},
    {status: "AUTH_ERROR", bg:"danger", icon:"Error"},
    {status: "PENDING", bg:"warning", icon:"Running"},
]

const getCSShop = (csShopId?:string) =>{
    if(!csShopId) return undefined
    
    return CS_SHOPS.find(csShop=>csShop.CS_SHOP_ID===csShopId)
}

/**
 * Get from local storage the list of user shops if exists, else load them from the server
 * If forceRefresh is true, always reload the shops
 * @returns the list of user shops
 */
const getUserShops = async (options?: {forceRefresh:boolean})=>{
    const { forceRefresh=false } = options ?? {}
    let userShops = getLocalStorageItem('user.shops') as Shop[]
    if(forceRefresh || !userShops) {
        // fetch shops from server
        const shopsResponse = await getShops()
        userShops = shopsResponse.content ?? []
        setLocalStorageItem('user.shops', userShops)
    }

    return userShops
}

const getShopsFromIds = (shopIds:string[] = []) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return [] }
    return userShops.filter(shop=>shopIds.includes(shop._id?.$oid!))
}

const getShopFromId = (shopId:string) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return undefined }
    return userShops.find(shop=>shop._id?.$oid === shopId)
}

const getShopFromName = (shopName:string) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return undefined }
    return userShops.find(shop=>shop.name === shopName)
}

const getShopFromIdForcedLoad = async (shopId: string) => {
    const userShops = await getUserShops()
    return userShops.find(shop => shop._id?.$oid === shopId)
}

const getShopsFromCsShopIds =  (cs_shop_id: string) => {
    let userShops = getLocalStorageItem('user.shops') as Shop[]
    return userShops.filter(shop => shop.cs_shop_id?.$oid === cs_shop_id)
}

const generateShopToken =  (cs_shop_id: string) =>{

    const dataToHash =  new Date().toISOString() + cs_shop_id;

    return MD5(dataToHash).toString();

}

const getShopStatus = (status: string)=>{
    let bg = "light"
    let icon = null
    const shopStatus = THEME_SHOPS_STATUSES.find(shopStatus=>shopStatus.status===status)
    if(shopStatus) {
        bg = shopStatus.bg
        icon = shopStatus.icon
    }

    return {bg, icon}
}



export { CS_SHOPS, getCSShop, CS_SHOPS_IDS, getUserShops, getShopsFromIds, getShopStatus, getShopFromId, getShopFromIdForcedLoad, generateShopToken, getShopsFromCsShopIds, getShopFromName }