import styles from'./css/FlagsIcon.module.scss'
import React from 'react'
import { LanguageId, FlagsIconProps } from '@interfaces/Icons'
import Localization from '@localization/Index'

const FlagsIcon: React.FC<FlagsIconProps> = ({ flagId, size = 18, round = false, onClick, title=true, disabled=false}) => {
    if (!flagId) return <></>
  
    const inlineStyles = round ? { borderRadius: '50%', overflow: 'hidden' } : {}
    const upperCaseCountryId = flagId.toUpperCase() as keyof typeof LanguageId 
    const titleLabel = title ? Localization.LANGUAGES[upperCaseCountryId] : ''
    const disabledClass = disabled ? 'disabled-flgas' : ''
  
    return (
      <div
        className={`fi fi-${flagId}${round ? '-r' : ''} ${styles[disabledClass]} `}
        style={{ ...inlineStyles, width: `${size}px`, height: `${size}px` }}
        onClick={onClick}
        title={titleLabel}
      >
        <div className={`fi-${flagId}`} />
      </div>
    )
  }
  
  export default FlagsIcon
