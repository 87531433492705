import React, { useState } from 'react'
import styles from './css/OrderStatusChanger.module.scss'
import { Order } from '@csapi/provisioning/api'
import CSReactSelect, { CSOption } from '@components/Form/CSReactSelect'
import { getOrderStatus, OrderStatus } from '@utils/OrderUtils'
import ListItemElement from '@components/ListItem/ListItemElement'
import Localization from '@localization/Index'
import ShopIcon from '@components/ShopIcon/ShopIcon'
import { getShopFromId } from '@utils/ShopsUtils'
import useWindowSize from '@hooks/UseWindowSize'
import ListItem from '@components/ListItem/ListItem'
import SectionGroup from '@components/SectionGroup/SectionGroup'
import CSSpan from '@components/HTMLElements/CSSpan'
import { askConfirmation, closeWaiting, showWaiting, toastError, toastSuccess } from '@utils/NotificationsUtils'
import { updateOrderStatus } from '@api/Orders'

interface OrderStatusChangerProps {
    setOpen: (open: boolean) => void
    open: boolean
    position: { x: number, y: number }
    order: Order | undefined
    closePreview?: () => void
}

const OrderStatusChanger: React.FC<OrderStatusChangerProps> = ({ order, setOpen, open, position , closePreview}) => {
    const [newStatus, setNewStatus] = useState<CSOption>()
    const [width, _] = useWindowSize()
    const allowedTransitions: Record<OrderStatus, string[]> = {
        PENDING: ['CONFIRMED', 'CANCELLED', 'PARTIALLY_SHIPPED', 'SHIPPED'],
        CONFIRMED: ['CANCELLED', 'PARTIALLY_SHIPPED', 'SHIPPED'],
        PARTIALLY_SHIPPED: ['SHIPPED'],
        CANCELED: [],  
        REFURBISHED: [],  
        SHIPPED: [],  
        OTHER: []  
    }

    const handleClose = () => {
        setNewStatus(undefined)
        setOpen(false)
    }

    const handleChangeStatus= async () => {
        

        handleClose()
        const confirmResponse = await askConfirmation({
            title: <p>{Localization.PAGE_ORDER.CHANGE_STATUS_CONFIRM_MESSAGE.replace("__STATUS__", newStatus?.value??'')}</p>
        })
        if (!confirmResponse.isConfirmed) return false
        try {
            showWaiting(Localization.ATTENDERE)

            const resp = await updateOrderStatus(order?._id?.$oid!, newStatus?.value??'')

            console.log(resp)

            setNewStatus(undefined)
            toastSuccess(Localization.PAGE_ORDER.CHANGE_STATUS_SUCCESS)

            closeWaiting()  
            window.location.reload()
        } catch (e) {
            console.log(e)
            closeWaiting()
        }finally{
            window.location.reload()
        }

    }

    const getStatusOptions = (): CSOption[] => {
        const status: OrderStatus | undefined = order?.order_status as OrderStatus | undefined


        if (!status || !(status in allowedTransitions)) {
            return []
        }

        return allowedTransitions[status].map((option: string): CSOption => ({
            value: option,
            label: option
        }))
    }

    if (!open) return null

    const restrictedStatuses= ['CANCELED', 'REFURBISHED', 'SHIPPED', 'OTHER']
    if (restrictedStatuses.includes(order?.order_status as OrderStatus)) {
        return null
    }

    const status = getOrderStatus(order?.order_status as OrderStatus)
    const shop= getShopFromId(order?.shop_id?.$oid!)
    const shopIconSize = (width>1700)?24:16
    
    return (
        <SectionGroup title = {Localization.PAGE_ORDER.CHANGE_ORDER_SATUS} backgroundIcon={"bi-arrow-repeat"} className={`mt-field  p-3 bg-white border rounded shadow ${styles.container}`} style={{ position: 'absolute', left: `${position.x}px`, top: `${position.y}px`}}
        toolbar={ <CSSpan role="button" onClick={handleClose}><i className="bi-x-lg  text-muted" /></CSSpan>}> 
                
                <ListItem className="d-flex justify-content-between w-auto mb-3" >
                    <ListItemElement colspan={12} label={shop?.name ?? ""} icon={<ShopIcon id={shop?.cs_shop_id?.$oid ?? ""} size={shopIconSize} />} value={order?.shop_order_id ?? ""} hover={false} selected={false} />
                    <ListItemElement colspan={12} position={'end'} label={Localization.PAGE_ORDERS.STATO_ORDINE} icon={`${status.icon} text-${status.color}`} value={<span className={`text-${status.color}`}>{order?.order_status ?? "N.D."}</span>} hover={false} selected={false}/>
                </ListItem>
                <CSReactSelect
                    className={"me-3 mt-3"}
                    label={Localization.PAGE_ORDER.STATO}
                    options={getStatusOptions()}
                    onChange={(selectedOption: CSOption) => setNewStatus(selectedOption)}
                    controlId='newOrderStatus'
                    client
                    value={newStatus}
                />
                <div className="d-flex justify-content-end mt-5">
                    <button className="btn btn-secondary" onClick={handleClose}>
                        {Localization.PAGE_ORDER.CANCEL}
                    </button>
                    <button className="btn btn-primary" disabled={!newStatus} style={{marginLeft:"10px"}} onClick={handleChangeStatus}>
                        {Localization.PAGE_ORDER.CHANGE_STATUS}
                    </button>
                </div>
            
        </SectionGroup>
    )
}

export default OrderStatusChanger
