import { createContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import FormLabel from 'react-bootstrap/esm/FormLabel';
import CheckboxTree, { CheckboxProps, OnCheckNode } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import CSLabel from './CSLabel';
import styles from './css/CSReactTreeSelect.module.scss'
import SearchableList from '@components/SearchableList/SearchableList'
import Localization from '@localization/Index';


type BaseProps = {
    controlId: string,
    text: string,
    label?: string,
    mandatory?: boolean,
    hasError?: boolean,
    errorText?: string,
    checked?: string[],
    data?: TreeNode[],
    data_cy?: string
    showCheckbox?:true,
    onCheck?: (checked:string[])=> void,
    disabled?: boolean
    readonly?: boolean
    checkModel?: "leaf"|"all"
}
interface SearchableProps extends BaseProps {
    searchable: true
    childrenSearch: JSX.Element
    minSearchLength?: number
    onClick: (nodeClicked: OnCheckNode) => void
    onSearchChange: (term: string) => void
    onNodeSelect?: () => void
}

interface NonSearchableProps extends BaseProps {
    searchable?: false
    onClick?: never  
    onSearchChange?: never 
    childrenSearch?: never
    minSearchLength?: never
}

type Props = SearchableProps | NonSearchableProps

export interface TreeNode extends OnCheckNode {
    value: string,
    label: string,
    children?: TreeNode[],
    showCheckbox?:boolean
    data_cy?: string
}

const CSReactTreeSelect = (props:Props) =>{
    const {mandatory=false, controlId, label, text, errorText="", hasError=false, data=[], onCheck, checked:defaultChecked=[], data_cy, showCheckbox= true, onClick, searchable=false, onSearchChange, childrenSearch, minSearchLength=3, disabled=false, readonly=false, checkModel="leaf"} = props
    const [checked, setChecked] = useState<string[]>(defaultChecked)
    const [expanded, setExpanded] = useState<string[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [term, setTerm] = useState<string>('')
    
    const toggleOpen = () => {
        return setOpen(prev=>!prev)
    }
    

    const check = (checked:string[])=>{
        setChecked(checked)
        onCheck?.(checked)
    }

    const click = (node: OnCheckNode) => {
        onClick?.(node)
    }

    interface CheckboxTreeExtendedProps extends CheckboxProps {
        onClick?: (node: OnCheckNode) => void;
    }

    let checkboxTreeProps : CheckboxTreeExtendedProps = {
        nodes: data,
        checked,
        expanded,
        onCheck: (checked: string[]) => check(checked),
        onExpand: (expanded: string[]) => setExpanded(expanded),
        noCascade: !showCheckbox,
        checkModel
    }

    if (searchable) {
        checkboxTreeProps = {
            ...checkboxTreeProps,
            onClick: click
        }
    }

    useEffect(()=>{
        setChecked(defaultChecked)
    },[defaultChecked])

    return (<>
    {open && <div className={styles.overlay} onClick={()=>toggleOpen()}></div>}
    <div style={{position: 'relative', width: '100%'}}>
        {searchable ? 
        <>
            <SearchableList 
                controlId="SearchTreeSelect" 
                search={{
                    label: Localization.CERCA, 
                    disabled: (data.length<1 || open), 
                    onChange: (term:string)=>{
                    if (onSearchChange){
                        setTerm(term)
                        onSearchChange(term)
                    }
                }}}
            > 
                {(term && term.length >= minSearchLength) && childrenSearch }
            </SearchableList>

            {(!term || term.length <1) &&
            <div key={`trewiewButton`} role="button" className={`ms-1 ${styles['treeview-button']}`} onClick={()=>toggleOpen()}>
                <i className={'bi bi-list-nested'} ></i>
            </div>
            
            }
        </>
        :
        <Form.Group controlId={controlId} data-cy={`checkbox-tree-${data_cy}`}>
            {label && <CSLabel mandatory={mandatory} label={label} />}
            <Form.Control type="button" disabled={disabled} readOnly={readonly} value={text} className={`${styles.button}${hasError?` border-danger`:``}`} onClick={()=>{toggleOpen()}}></Form.Control>
            <div className="text-left position-relative">
                <FormLabel className='cs-text text-danger position-absolute end-0' style={{top:'-5px'}} visuallyHidden={!hasError}>
                    <small>{errorText}</small>
                </FormLabel>
            </div>
        </Form.Group>
        }
        {open &&
            <div className={`${styles["tree-container"]} shadow`} >
            <CheckboxTree
            icons={{
                check: <i className="bi-check-square p-2" />,
                uncheck: <i className="bi-square p-2" />,
                halfCheck: <i className="bi-dash-square p-2" />,
                expandClose: <i className="bi-chevron-right p-2" />,
                expandOpen: <i className="bi-chevron-down p-2" />,
                expandAll: <i className="bi-chevron-right p-2" />,
                collapseAll: <i className="bi-chevron-down p-2" />,
                parentOpen: <></>,
                parentClose: <></>,
                leaf: <></>
            }}
            disabled={disabled || readonly}
            {...checkboxTreeProps} 
            />

            </div>
        }
    </div>
    </>
    )
}

export default CSReactTreeSelect