import { useContext, useEffect, useState } from "react"
import { AuthContext } from '@providers/AuthProvider'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/LocalStorage'
import { ConfigurationProps, Configuration} from '@interfaces/Configuration'
import Localization from '@localization/Index'
import useCallbackState from "./UseCallbackState"
import { generateId } from '@utils/StringUtils'



/**
 * this hook manage the configuration state of the logged user.
 * 
 * It is coupled in this project to the configuration provider (ref. client/src/Providers/ConfigurationProvider.tsx )
*/ 
const useConfiguration = ()=> {
    const auth = useContext(AuthContext)
    const _initialConfigurations = getLocalStorageItem("configurations", [] as Configuration[]) as Configuration[]
    const [configurations, setConfigurations] = useCallbackState<Configuration[]>([..._initialConfigurations])

    useEffect(()=>{
        // when availableConfigurations changes, put it into the localstorage
        setLocalStorageItem("configurations", configurations)
        console.log("Configurations saved.")
    }, [JSON.stringify(configurations)])

    /**
     * set a config value into the current active configuration for the current user
     * @param key the key config to be changed
     * @param value the value to be set; if undefined or null, the key will be unset
     */
    const setValue = <Key extends keyof ConfigurationProps>(key:Key, value:ConfigurationProps[Key]) =>{
        // this will set the configurations in the state, to be saved in the useEffect
        setConfigurations((prev:Configuration[])=>{
            // create copy variable to work on
            let currentConfigurations = [...prev]
            const username = auth?.userData?.username
            // (SAFEGUARD) if we are not logged simply keep the state (this is only a safeguard for unauth calls)
            if(!username) return currentConfigurations
            // get the current active configuration for the user
            let activeConfiguration = currentConfigurations.find((_configuration: Configuration)=>_configuration.active===true && _configuration.username === username!)
            if(!activeConfiguration) {
                // if the user has no configs at all, create it and put it in the configurations array
                const hasConfigurations = !!currentConfigurations.find(_configuration=>_configuration.username===username!)
                if(!hasConfigurations) {
                    const newConfiguration = {
                        id: generateId(),
                        name: Localization.CONFIGURAZIONE.NUOVA_CONFIGURAZIONE,
                        username,
                        active: true
                    }
                    currentConfigurations.push(newConfiguration as Configuration)
                }
                
                // (SAFEGUARD) if all the user configurations are inactive, simply set the first one as active
                if(!currentConfigurations.find(_configuration=>_configuration.username===username! && _configuration.active===true)) {
                    const firstConfigurationAvailable = currentConfigurations.find(_configuration=>_configuration.username===username!)
                    firstConfigurationAvailable!.active = true
                }

                // refetch the current configuration from array
                activeConfiguration = currentConfigurations.find((_configuration: Configuration)=>_configuration.active===true && _configuration.username === username!)
            }
            if(value===null || value===undefined) {
                delete activeConfiguration![key]
            } else {
                // there is a problem with the extends interface so we suppress the ts error here
                //@ts-ignore
                activeConfiguration![key] = value
            }

            return currentConfigurations

        })
    }

    /**
     * get the config value for the given key in the current active configuration for the current user, or the default value if the value is null and defaultValue is set
     * @param key the key of the config to be fetched (excluded username and active properties which are special property)
     * @param defaultValue the default value if the value of this key property is null
     * @returns 
     */
    const getValue = <Key extends keyof ConfigurationProps>(key:Key, defaultValue?: ConfigurationProps[Key])=>{
        const username = auth?.userData?.username
        // (SAFEGUARD) return the default value if no user is set
        if(!username) return defaultValue

        const activeConfiguration = configurations.find((_configuration: Configuration)=>_configuration.active===true && _configuration.username===username)
        // (SAFEGUARD) return the default value if no active configuration is set
        if(!activeConfiguration) return defaultValue

        return activeConfiguration[key] ?? defaultValue
    }

    /**
     * remove from the provider (and the storage thanks to useEffect) the configuration with the given id
     * @param id 
     */
    const removeConfiguration = (id:string) => {
        setConfigurations((prev:Configuration[]) =>{
            let currentConfigurations = [...prev]
            const username = auth?.userData?.username
            // (SAFEGUARD) return the current configurations if user is not set
            if(!username) return currentConfigurations

            const newConfigurations = currentConfigurations.filter(_configuration=>_configuration.id!==id)
            
            // if in the remaining configurations there are no one active, set as active the first one in the array
            const hasActiveConfiguration = !!newConfigurations.find(_configuration=>_configuration.username===username && _configuration.active===true)
            if(newConfigurations.length>0 && !hasActiveConfiguration) {
                newConfigurations[0].active = true // set a new active configuration if exists
            }
            return newConfigurations
        })
    }

    /**
     * set as active the configuration with the given id
     * @param id the id of the configuration to be set as active
     */
    const setActiveConfiguration = (id:string)=>{
        setConfigurations((prev:Configuration[]) =>{
            let currentConfigurations = [...prev]
            const username = auth?.userData?.username
            // (SAFEGUARD) return the current configurations if user is not set
            if(!username) return currentConfigurations

            const toBeActiveConfiguration = currentConfigurations.find(configuration=>configuration.id===id)
            
            // (SAFEGUARD) if the configuration with given id does not exists, return current configurations
            if(!toBeActiveConfiguration) return currentConfigurations

            // first, set all the configurations for the current user to inactive
            currentConfigurations.forEach((userConfiguration, index:number) => {
                if(username === userConfiguration.username) {
                    return currentConfigurations[index].active = false
                }
            })

            // now set as active the one with the given id
            toBeActiveConfiguration.active = true
            return currentConfigurations
        })
    }

    /** 
     * create new configuration and add to the user configurations 
     * * keep in mind that this function is async, because can return the whole new configuration created
     * */
    const newConfiguration = ():Promise<Configuration | undefined>=>{
        return new Promise<Configuration | undefined>((resolve)=>{
            let newConfiguration:Configuration|undefined = undefined
            setConfigurations((prev:Configuration[]) =>{
                let currentConfigurations = [...prev]
                const username = auth?.userData?.username
                // (SAFEGUARD) return the current configurations if user is not set
                if(!username) return currentConfigurations
    
                // get the current number of the user configurations
                const currentUserConfigurationsCount = currentConfigurations.filter(_configuration=>_configuration.username === username!).length
    
                // create the configurations with specific name (with n+1 in name if there are other configurations already) as non active
                // to activate this configuration, use the setActiveConfiguration with the resulting id
                newConfiguration = {
                    id: generateId(),
                    name: `${Localization.CONFIGURAZIONE.NUOVA_CONFIGURAZIONE}${currentUserConfigurationsCount>0?` ${currentUserConfigurationsCount+1}`:``}`,
                    username,
                    active: (currentUserConfigurationsCount===0)
                }
                currentConfigurations.push(newConfiguration as Configuration)
                return currentConfigurations
            }, (_)=>{
                return resolve(newConfiguration)
            })
        })
    }

    return [configurations, setValue, getValue, removeConfiguration, setActiveConfiguration, newConfiguration]
}

export default useConfiguration