import { getCustomerOrders } from "@api/Orders"
import CSDiv from "@components/HTMLElements/CSDiv"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import { ListMetadata, Order } from "@csapi/provisioning/api"
import Localization from "@localization/Index"
import { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import OrdersListItem from "@components/OrdersList/OrdersListItem"
import OrdersList from "@components/OrdersList/OrdersList"
import { useNavigate, useParams } from "react-router-dom"
import { copyToClipboard } from "@utils/StringUtils"
import ContextMenu from "@components/ContextMenu/ContextMenu"
import { Container, ToastContainer } from "react-bootstrap"
import { ContextMenuItem } from "@interfaces/ContextMenu"
import OrderPreview from "@components/OrdersList/OrderPreview"
import { PageStates } from "@utils/PageStateUtils"
import { ErrorBoxSearch, NoFilterBoxSearch } from "@components/InfoBoxSearch/InfoBoxSearch"
import { addMonths} from "date-fns"
import ListItemElement from "@components/ListItem/ListItemElement"
import AdvancedFilters from "@components/AdvancedFilters/AdvancedFilters"
import { areEmptyFilters, CurrentFilters, FilterType, FilterTypeDefinition } from "@utils/AdvancedFiltersUtils"
import { FiltersContext, FiltersStatus,  } from "@providers/FiltersProvider"
import FlagsIcon from "@components/FlagsIcon/FlagsIcon"
import { CountryToCurrencyMap } from "@interfaces/Icons"

type DateFilterValue = [Date | null, Date | null]

const CustomerOverviewTab = () => {
    const [pageState, setPageState] = useState("FILTERS_LOADING")
    const filtersContext = useContext(FiltersContext)
    const { currentFilters, filtersStatus, setCurrentFilters, setApplied, setFiltersStatus } = filtersContext
    const [customerOrders, setCustomerOrders] = useState<Order[]>([])
    const [customerOrdersMetadata, setCustomerOrdersMetadata] = useState<ListMetadata|undefined>()
    const [selectedOrderId, setSelectedOrderId] = useState<string|undefined>()
    const [openPreview, setOpenPreview] = useState(false)
    const [contextMenuOpen, setContextMenuOpen] = useState(false)
    const [mouseCoordinates, setMouseCoordinates] = useState({x:0,y:0})
    const [areFiltersEmpty, setAreFiltersEmpty] = useState(false)
    const { id } = useParams<{ id: string }>()


    const navigate = useNavigate()

    const selectedCustomerOrder = customerOrders.find(cOrder=>cOrder._id?.$oid === selectedOrderId)

    const availableFilters:FilterTypeDefinition[] = [
        
        {
            id: "date",
            type: FilterType.DATEPICKER,
            label: Localization.PAGE_CUSTOMERS.ORDER_DATE,
            defaultValue: [addMonths(new Date(), -1), new Date()],
        }
    
    ]

    const contextMenu:Array<ContextMenuItem> = [
        {
            text: Localization.PAGE_ORDERS.APRI_ANTEPRIMA,
            action: ()=>{
                setOpenPreview(true)
            },
            default: true
        },
        {
            text: Localization.PAGE_ORDERS.VAI_A_DETTAGLIO,
            action: ()=>{
                navigate(`/orders/${selectedOrderId}`)
            }
        },
        {
            text: Localization.PAGE_ORDERS.COPIA_ID,
            action: async ()=>{
                
                await copyToClipboard({text: selectedCustomerOrder?._id?.$oid ?? ""})
            }
        },
        {
            text: Localization.PAGE_ORDERS.COPIA_CODICE_ORDINE,
            action: async ()=>{
                await copyToClipboard({text: selectedCustomerOrder?.shop_order_id ?? ""})
            }
        },
    ]


    useEffect(() => {
        const initializeFilters = () => {
            const defaultFilters: CurrentFilters = [
                {
                    id: "date",
                    value: [addMonths(new Date(), -1), new Date()] as [Date, Date]
                }
            ];
    
            setCurrentFilters(defaultFilters);
            setApplied(true);
            setFiltersStatus(FiltersStatus.READY);
        }
    
        if (filtersStatus !== FiltersStatus.READY) {
            initializeFilters();
        } else {
            applyFilters();
        }
    }, [filtersStatus, setCurrentFilters, setApplied, setFiltersStatus]);
    

    const applyFilters = async () => {
        if (areEmptyFilters(currentFilters, availableFilters) ) {
            setCustomerOrdersMetadata(undefined)
            setPageState(PageStates.IDLE)
            setAreFiltersEmpty(true)
            setCustomerOrders([])
            return
        }
        
        setAreFiltersEmpty(false)
    
        const dateFilter = currentFilters?.find(filter => filter.id === "date")
        const dateFilterValue = (dateFilter?.value as DateFilterValue)

        const dateFrom = dateFilterValue?.[0]?.toISOString() ?? ''
        const dateTo = dateFilterValue?.[1]?.toISOString() ?? ''

        try {
            const _customerOrders = await getCustomerOrders(currentFilters!, id!, 0, 20)
            setCustomerOrders(_customerOrders.content ?? [])
            setCustomerOrdersMetadata(_customerOrders._metadata)
            setPageState(PageStates.IDLE)
        } catch (err) {
            console.error("Failed to load customer orders:", err)
            setPageState(PageStates.DATA_LOADING_ERROR)
        }
    }
    

    /**
     * Open the context menu
     * @param e the event that triggered the opening
     * @param rowIndex the row index of the visible data with which to select the current row for the actions in the context menu
     */
    const openContextMenu = (e:React.MouseEvent, id: string) =>{
        e.preventDefault()
        setMouseCoordinates({x:e.pageX, y:e.pageY})
        setSelectedOrderId(id)
        setContextMenuOpen(true)
    }

    const { total_items=0, page_index=0, total_pages=0} = customerOrdersMetadata ?? {}


    type CurrencyTotals = {
        [key: string]: number
    }

    const totalsByCurrency = customerOrders.reduce<CurrencyTotals>((acc, item) => {
        const amount: number | undefined = item.tot_amount?.amount
        const currency: string | undefined = item.tot_amount?.currency
        if (currency != null && amount != null) {
            acc[currency] = (acc[currency] || 0) + amount
        }
        return acc
    }, {})      


    const ordersAmounts = () => {
        return Object.entries(totalsByCurrency).map(([currency, total]) => {
            const flagId =  Object.keys(CountryToCurrencyMap)[Object.values(CountryToCurrencyMap).indexOf(currency)].toLowerCase()
            return (
                <ListItemElement
                    label={Localization.CURRENCY[currency as keyof typeof Localization.CURRENCY] ?? ''} 
                    icon={<FlagsIcon flagId={flagId ?? ''} size={24} round={true} />}
                    value={total.toFixed(2)} 
                    colspan={24} 
                    key={flagId}
                /> 
            )
        })
    }

    return <>
            <div className="row mt-5">
                <div className={`col-md-24` }>    
                    <SectionSubGroup style={{padding:'.25rem 1rem', margin: '.5rem 0'}} >
                        <div className="d-flex w-100  custom-flex-container">
                            <ListItemElement colspan={12}  label={Localization.PAGE_CUSTOMERS.ORDERS_MADE} icon={"bi-cart3"} value={total_items.toString()} /> 
                            <div>{ordersAmounts()} </div>
                        </div>
                    </SectionSubGroup>
                </div>
                <Container fluid>
                    <AdvancedFilters 
                        name="customerOrders"
                        availableFilters={availableFilters}
                        onApply={()=>{
                            setPageState(PageStates.DATA_LOADING)
                            return applyFilters()
                        }}
                    ></AdvancedFilters>
                </Container>
                <Container fluid className="mt-4">
                    {pageState === PageStates.DATA_LOADING_ERROR && <ErrorBoxSearch />}
                    {pageState === PageStates.DATA_LOADING && <SectionGroup><OrdersList loading /></SectionGroup>} 
                    {pageState === PageStates.IDLE && areFiltersEmpty && <NoFilterBoxSearch />}
                    {pageState === PageStates.IDLE && !areFiltersEmpty &&                         
                        <SectionGroup title={Localization.RISULTATI.replace("__COUNT__", total_items.toString())}>
                            <CSDiv id="CustomerOrdersContainer" className="overflow-auto" fillVertical offsetVertical={100}>
                                <InfiniteScroll
                                    scrollableTarget="CustomerOrdersContainer"
                                    dataLength={customerOrders.length}
                                    hasMore={page_index+1<total_pages}
                                    next={async ()=>{
                                        try {
                                            const _customerOrders = await getCustomerOrders(currentFilters!,id!, page_index+1,undefined)
                                            setCustomerOrdersMetadata ( _customerOrders._metadata )
                                            setCustomerOrders(prev=>{
                                                const addedOrders = _customerOrders.content ?? []
                                                return [...prev, ...addedOrders]
                                            })
                                        } catch(err) {
                                            setCustomerOrdersMetadata(undefined)
                                            setCustomerOrders([])
                                            setPageState(PageStates.DATA_LOADING_ERROR)
                                        }
                                    }}
                                    loader={<OrdersListItem loading />}
                                >
                                    <OrdersList orders={customerOrders} onSelectItem={(id:string)=>{
                                        setSelectedOrderId(id)
                                        setOpenPreview(true)
                                    }} onContextMenu={(e:React.MouseEvent, id:string)=>{
                                        openContextMenu(e, id)
                                    }}/> 
                                </InfiniteScroll>
                            </CSDiv>
                        </SectionGroup>
                    }
                </Container>    
                {selectedCustomerOrder && openPreview && <OrderPreview order={selectedCustomerOrder} onClose={()=>{
                        setSelectedOrderId(undefined)
                        setOpenPreview(false)
                    }} />
                }
                {contextMenuOpen && selectedCustomerOrder && <ContextMenu coordinates={mouseCoordinates} menu={contextMenu} closeFunction={()=>{setContextMenuOpen(false)}}/>}
                <ToastContainer />
            </div>
        </>
}

export default CustomerOverviewTab

