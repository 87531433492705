import SectionGroup from '@components/SectionGroup/SectionGroup'
import { useState, useEffect } from 'react'
import { PageStates } from "@utils/PageStateUtils"
import { getModuleDetails, updateModuleDetails } from '@api/Modules'
import Localization from '@localization/Index'
import PageHeader from '@components/Page/PageHeader'
import CSSwitch from '@components/Form/CSSwitch'
import { askConfirmation, closeWaiting, showWaiting, toastError } from '@utils/NotificationsUtils'
import { DefaultModuleDetailProps, ModuleT } from './ModuleDetailsUtils'
import ModuleDetailsGeneralInfo from './ModuleDetailsGeneralInfo'
import SectionSubGroup from '@components/SectionGroup/SectionSubGroup'
import CSDiv from '@components/HTMLElements/CSDiv'
import ModuleLoadingError from './ModuleLoadingError'


const ModuleDetail = (props :DefaultModuleDetailProps) => {
  const { id: rawId } = props
  const id = rawId?.toUpperCase()
  
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(false)
  const [startDate, setStartDate] = useState<string | undefined>(undefined)
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [module, setModule] = useState<ModuleT | undefined>(undefined)
  const [pageState, setPageState] = useState<PageStates>(PageStates.INIT)
  
  const breadcrumbs = [
    {text: Localization.MENU.IL_TUO_CLOUDSTORE, link: '/'},
    {text: Localization.MENU.MODULI, link: '/modules'},
    {text: Localization.PAGE_MODULES.TITLES_DETAILS[id as keyof typeof Localization.PAGE_MODULES.TITLES_DETAILS]}
  ]
  
  const updateModule = async () => {
    const confirmResponse = await askConfirmation({
      title: <p>{Localization.PAGE_MODULES.UPDATE_CONFIRM.replace("__MODULE__",`${Localization.PAGE_MODULES.TITLES_DETAILS[id as keyof typeof Localization.PAGE_MODULES.TITLES_DETAILS] ?? "N.D."}`)}</p>
    })
    if (!confirmResponse.isConfirmed) return false
    try {
      showWaiting(Localization.ATTENDERE)
      if (id && module) {
        await updateModuleDetails(id, module)
      }
      closeWaiting()
      loadModule()
    } catch (e) {
      closeWaiting()
      toastError(Localization.ERRORE_GENERICO)
    }
  }

  const loadModule = async () => {
    console.log(`Loading module`)
    try {
      if (!id) {
        setPageState(PageStates.ERROR)
        return
      }
      setPageState(PageStates.LOADING)

      const resp = await getModuleDetails(id)
      if (!resp?.content) {
        setPageState(PageStates.ERROR)
        return
      }
      const moduleData = resp.content as ModuleT
      setModule(moduleData)
      setIsEnabled(moduleData?.enabled)
      setStartDate(moduleData?.last_update_dt?.$date)
      setStatus(moduleData?.status)
      setPageState(PageStates.IDLE)
    } catch (e: any) {
      console.error(e)
      setPageState(PageStates.ERROR)
    }
  }

  useEffect(() => {
    loadModule()
  }, [])

  const handleToggle = () => {
    setIsEnabled(!isEnabled)
  }



  return (
    <>
      {pageState === PageStates.DATA_LOADING && <SectionGroup><ModuleDetailsGeneralInfo id={id} status={status} startDate={startDate}  loading /></SectionGroup>} 
      {pageState === PageStates.ERROR && <ModuleLoadingError id={id} />}  
      {pageState === PageStates.IDLE &&
        <div>
        <PageHeader title={Localization.PAGE_MODULES.TITLES.DETAILS_TITLE} breadcrumbs={breadcrumbs} />
        <ModuleDetailsGeneralInfo id={id} status={status} startDate={startDate} />
        <SectionGroup title={Localization.PAGE_MODULES.GENERAL_CONFIG}>  
          <SectionSubGroup title={Localization.PAGE_MODULES.ACTIVATE_MODULE}>  
            <div className="d-flex w-100 custom-flex-container">
              <CSSwitch
                className="mt-field pt-3"
                label={Localization.PAGE_MODULES.ENABLE_DISABLED}
                controlId={'status-switch'} 
                onChange={handleToggle}
                checked={isEnabled ?? false}
              />
            </div>
          </SectionSubGroup>    
        </SectionGroup>
        <div className="d-flex w-100 mt-5 justify-content-end">
          <button onClick={updateModule} className='m-1 btn mx-2 btn-primary'>{Localization.MODIFICA}</button>
        </div> 
      </div>
      }         
    </>
  )
}

export default ModuleDetail
