import { AuthContext } from '@providers/AuthProvider'
import CloudStoreLogo from '@svgs/logo.svg'
import React from 'react'
import Badge from 'react-bootstrap/esm/Badge'
import Navbar from 'react-bootstrap/esm/Navbar'
import UserMenu from '@components/UserMenu/UserMenu'
import Localization from '@localization/Index'
import HeaderLanguage from '@components/Header/HeaderLanguage'
import CSInput from '@components/Form/CSInput'
import HeaderLogs from './HeaderLogs'

type HeaderProps = {

}

const Header = (props:HeaderProps)=> {

    const auth = React.useContext(AuthContext)

    return (
        <div className="fixed-top cs-sidebar">
            <div className='d-flex align-items-center'>
                <div className="d-none d-md-block p-2" style={{width: '280px', minWidth: '280px'}}>
                    <Navbar.Brand href="/"><img src={CloudStoreLogo} width="200" alt="logo" /></Navbar.Brand>
                </div>
                <div className="w-100 ps-1">
                    <Navbar bg="light" expand="lg" style={{zIndex:9999}}>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex justify-content-start align-items-center" style={{marginLeft: '-5px'}}>
                                <div className='d-block d-md-none'></div>
                                <CSInput type="text" icon="bi-search" controlId='header_search' width={'300px'} placeholder={Localization.CERCA} />
                                {auth.environment && <Badge className="d-none d-md-block ms-2" bg="primary">{auth.environment}</Badge>}
                            </div>
                            <div className="d-flex justifiy-content-end align-items-center">
                                <div className="d-flex align-items-center"><HeaderLogs/></div>
                                <div className="ms-4"><HeaderLanguage /></div>
                                <div className="ms-4"><UserMenu /></div>
                            </div>
                        </div>
                    </Navbar>
                </div>
            </div>
        </div>
    )
}

export default Header