
//TODO: copy from getQueryString cs.connectors.utils
/**
 * Returns the query string for the given params
 * @param args the query params
 * @returns the string in form of '?p1=x&p2=y&...'
 */
const getQueryString = <T>(args:T) => {
    let key: keyof T
    let queryString = `?`
    for (key in args) {
        const value = args[key]
        queryString += `${key.toString()}=${value}&`
    }
    return queryString
}

const parseSort = (sort?: [number|undefined, "ASC"|"DESC"], columns?: string[]): string|undefined =>{
    if(!sort || !columns) return undefined 
    const [index, type] = sort
    if(typeof index !== 'number') return undefined
    return `${columns[index]}[${type}]`

}

export { getQueryString, parseSort }