import { CurrentFilters } from "@utils/AdvancedFiltersUtils";
import React, { createContext, useState } from "react";

export enum FiltersStatus {
    LOADING = "LOADING",
    READY = "READY",
    ERROR = "ERROR"
}

type FiltersContextType = {
    // loading: boolean
    // setLoading: React.Dispatch<React.SetStateAction<boolean>>
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    applied: boolean
    setApplied: React.Dispatch<React.SetStateAction<boolean>>
    currentFilters?: CurrentFilters
    setCurrentFilters: React.Dispatch<React.SetStateAction<CurrentFilters | undefined>>
    filtersStatus: FiltersStatus,
    setFiltersStatus: React.Dispatch<React.SetStateAction<FiltersStatus>>,
    selectedPresetId?: string,
    setSelectedPresetId: React.Dispatch<React.SetStateAction<string|undefined>>,
}

const FiltersContext = createContext<FiltersContextType>(null!)

const FiltersProvider = ({ children }: { children: React.ReactNode }) => {
    // const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [applied, setApplied] = useState(false)
    const [currentFilters, setCurrentFilters] = useState<CurrentFilters | undefined>(undefined)
    const [filtersStatus, setFiltersStatus] = useState<FiltersStatus>(FiltersStatus.LOADING)
    const [selectedPresetId, setSelectedPresetId] = useState<string|undefined>(undefined)

    const value = {
        open,
        setOpen,
        applied,
        setApplied,
        currentFilters,
        setCurrentFilters,
        filtersStatus,
        setFiltersStatus,
        selectedPresetId,
        setSelectedPresetId
    }
    return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}

export {FiltersProvider, FiltersContext}