import React, { useState } from 'react'
import { Customer, CustomerShippingInfo} from '@csapi/provisioning/api'
import CSInput from '@components/Form/CSInput'
import CSFlagsSelect from '@components/Form/CSFlagsSelect'
import Localization from '@localization/Index'
import { CSOption } from '@components/Form/CSReactSelect'
import SectionSubGroup from '@components/SectionGroup/SectionSubGroup'
import CSSpan from '@components/HTMLElements/CSSpan'
import { Button } from 'react-bootstrap'
import CSSwitch from '@components/Form/CSSwitch'
import { askConfirmation, closeWaiting, showWaiting, toastError, toastSuccess } from '@utils/NotificationsUtils'
import { updateCustomer } from '@api/Customers'

type ShippingAddressCardProps = {
    address: CustomerShippingInfo
    customerToSave: Customer
    index: number
    onAddressUpdated: () => void
}

const ShippingAddressCard: React.FC<ShippingAddressCardProps> = ({ address, customerToSave, index, onAddressUpdated }) => {
    const [editMode, setEditMode] = useState(false)
    const [updatedAddress, setUpdatedAddress] = useState<CustomerShippingInfo>(address)


    const handleInputChange = (field: string, value: string) => {
    setUpdatedAddress((prev) => ({
        ...prev,
        [field]: value,
        }))
    }

    const handleAddressChange = (field: string, value: string) => {
    setUpdatedAddress((prev) => ({
        ...prev,
        address: {
            ...prev.address,
            [field]: value,
        },
        }))
    }

    const handleAddressLineChange = (value: string) => {
        setUpdatedAddress(prev => ({
            ...prev,
            address: {
                ...prev.address,
                address_lines: [value] // Aggiorna solo il primo elemento
            }
        }))
    }
    

    const handleContactsChange = (field: string, value: string) => {
    setUpdatedAddress((prev) => ({
        ...prev,
        contacts: {
            ...prev.contacts,
            [field]: value,
        },
    }))
    }

    const handleSaveAddress = async () => {
        

        const confirmResponse = await askConfirmation({title: <p>{Localization.PAGE_CUSTOMERS.TABS.DETAILS.UPDATE_MESSAGE}</p>})

        if (!confirmResponse.isConfirmed) return false

        try{
            showWaiting(Localization.ATTENDERE)

            const updatedShippingInfos = customerToSave.shipping_infos?.map((info, idx) => {

                const isCurrentDefault = idx === index && updatedAddress.is_default

                 // Applica la logica per impostare correttamente il default
                if (isCurrentDefault) {
                    return { ...updatedAddress, default: true, is_default: true }
                }
                //rimuove il default da altri
                if (idx !== index && info.default ) {
                    info.default= false
                    info.is_default= false 
                }

                return info
            })


            customerToSave.shipping_infos = updatedShippingInfos

                
            if(!updatedAddress){
                toastError(Localization.PAGE_CUSTOMERS.OPERATION_ERROR) 
                return
            }


        // Logica per eliminare shipping_info se shipping_infos è definito e contiene almeno un elemento
        if (customerToSave.shipping_infos && customerToSave.shipping_infos.length > 0) {
            delete customerToSave.shipping_info
        }
        
        const resp = await updateCustomer(customerToSave._id?.$oid!, customerToSave)

        setEditMode(false)
        toastSuccess(Localization.PAGE_ORDER.CHANGE_STATUS_SUCCESS)
        closeWaiting()

        } catch(e) {
            closeWaiting()
            toastError(Localization.ERRORE_GENERICO)
        }
    }

    const handleDeleteAddress = async () => {
        const confirmResponse = await askConfirmation({
            title: <p>{Localization.PAGE_CUSTOMERS.CONFIRM_DELETE_ADDRESS}</p>
        });
    
        if (!confirmResponse.isConfirmed) return;
    
        try {
            showWaiting(Localization.ATTENDERE)
    
            // Rimuovi l'indirizzo selezionato
            const newShippingInfos = customerToSave?.shipping_infos?.filter((_, idx) => idx !== index)
    
            customerToSave.shipping_infos = newShippingInfos;
            customerToSave.shipping_info= undefined;
    
            const resp = await updateCustomer(customerToSave._id?.$oid!, customerToSave);
    
            toastSuccess(Localization.PAGE_ORDER.CHANGE_STATUS_SUCCESS);
            onAddressUpdated()
        } catch (e) {
            toastError(Localization.ERRORE_GENERICO);
        } finally {
            closeWaiting();
        }
    }

    return (
    <div className="shipping-address-card">
        {editMode ? (
        <SectionSubGroup>
            <div className="row">
                        <strong className="mt-2 col-sm-12 col-md-12">{Localization.PAGE_CUSTOMERS.MODIFY_ADDRESS}</strong>
                        <div className="col-sm-12 mt-2 col-md-12 text-end">
                            <CSSwitch
                                label={Localization.PAGE_CUSTOMERS.SHIPPING_ADDRESSES_DEFAULT}
                                controlId={'new-default-address'}
                                checked={updatedAddress.is_default || false}
                                onChange={(e) => handleInputChange('is_default', e.target.checked)}
                            />
                        </div>
                    </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 mt-field">
                <CSInput
                    type="text"
                    controlId="contact_name"
                    label={Localization.PAGE_CUSTOMERS.CONTACT_NAME}
                    value={updatedAddress.contact_name || ''}
                    onChange={(e) => handleInputChange('contact_name', e.target.value)}
                    width="100%"
                />
                </div>
                {/* Campi per contacts */}
                <div className="col-sm-12 col-md-12 mt-field">
                <CSInput
                    type="email"
                    controlId="contact_email"
                    label={Localization.PAGE_CUSTOMERS.TABLE.EMAIL}
                    value={updatedAddress.contacts?.email || ''}
                    onChange={(e) => handleContactsChange('email', e.target.value)}
                    width="100%"
                />
                </div>
                <div className="col-sm-12 col-md-12 mt-field">
                    <CSInput
                        type="text"
                        controlId="new_contact_phone"
                        label={Localization.PAGE_CUSTOMERS.TABLE.PHONE}
                        value={updatedAddress.contacts?.phone || ''}
                        onChange={e => handleContactsChange('phone', e.target.value)}
                        width="100%"
                    />
                </div>
                <div className="col-sm-12 col-md-12 mt-field">
                    <CSInput
                        type="text"
                        controlId="new_contact_mobile"
                        label={Localization.PAGE_CUSTOMERS.TABLE.CELL}
                        value={updatedAddress.contacts?.mobile || ''}
                        onChange={e => handleContactsChange('mobile', e.target.value)}
                        width="100%"
                    />
                </div>
                {/* Campi per l'indirizzo */}
                <div className="col-sm-12 col-md-12 mt-field">
                <CSInput
                    type="text"
                    controlId="address_line"
                    label={Localization.PAGE_CUSTOMERS.TABLE.ADDRESS_LINE}
                    value={updatedAddress.address?.address_lines?.[0] || ''}
                    onChange={(e) => handleAddressLineChange(e.target.value)}
                    width="100%"
                />
                </div>
                <div className="col-sm-12 col-md-12 mt-field">
                <CSInput
                    type="text"
                    controlId="city"
                    label={Localization.PAGE_CUSTOMERS.TABLE.CITY}
                    value={updatedAddress.address?.city || ''}
                    onChange={(e) => handleAddressChange('city', e.target.value)}
                    width="100%"
                />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-7 col-md-7 mt-field">
                <CSInput
                    type="text"
                    controlId="province"
                    label={Localization.PAGE_CUSTOMERS.TABLE.PROVINCE}
                    value={updatedAddress.address?.province || ''}
                    onChange={(e) => handleAddressChange('province', e.target.value)}
                    width="100%"
                />
                </div>
                <div className="col-sm-4 col-md-4 mt-field">
                <CSInput
                    type="text"
                    controlId="province_code"
                    label={Localization.PAGE_CUSTOMERS.TABLE.PROVINCE_CODE}
                    value={updatedAddress.address?.province_code || ''}
                    onChange={(e) => handleAddressChange('province_code', e.target.value)}
                    width="100%"
                />
                </div>
                <div className="col-sm-5 col-md-5 mt-field">
                <CSInput
                    type="text"
                    controlId="postal_code"
                    label={Localization.PAGE_CUSTOMERS.TABLE.POSTAL_CODE}
                    value={updatedAddress.address?.postal_code || ''}
                    onChange={(e) => handleAddressChange('postal_code', e.target.value)}
                    width="100%"
                />
                </div>
                <div className="col-sm-8 col-md-8 mt-field">
                <CSFlagsSelect
                    mode="COUNTRY"
                    onChange={(option: CSOption) => handleAddressChange('country_code', option.value.toUpperCase())}
                    controlId="country_code"
                    label={Localization.PAGE_CUSTOMERS.TABLE.COUNTRY_CODE}
                    value={{
                    value: updatedAddress.address?.country_code || 'IT',
                    label: Localization.COUNTRIES_CODE[
                        (updatedAddress.address?.country_code?.toUpperCase() as keyof typeof Localization.COUNTRIES_CODE) || 'IT'
                    ],
                    }}
                />
                </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
                <Button variant="secondary"  onClick={() => setEditMode(false)} className="btn btn-muted me-3 p-3">
                    {Localization.ANNULLA}
                </Button>
                <Button variant="primary"  onClick={handleSaveAddress} className="btn btn-primary p-3">
                    {Localization.MODIFICA}
                </Button>
                
            </div>
        </SectionSubGroup>
        ) : (
            <SectionSubGroup 
                toolbar={
                    <>
                        <CSSpan role="button" onClick={() => setEditMode(true)} title={Localization.MODIFICA}>
                            <i className="bi-pencil-fill text-muted p-1" />
                        </CSSpan>
                        <CSSpan role="button" onClick={() => handleDeleteAddress()} title={Localization.ANNULLA}>
                            <i className="bi-trash3 text-muted p-1" />
                        </CSSpan>
                    </>
                    
                }
                title={updatedAddress.contact_name}    
                >
                <div className="row">
                    <div className="col">
                        {updatedAddress.address?.address_lines?.[0] && (
                            <div className="d-flex align-items-center">
                                <i className="bi-geo-alt text-muted p-1" />
                                <p className="mb-1">{updatedAddress.address.address_lines[0]}</p>
                            </div>
                        )}
                        {(updatedAddress.address?.postal_code || updatedAddress.address?.city || updatedAddress.address?.province_code) && (
                            <div className="d-flex align-items-center">
                                <i className="bi-building text-muted p-1" />
                                <p className="mb-1">
                                    {updatedAddress.address?.postal_code} {updatedAddress.address?.city}{' '}
                                    {updatedAddress.address?.province_code ? `(${updatedAddress.address.province_code})` : ''}
                                </p>
                            </div>
                        )}
                        {updatedAddress.address?.country_code && (
                            <div className="d-flex align-items-center">
                                <i className="bi-globe-americas text-muted p-1" />
                                <p className="mb-1">{updatedAddress.address.country_code}</p>
                            </div>
                        )}
                        <div className="d-flex justify-content-start" style={{width: '75%'}}>
                            {updatedAddress.contacts?.email && (
                                <div className="d-flex align-items-center me-3">
                                    <i className="bi-envelope-at text-muted p-1" />
                                    <p className="mb-1">{updatedAddress.contacts.email}</p>
                                </div>
                            )}
                            {updatedAddress.contacts?.phone && (
                                <div className="d-flex align-items-center me-3">
                                    <i className="bi-telephone text-muted p-1" />
                                    <p className="mb-1">{updatedAddress.contacts.phone}</p>
                                </div>
                            )}
                            {updatedAddress.contacts?.mobile && (
                                <div className="d-flex align-items-center">
                                    <i className="bi-phone text-muted p-1" />
                                    <p className="mb-1">{updatedAddress.contacts.mobile}</p>
                                </div>
                            )}
                        </div>
                        {updatedAddress.is_default && (
                            <div className="mt-2 d-flex align-items-center">
                                <i className="bi-check-circle-fill text-success me-1"></i>
                                <p className="mb-0">{Localization.PAGE_CUSTOMERS.SHIPPING_ADDRESSES_DEFAULT}</p>
                            </div>
                        )}
                    </div>
                </div>
            </SectionSubGroup>
        )}
        </div>
    )
    }

export default ShippingAddressCard