import CSInput from "@components/Form/CSInput"
import Localization from "@localization/Index"
import { useState } from "react"
import { Search } from "./SearchableList"

type Props = {
    search: Search
    controlId: string
}

const SearchableListSearch = (props: Props) =>{
    const { controlId, search} = props
    const { label=Localization.CERCA, disabled=false, onChange } = search
    const [term, setTerm] = useState("")
    return <div>
        <CSInput 
            type="text"
            solid
            onClear={()=>{
                setTerm("")
                return onChange?.("")
            }}
            icon="bi-search"
            controlId={`SearchableListSearch_${controlId}`}
            placeholder={label}
            value={term}
            onChange={(e)=>{
            setTerm(e.target.value);
            return onChange?.(e.target.value)
            }} 
            width={"100%"}
            disabled={disabled}
        />
    </div>
}

export default SearchableListSearch