import HelperInline from '@components/Helper/HelperInline'
import { FormLabel } from 'react-bootstrap'
import styles from './css/CSLabel.module.scss'

type Props = {
    label: string,
    mandatory?: boolean
    helpInline?: JSX.Element
}

const CSLabel = (props:Props) =>{
        const { label, helpInline, mandatory=false } = props
        return <FormLabel className={`${styles.label}${mandatory?` ${styles.mandatory}`:``}`}>
                    {label}
                    {helpInline && 
                        <span className='ms-2'><HelperInline placement="top" tooltip={helpInline} /></span>
                    }
                </FormLabel>
}

export default CSLabel