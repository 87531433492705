import { PropsWithChildren } from "react"
import styles from './css/ListItem.module.scss'

type Props = {
    skeleton?: boolean
}

const ListItem = (props: PropsWithChildren<JSX.IntrinsicElements["div"] & Props>) =>{
    const { onClick, onContextMenu, children, skeleton=false, className="" } = props
    if(skeleton) return <div className={`pt-4 pb-4 mt-3 mb-3 d-flex ${styles["container-loading"]} loading-skeleton`}></div>

    return <div className={`pt-4 pb-4 d-flex ${styles["container"]} ${className}`} onClick={(e)=>{onClick?.(e)}} onContextMenu={(e)=>{return onContextMenu?.(e)}}>
        {children}
        </div>
}

export default ListItem