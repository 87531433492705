import { ContextMenuItem, ContextSubMenu } from "@interfaces/ContextMenu"
import { group } from "console"
import React, { useEffect, useRef, useState } from "react"
import contextStyle from './css/ContextMenu.module.scss'
import SubMenu from "./SubMenu"

type ContextMenuProps = {
    menu: Array<ContextMenuItem>,
    coordinates: {x:number, y:number},
    closeFunction: ()=>any,
    width?: string
}

const ContextMenu = (props:ContextMenuProps)=> {
    const { menu, coordinates, closeFunction, width="200px" } = props

    const menuRef = useRef<HTMLDivElement>(null)
    const [style, setStyle] = useState<React.CSSProperties>({width})

    // this useeffect take care of shifting the menu in the visible area if is clicked in edge position (like all right or all bottom)
    useEffect(() => {
        if (!menuRef.current) return
        const resizeObserver = new ResizeObserver(() => {
            const menuWidth = menuRef?.current?.clientWidth ?? 0
            const menuHeight = menuRef?.current?.clientHeight ?? 0

            const {innerWidth, innerHeight} = window
            let top:string, left:string

            if(coordinates.y + menuHeight>= innerHeight) {
                top = `${innerHeight-menuHeight}px`
            } else {
                top = `${coordinates.y }px`
            }
        
            if(coordinates.x + menuWidth >= innerWidth) {
                left = `${innerWidth - menuWidth}px`
            } else {
                left = `${coordinates.x}px`
            }

            setStyle((prevStyle)=>{
                return {...prevStyle, top, left}
            })
        })
        resizeObserver.observe(menuRef.current)

        return () => resizeObserver.disconnect() // clean up 
    }, [])

    const calculateSubMenuPosition = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        const menuItem = document.getElementById(id)
        if(!menuItem) return
        const { top, right, bottom, left } = menuItem.getBoundingClientRect()
        const { clientHeight, clientWidth } = menuItem

        const subMenu = menuItem.querySelector(".context-sub-menu") as HTMLElement
        if(!subMenu) return 

        const { clientHeight: subMenuHeight, clientWidth: subMenuWidth } = subMenu

        const {innerWidth, innerHeight} = window

        let submenuTop:string, submenuLeft:string

        if(top + subMenuHeight > innerHeight) {
            submenuTop = `${-(top+subMenuHeight - innerHeight)}px`
        } else {
            submenuTop = `0px`
        }

        if(right + subMenuWidth < innerWidth) {
            submenuLeft = `${clientWidth}px`
        } else {
            submenuLeft = `-${subMenuWidth}px`
        }

        subMenu.style.top = submenuTop
        subMenu.style.left = submenuLeft
    }

    const defaultActionIndex = menu.findIndex(m=>m.default)
    const defaultAction = (defaultActionIndex>-1)?menu[defaultActionIndex]:menu[0]
    const otherActions = menu.filter((_, index)=>{
        if(defaultActionIndex>-1) return index !== defaultActionIndex
        return defaultActionIndex !== 0
    }).filter(item=>{
        const {show=true } = item
        return !((typeof show === "boolean" && !show) || (typeof show === "function" && !show()))
    })

    const defaultActionText = (typeof defaultAction.text === "string") ? defaultAction.text : (typeof defaultAction.text === "function" ? defaultAction.text(): defaultAction.text)

    return (
        <div className={contextStyle.overlay} onClick={()=>{closeFunction?.()}} onContextMenu={(e)=>{e.preventDefault();closeFunction?.()}}>
            <div ref={menuRef} className={`${contextStyle.menu}`} style={style} >
                <div role="button" className={`py-4 d-flex align-items-center justify-content-between ${contextStyle.firstItem}`} onClick={(e)=>{e.stopPropagation();defaultAction.action?.();closeFunction?.()}}>
                    <div>{defaultActionText}</div>
                    {defaultAction.subMenu && <div><i className="bi-chevron-right"></i></div>}
                    {defaultAction.subMenu && <SubMenu content={defaultAction.subMenu} />}
                </div>
                {otherActions.length>0 && <div className={`${contextStyle.separator} mb-3`}></div>}
                {otherActions.map((item,index)=>{
                    const { action, text, subMenu } = item
                    const _text = (typeof text === "string") ? text : (typeof text == "function"?text():text)

                    return (
                            <div key={`contextmenu_${index}`} role="button" id={`contextmenuitem_${index}`} className={`d-flex align-items-center justify-content-between ${contextStyle.item}`} onMouseEnter={subMenu?(e)=>{calculateSubMenuPosition(e, `contextmenuitem_${index}`)}:undefined} onClick={(e)=>{e.stopPropagation();action?.();closeFunction?.()}}>
                                <div>{_text}</div>
                                {subMenu && <div><i className="bi-chevron-right"></i></div>}
                                {subMenu && <SubMenu content={subMenu} closeFunction={closeFunction} />}
                            </div>
                        )
                    })}
            </div>
            
        </div>
    )
}

export default ContextMenu