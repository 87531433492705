import ShopIcon from "@components/ShopIcon/ShopIcon"
import { getOrderStatus, OrderStatus } from "@utils/OrderUtils"
import { format, parseISO } from "date-fns"
import { Order } from "@csapi/provisioning/api"
import { getShopsFromIds } from "@utils/ShopsUtils"
import { useState } from "react"
import OrderStatusChanger from "@components/OrderStatusChanger/OrderStatusChanger"
import styles from './css/OrderSummaryHeader.module.scss'

type Props = {
    order: Order,
    dateType?: "order_dt" | "creation_dt" | "update_dt" | "last_status_update_dt",
    showTotal?: boolean,
    showItemsCount?: boolean
}

const OrderSummaryHeaderDetails = (props: Props) =>{
    const { order, dateType="order_dt", showTotal=true, showItemsCount=false } = props
    const status = getOrderStatus(order?.order_status as OrderStatus)
    const [mouseCoordinates, setMouseCoordinates] = useState({x:0,y:0})
    const orderDate = (!!order?.[dateType]?.$date)?format(parseISO(order?.[dateType]?.$date), 'dd/MM/yyyy HH:mm'):"N.D."
    const shopIds = [order?.shop_id?.$oid!]
    const shop = getShopsFromIds(shopIds)?.[0]
    const [showOrderStatusChanger, setShowOrderStatusChanger] = useState(false)

    const openChangeStatus = (e:React.MouseEvent) =>{
        setMouseCoordinates({x:e.pageX, y:e.pageY})
        setShowOrderStatusChanger(true)
    }

    return <>
        <div className="d-flex justify-content-left align-items-center mt-4">
            <OrderStatusChanger
                setOpen={setShowOrderStatusChanger}
                open={showOrderStatusChanger}
                position={mouseCoordinates}
                order={order}
            />
            <div className={`${styles.statusUpdate}`} style={{textDecorationColor:status.color}}  onClick={(e:React.MouseEvent)=>{openChangeStatus(e)}} >
                <i className={`${status.icon} font-1-5x text-${status.color} me-2`} />
                <strong className={`text-${status.color} me-4`}>{order?.order_status ?? "N.D."}</strong>
                </div>
            <i className={`bi-calendar3 font-1-5x text-muted me-2`} />
            <span className="small me-4">{orderDate}</span>
            {showTotal && <>
                <i className={`bi-cash-stack font-1-5x text-muted me-2`} />
                <span className="small me-4">{`${order?.tot_amount?.amount?.toFixed(2) ?? "N.D."} ${order?.tot_amount?.currency ?? ""}`}</span>
            </>}
            {showItemsCount && <>
                <i className={`bi-boxes font-1-5x text-muted me-2`} />
                <span className="small me-4">{`${order?.items?.length ?? 0}`}</span>
            </>}
            <ShopIcon id={shop?.cs_shop_id?.$oid ?? ""} size={26} />
            <span className={`small ms-2`}>{shop?.name}</span>
        </div>
    </>
}

export default OrderSummaryHeaderDetails