import React from 'react';
import { useParams } from 'react-router-dom';
import ModuleDetail from './ModuleDetail';
import ModuleDetailsFinance from './ModuleDetailsFinance';
import ModuleDetailsERP from './ModuleDetailsERP';
import ModuleDetailsBC from './ModuleDetailsBC';
import ModuleDetailsWarehouses from './ModuleDetailsWarehouses';
import ModuleDetailsPOS from './ModuleDetailsPOS';

export const moduleComponentsMap = {
    'FINANCE': ModuleDetailsFinance,
    'ERP': ModuleDetailsERP,
    "BUSINESSCONDITIONS": ModuleDetailsBC,
    "POS": ModuleDetailsPOS,
    "WAREHOUSES": ModuleDetailsWarehouses
  }

const ModuleDetailProxy = () => {
  const { id: rawId } = useParams()
  const id = rawId?.toUpperCase()

  const SpecificModuleComponent = moduleComponentsMap[id as keyof typeof moduleComponentsMap] || ModuleDetail

  return <SpecificModuleComponent id={id??''} />
};

export default ModuleDetailProxy;
