import React from "react"
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger"
import Tooltip from "react-bootstrap/esm/Tooltip"
import styles from './css/CSSpan.module.scss'

type SpanProps = {
    tooltip?: string,
    hover?: boolean,
    disabled?: boolean,
    underline?: "none"|"always"|"hover",
    badgecount?: number
    allowClickWhenDisabled?: boolean
}

const CSSpan = (props:SpanProps & React.HTMLAttributes<HTMLSpanElement>)=> {
    const { tooltip, children, role, underline="none", disabled=false, badgecount=0, style={}, onClick, allowClickWhenDisabled=true, ...rest } = props

    const tooltipComponent = tooltip?(
        <Tooltip id="tooltip" style={{position:'fixed'}}>
            {tooltip}
        </Tooltip>
    ):<></>;
    
    const underlineStyle = (underline==="always") ? styles.underlineAlways : ((underline==="hover") ? styles.underlineHover : ``)
    return (
        <OverlayTrigger placement="top" overlay={tooltipComponent}>
            <span {...rest} role={role} onClick={(disabled)?undefined:onClick} className={`${!!props.className?props.className:""} ${(role=="button" && !disabled)?styles.hover:''} ${disabled?styles.disabled:""} ${underlineStyle}`} style={{position: 'relative', ...style}}>
                {children}
                {badgecount>0 && <span className="smallest ps-1 pe-1" style={{fontSize: '8pt', position:"absolute", top: '-10px', right: '-10px', borderRadius: '5px', backgroundColor: 'red', color: 'white'}}>{badgecount}</span>}
            </span>
        </OverlayTrigger>
    )
}

export default CSSpan