import Localization from "@localization/Index"
import { toastSuccess } from "@utils/NotificationsUtils"

type ClipboardType = {
    text: string,
    textSuccess?: string,
    showToast?: boolean
}

/**
 * copy the text to the clipboard
 * @param args of type ClipboardType
 * @returns 
 */
const copyToClipboard = async (args:ClipboardType) =>{
    const {text, textSuccess=Localization.COPIATO, showToast=true } = args
    if(navigator.clipboard) {
        await navigator.clipboard.writeText(text)
    } else {
        const input = document.createElement('textarea')
        input.value = text
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
    }
    showToast && toastSuccess(textSuccess)
    return
}

/**
 * generate random id
 * @returns the id generated
 */
const generateId = ():string=>{
    return Math.floor(Math.random() * Date.now()).toString(16)
}

export { copyToClipboard, generateId }