import styles from './css/SideMenuItem.module.scss'
import { UserRoleEnum } from "@interfaces/User"
import { ModuleIdEnum } from "@utils/ModulesUtils"
import React from "react"
import { AuthContext } from "@providers/AuthProvider"
import { LinkContainer } from 'react-router-bootstrap'

type Props = {
    name: string,
    icon?: string,
    selected: Boolean,
    to: string
    modules?: (keyof typeof ModuleIdEnum)[]
    roles?: (keyof typeof UserRoleEnum)[]
}

const SideMenuItem = (props:Props) =>{
    const auth = React.useContext(AuthContext)
    const { name, icon, selected, to, roles=[UserRoleEnum.SUPPORT, UserRoleEnum.BASE], modules} = props

    // check if the user has access to at least one of the modules listed in the modules property
    const modulesNotAllowed = modules && !auth.userData.modules?.find(module=>{
        return module._id && modules.includes(module._id) && module.enabled
    })

    // check if the user has the role needed for this page
    const roleNotAllowed = roles && !roles.includes(auth.userData.role)

    if(modulesNotAllowed || roleNotAllowed) return <></>

    return (
        <div className={`${styles.container} ${selected?styles.selected:""}`}>
            <div className={`${styles.link}`}>
                <LinkContainer to={to} style={{textDecoration: 'none'}}>
                    <a href={to}>
                        <div className={`d-flex justify-content-between align-items-center`} style={{width:'100%'}}>
                            <div className='d-flex justify-content-start align-items-center' style={{fontWeight: 600, fontSize: '1.15rem'}}>
                                {icon && <span className={`${styles.icon}`}><i className={`${icon} ${selected?"text-primary":"text-gray-500"}`} /></span>}
                                <div className={`${selected?"text-primary":"text-gray-800"}`}>{name}</div>
                            </div>
                        </div>   
                    </a>
                </LinkContainer>
            </div>
        </div>
    )
}

export default SideMenuItem