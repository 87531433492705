import { useRef, useState } from "react"
import DatePicker, { CalendarContainer, CalendarContainerProps, registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import styles from './css/CSDatePicker.module.scss'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-GB'
import Form from "react-bootstrap/esm/Form"
import Localization from "@localization/Index"
import { format, parseJSON, addDays, addMonths, startOfMonth, lastDayOfMonth } from 'date-fns'
import React from "react"
import { ConfigurationContext } from "@providers/ConfigurationProvider"
import { generateId } from '@utils/StringUtils'
import InputGroup from "react-bootstrap/esm/InputGroup"
import CSInput from "./CSInput"
import CSLabel from "./CSLabel"

type Props = CalendarContainerProps & {
    controlId: string
    dateRange: [Date|null, Date|null],
    setDateRange: (range:[Date|null, Date|null])=>void,
    label?: string
    mandatory?: boolean
    data_cy?: string
    isClearable?: boolean
}

export interface PresetDateRange {
    id:string,
    label: string,
    dates: [string|null, string|null]
}


const dateToString = (date:Date|null)=>{
    if(!date) return null
    return format(date, 'dd/MM/yyyy')
}

const stringToDate = (value:string|null) =>{
    if(!value) return null
    return parseJSON(value)
}

const today = new Date()
const yesterday = addDays(today, -1)
const sevenDaysAgo = addDays(today, -7)
const firstOfMonth = startOfMonth(today)
const firstOfPreviousMonth = addMonths(firstOfMonth, -1)
const lastOfPreviousMonth = lastDayOfMonth(firstOfPreviousMonth)


const defaultDateRanges: Array<PresetDateRange> = [
    {id: "default_date_range_1", label: Localization.DATE_PICKER.DEFAULT_DATE_RANGES.OGGI, dates: [JSON.stringify(today), JSON.stringify(today)] },
    {id: "default_date_range_2", label: Localization.DATE_PICKER.DEFAULT_DATE_RANGES.IERI, dates: [JSON.stringify(yesterday), JSON.stringify(yesterday)] },
    {id: "default_date_range_3", label: Localization.DATE_PICKER.DEFAULT_DATE_RANGES.ULTIMA_SETTIMANA, dates: [JSON.stringify(sevenDaysAgo), JSON.stringify(today)] },
    {id: "default_date_range_4", label: Localization.DATE_PICKER.DEFAULT_DATE_RANGES.PRIMO_MESE, dates: [JSON.stringify(firstOfMonth), JSON.stringify(today)] },
    {id: "default_date_range_5", label: Localization.DATE_PICKER.DEFAULT_DATE_RANGES.MESE_PRECEDENTE, dates: [JSON.stringify(firstOfPreviousMonth), JSON.stringify(lastOfPreviousMonth)] },
]

const CSDatePicker = (props:Props) =>{
    const { controlId, dateRange, setDateRange, label, mandatory=false, isClearable=true, data_cy } = props
    //const [dateRange, setDateRange] = useState<[Date|null, Date|null]>([null, null])
    const [startDate, endDate] = dateRange
    const [selectedPresetDateRange, setSelectedPresetDateRange] = useState<string|undefined>(undefined)
    const [showSavePreset, setShowSavePreset] = useState(false)

    const datePickerRef = useRef<any>()
    const savePresetNameRef = useRef<HTMLInputElement>(null)

    const configurationContext = React.useContext(ConfigurationContext)
    const savedPresets = configurationContext.getValue("dateRangePresets", []) as PresetDateRange[]

    registerLocale('it',it)
    registerLocale('en',en)

    const saveConfiguration = ()=>{
        if(dateRange.includes(null)) return false

        let presetName = savePresetNameRef.current?.value
        if(!presetName) {
            presetName = `${dateToString(dateRange[0])} - ${dateToString(dateRange[1])}`
        }
        const presetToBeSaved:PresetDateRange = {
            id: generateId(),
            label: presetName,
            dates: [JSON.stringify(dateRange[0]), JSON.stringify(dateRange[1])]
        }

        savedPresets?.push(presetToBeSaved)

        configurationContext.setValue("dateRangePresets", savedPresets)
        savePresetNameRef.current!.value = ""
        setSelectedPresetDateRange(presetToBeSaved.id)
        setShowSavePreset(false)
    }

    const selectPreset = (presetDateRange:PresetDateRange) =>{
        setDateRange([stringToDate(presetDateRange.dates[0]), stringToDate(presetDateRange.dates[1])])
        datePickerRef.current?.setPreSelection(stringToDate(presetDateRange.dates[0]))
        setSelectedPresetDateRange(presetDateRange.id)
        if(savePresetNameRef.current) {savePresetNameRef.current.value = ""}
        setShowSavePreset(false)
    }

    const removePreset = (presetDateRange:PresetDateRange) =>{
        const filteredPresets = savedPresets?.filter(preset=>preset.id!=presetDateRange.id)
        configurationContext.setValue("dateRangePresets", filteredPresets)
        if(presetDateRange.id===selectedPresetDateRange) {
            setSelectedPresetDateRange(undefined)
        }
    }

    const setEndOfDay = (date:Date|null) => {
        if (date){
            date.setHours(23, 59, 59, 999);
            return date;
        }
        return date;
    };

    const PredefinedActionsContainer = (props:CalendarContainerProps) => {
        const { children, className } = props
        return (
            <div className={`d-flex shadow-lg p-2 ${styles["calendar-container"]}`} data-cy={`cs-datepicker-${data_cy}`}>    
                <CalendarContainer className={className}>
                    <div style={{ position: "relative" }}>{children}</div>
                    
                </CalendarContainer>
                <div className="d-flex flex-column align-items-start justify-content-start p-1">
                    {defaultDateRanges.map((presetDateRange:PresetDateRange)=>{
                        return (
                            <div key={presetDateRange.id} role="button" className={`ps-1 ${styles.presetDateRange} ${presetDateRange.id === selectedPresetDateRange ? "text-primary":"text-muted"}`} onClick={()=>{
                                selectPreset(presetDateRange)
                            }}>{presetDateRange.label}</div>
                        )
                    })}
                    {savedPresets?.map((presetDateRange:PresetDateRange)=>{
                        return (
                            <div style={{width:'100%'}} key={presetDateRange.id} role="button" className={`ps-1 d-flex justify-content-between align-items-center`}>
                                <span className={`me-2 ${styles.presetDateRange} ${presetDateRange.id === selectedPresetDateRange ? "text-primary":"text-muted"}`} onClick={()=>{
                                selectPreset(presetDateRange)
                            }}>{presetDateRange.label}</span>
                                <i className={`bi-trash text-muted ${styles.presetDateRange}`} role="button" onClick={()=>{removePreset(presetDateRange)}}/>
                            </div>
                        )
                    })}
                    <Form className={`mt-5 ps-1 ${!showSavePreset?"invisible":""}`} onSubmit={(e)=>{e.preventDefault(); saveConfiguration()}}>
                        <CSInput 
                            controlId='datepicker_save_preset_btn'
                            placeholder={`${dateToString(dateRange[0])} - ${dateToString(dateRange[1])}`}
                            label={Localization.ADVANCED_FILTERS.SALVA_FILTRO_PERSONALIZZATO}
                            type="text"
                            ref={savePresetNameRef}
                            width={'250px'}
                            buttons={[
                                {
                                    icon: 'bi-check-circle',
                                    action: ()=>saveConfiguration()
                                }
                            ]}
                        />
                    </Form>
                </div>
            </div>
        );
    }

    const CustomInput = React.forwardRef<any,any>(({ value, onClick }, ref) => (
        <>
        
        <InputGroup className="me-2">
            <CSInput width="245px" type="text" controlId={`${controlId}_input`} onClick={onClick} ref={ref} value={value} />
        </InputGroup>
        </>
    ))

    return (
        <div style={{width:'245px'}}>
            {label && <CSLabel mandatory={mandatory} label={label} />}
            <DatePicker
                id={controlId}
                locale={Localization.__LANGUAGE}
                ref={datePickerRef}
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onCalendarClose={()=>{
                    if(dateRange.includes(null)) {
                        setDateRange([null,null])
                    }
                    setShowSavePreset(false)}
                }
                onChange={(update) => {
                    console.log("change", update)
                    if(update[1])setEndOfDay(update[1]);
                    setDateRange(update)
                    if(!(update.includes(null))) {
                        setShowSavePreset(true)
                    } else {
                        setSelectedPresetDateRange(undefined)
                        setShowSavePreset(false)
                    }
                }}
                
                isClearable={isClearable}
                monthsShown={2}
                shouldCloseOnSelect={false}
                customInput={<CustomInput />}
                calendarContainer={PredefinedActionsContainer}
            />
        </div>
    )
}

export default CSDatePicker