import { OverlayTrigger } from "react-bootstrap"
import { Placement } from "react-bootstrap/esm/types"
import styles from './css/HelperInline.module.scss'

type Props = {
    tooltip: JSX.Element
    placement: Placement
    iconClassName?: string
}

const HelperInline = (props: Props) =>{
    const { tooltip, placement, iconClassName="bi-info-circle" } = props

    const TooltipComponent = ({scheduleUpdate, arrowProps, outOfBoundaries, show, ...props}: any)=><div {...props} className={`${styles["helper-inline-tooltip"]}`}>
        {tooltip}
    </div>

    return (
        <OverlayTrigger offset={[0,10]} rootClose placement={placement} trigger={['click']} overlay={TooltipComponent}>
                <i role="button" className={`${iconClassName} text-info`}></i>
        </OverlayTrigger>
    )
}

export default HelperInline