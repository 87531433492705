import styles from './css/OrderItemsEntry.module.scss'
import { OrderItemWithInfo } from "@interfaces/Orders"
import ItemHeader from '../ItemHeader/ItemHeader'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Localization from '@localization/Index'
import CSInput from '@components/Form/CSInput'
import CSSwitch from '@components/Form/CSSwitch'

type Props = {
    loading?: boolean,
    item?: OrderItemWithInfo,
    isVariationEditable?: boolean
    onVariationChange?: (sku: string, variation:string, allSiblings: boolean)=>unknown
}

const OrderItemsEntry = (props:Props)=>{
    const { loading, item, isVariationEditable=false, onVariationChange } = props
    const [isVariationModalOpen, setIsVariationModalOpen] = useState(false)
    const [editVariation, setEditVariation] = useState(item?.variation ?? "0")
    const [allSiblings, setAllSiblings] = useState(true)

    if(loading) return (
        <div className="m-2 p-2 d-flex flex-column loading-skeleton">
            <div className={styles.skeletonItem} style={{width:"200px"}}>&nbsp;</div>
            <div className={styles.skeletonItemSub} style={{width:"300px"}}>&nbsp;</div>
        </div>
    )

    const openVariationModal = ()=>{
        setEditVariation(item?.variation ?? "0")
        setAllSiblings(true)
        setIsVariationModalOpen(true)
    }

    const changeVariation = ()=>{
        setIsVariationModalOpen(false)
        if(!sku) return false

        return onVariationChange?.(sku, editVariation, allSiblings)
    }

    const { sku, qty, cost, variation, variationType, unit_price } = item!

    return (
        <>
        <div className={`row ${styles.container}`}>
            <div className="col-10">
                <ItemHeader item={item} /> 
            </div>
            <div className={`col-md-2 d-flex justify-content-end align-items-center ${styles.cell}`}>
                {qty}
            </div>
            <div className={`col-md-4 d-flex justify-content-end align-items-center ${styles.cell}`}>
            {unit_price?.amount?`${unit_price?.amount?.toFixed(2)} ${unit_price?.currency ?? "EUR"}`:"N.D."}
            </div>
            <div className={`col-md-4 d-flex justify-content-end align-items-center ${styles.cell}`}>
                {isVariationEditable && <div className='d-flex align-items-center justify-content-center me-3'>
                    <i role="button" className='bi bi-pencil text-muted font-1-5x' onClick={()=>openVariationModal()} />
                </div>}
                <div className='d-flex flex-column justify-content-center align-items-end'>
                    <div>
                        {variation?`${parseFloat(variation).toFixed(2)}%`:`N.D.`}
                    </div>
                    {variationType && <div className={styles.subCell}>
                        {variationType}
                    </div>}
                </div>
                
            </div>
            <div className={`col-md-4 d-flex justify-content-end align-items-center ${styles.cell}`}>
                {cost?.amount?`${cost?.amount?.toFixed(2)} ${cost?.currency}`:"N.D."}
            </div>
        </div>
        <Modal show={isVariationModalOpen} onHide={()=>setIsVariationModalOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{`${Localization.MODIFICA} ${variationType}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-24">
                        <CSInput 
                            type="number"
                            controlId="form_variation"
                            label={variationType}
                            value={editVariation}
                            onChange={(e) => setEditVariation(e.target.value) }
                            width="100px"
                        />
                        <CSSwitch
                            className='mt-field'
                            controlId="form_all_siblings"
                            label={Localization.PAGE_ORDER.APPLICA_A_SKU_STESSO_PARENT}
                            checked={allSiblings}
                            onChange={(e)=>setAllSiblings(prev=>!prev)}
                        />
                    </div> 
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-center align-items-center w-100">
                    <button onClick={()=>setIsVariationModalOpen(false)} className='m-1 mx-2 btn btn-light'>{Localization.ANNULLA}</button>
                    <button onClick={()=>changeVariation()} className='m-1 btn mx-2 btn-primary'>{Localization.SALVA}</button>
                </div>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default OrderItemsEntry