import Localization from "@localization/Index"
import { NavigateFunction } from "react-router-dom"
import { getShopFromId, getShopFromName } from "./ShopsUtils"

enum QueueEntryObjectStatusEnum {
    ToBeProcessed= "TO_BE_PROCESSED",
    Processing= "PROCESSING",
    ProcessedSuccessful= "PROCESSED_SUCCESSFUL",
    ProcessedFailed= "PROCESSED_FAILED",
    none="NONE",
}

const QueueEntryStatuses = [
    {id: QueueEntryObjectStatusEnum.ToBeProcessed, label: Localization.QUEUES.STATUSES.TO_BE_PROCESSED, color: 'info'},
    {id: QueueEntryObjectStatusEnum.Processing, label: Localization.QUEUES.STATUSES.PROCESSING, color: 'warning'},
    {id: QueueEntryObjectStatusEnum.ProcessedSuccessful, label: Localization.QUEUES.STATUSES.PROCESSED_SUCCESSFUL, color: 'success'},
    {id: QueueEntryObjectStatusEnum.ProcessedFailed, label: Localization.QUEUES.STATUSES.PROCESSED_FAILED, color: 'danger'},
]


const getQueueEntryStatus = (id?: QueueEntryObjectStatusEnum)=>{
    if(!id) return undefined
    return QueueEntryStatuses.find(queueEntryStatus=>queueEntryStatus.id===id)
}

export interface CustomColumn {
    attribute: string
    translations: string
    icon: string
    value: (data: any) => number | string
    onClick?: (...args: any[]) => void
    className?:string
}

export interface QueueItem {
    group: QueueListInfoGroupEnum
    card_group:QueueCardGroupEnum
    card_group_translation?:string
    name: string
    label: string
    description: string
    icon: string
    external: boolean
    visible?: boolean
    background_color?: string
    custom_columns?: CustomColumn[] // è l'elenco delle colonne custom per la singola coda
    custom_details_info?: CustomColumn[] // sono gli attributi custum da visualizzare all'interno del tab info sotto gli attribti standard
    custon_details_detail?:string // è il componente da renderizzare dentro il tabs Details
}

export enum QueueListInfoGroupEnum  {
    AMAZON= 'AMAZON',
    BC= 'BC',
    ERP= 'ERP',
    FxFF= 'FxFF',
    NETSUITE= 'NETSUITE',
    OTHER= 'OTHER',
    MKPL_SVT= 'MKPL_SVT',
    WHS= 'WHS',
    NONE='NONE',
    TON= 'TON',
    RETAILPRO='RETAILPRO',
    EFASHION='EFASHION',
    EXTERNAL="EXTERNAL"
}

export enum QueueCardGroupEnum {
    AMAZON = 'AMAZON',
    NETSUITE = 'NETSUITE',
    OTHER = 'OTHER',
    SHOP = 'SHOP',
    ACCOUNT = 'ACCOUNT',
    WHS = 'WHS',
    FXFF= 'FXFF',
    BC = 'BC',
    ERP = 'ERP',
    MKPL_SVT = 'MKPL_SVT',
    NONE='NONE',
    SAVAIT= 'SAVAIT',
    TON="TON",
    RETAILPRO="RETAILPRO",
    EFASHION='EFASHION',
    EXTERNAL="EXTERNAL",
    NEW="NEW"
}

const findQueueItemByName = (value: string): QueueItem | undefined => {
    return queues_list.find(item => item.name === value)
}

const statusIcons = {
    ELABORATED: "bi-check-circle-fill text-muted", 
    PENDING: "bi-clock-fill text-muted", 
    TO_BE_PROCESSED: "bi-arrow-repeat text-muted", 
    PROCESSED_SUCCESSFUL: "bi-check-circle-fill text-success", 
    PROCESSED_FAILED: "bi-exclamation-circle-fill text-danger", 
    ND:"bi-question-circle text-muted"
}


/**NON TROVO 
 * other.listing
 * inbound.request
 * inbound.response
 * */

const queues_list : QueueItem[] =  [
    {
        'group': QueueListInfoGroupEnum.AMAZON,
        'card_group': QueueCardGroupEnum.AMAZON,
        'card_group_translation': Localization.QUEUES.CARD.AMAZON,
        'name': 'amzn.feed-request.fulfillment',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.AMZN_FEED_REQUEST_FULFILLMENT.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.AMZN_FEED_REQUEST_FULFILLMENT.DESCRIPTION,
        'icon': 'bi-truck',
        'external': false,
        
    },
    {
        'group': QueueListInfoGroupEnum.AMAZON,
        'card_group': QueueCardGroupEnum.AMAZON,
        'card_group_translation': Localization.QUEUES.CARD.AMAZON,
        'name': 'amzn.feed-request.inventory',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.AMZN_FEED_REQUEST_INVENTORY.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.AMZN_FEED_REQUEST_INVENTORY.DESCRIPTION,
        'icon': 'bi-upc-scan',
        'external': false,
        
    },
    {
        'group': QueueListInfoGroupEnum.AMAZON,
        'card_group': QueueCardGroupEnum.AMAZON,
        'card_group_translation': Localization.QUEUES.CARD.AMAZON,
        'name': 'amzn.feed-response',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.AMZN_FEED_RESPONSE.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.AMZN_FEED_RESPONSE.DESCRIPTION,
        'icon': 'bi-amazon',
        'external': false,
        
    },
    {
        'group': QueueListInfoGroupEnum.NETSUITE,
        'card_group': QueueCardGroupEnum.NETSUITE,
        'card_group_translation': Localization.QUEUES.CARD.NETSUITE,
        'name': 'ns.inbound.cashsale-or-invoice',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_CASHSALE_INVOICE.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_CASHSALE_INVOICE.DESCRIPTION,
        'icon': 'bi-cash-coin',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.NETSUITE,
        'card_group': QueueCardGroupEnum.NETSUITE,
        'card_group_translation': Localization.QUEUES.CARD.NETSUITE,
        'name': 'ns.inbound.customer',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_CUSTOMER.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_CUSTOMER.DESCRIPTION,
        'icon': 'bi-people-fill',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.NETSUITE,
        'card_group': QueueCardGroupEnum.NETSUITE,
        'card_group_translation': Localization.QUEUES.CARD.NETSUITE,
        'name': 'ns.inbound.fulfillments',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_FULFILLMENTS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_FULFILLMENTS.DESCRIPTION,
        'icon': 'bi-truck',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.NETSUITE,
        'card_group': QueueCardGroupEnum.NETSUITE,
        'card_group_translation': Localization.QUEUES.CARD.NETSUITE,
        'name': 'ns.inbound.item-availability-update',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_ITEM_AVAILABILITY.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_ITEM_AVAILABILITY.DESCRIPTION,
        'icon': 'bi-boxes',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.NETSUITE,
        'card_group': QueueCardGroupEnum.NETSUITE,
        'card_group_translation': Localization.QUEUES.CARD.NETSUITE,
        'name': 'ns.inbound.order-update',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_ORDERS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_ORDERS.DESCRIPTION,
        'icon': 'bi-card-list',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.NETSUITE,
        'card_group': QueueCardGroupEnum.NETSUITE,
        'card_group_translation': Localization.QUEUES.CARD.NETSUITE,
        'name': 'ns.outbound.sales-order',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.NS_OUTBOUND_SALES_ORDERS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.NS_OUTBOUND_SALES_ORDERS.DESCRIPTION,
        'icon': 'bi-list-ul',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.NETSUITE,
        'card_group': QueueCardGroupEnum.NETSUITE,
        'card_group_translation': Localization.QUEUES.CARD.NETSUITE,
        'name': 'ns.outbound.transfer-order',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.NS_OUTBOUND_TRANSFER_ORDERS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.NS_OUTBOUND_TRANSFER_ORDERS.DESCRIPTION,
        'icon': 'bi-arrow-left-right',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.NETSUITE,
        'card_group': QueueCardGroupEnum.NETSUITE,
        'card_group_translation': Localization.QUEUES.CARD.NETSUITE,
        'name': 'ns.inbound.item',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_ITEMS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.NS_INBOUND_ITEMS.DESCRIPTION,
        'icon': 'bi-upc',
        'external': false
    },
   /*{
        'group': QueueListInfoGroupEnum.OTHER',
        'card_group':QueueCardGroupEnum.NONE,
        'card_group_translation': Localization.QUEUES.CARD.,   
        'name': 'other.report',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.REPORT.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.REPORT.DESCRIPTION,
        'icon': 'fa fa-file-text',
        'external': false,
        'visible': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER',
        'card_group':'ACCOUNT',
         'card_group':QueueCardGroupEnum.NONE,
        'card_group_translation': Localization.QUEUES.CARD.,
        'name': 'cs.listing.queue',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.LISTING.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.LISTING.DESCRIPTION,
        'icon': 'fa fa-cloud-upload',
        'external': false
    },*/
   /* {
        'group': QueueListInfoGroupEnum.OTHER',
        'card_group':QueueCardGroupEnum.NONE,
        'card_group_translation': Localization.QUEUES.CARD.,
        'name': 'cs.long-running.tasks.queue',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.LRT.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.LRT.DESCRIPTION,
        'icon': 'fa fa-hourglass-half',
        'external': false
    },*/
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.SHOP,
        'card_group_translation': Localization.QUEUES.CARD.SHOP,
        'name': 'other.outbound.packages',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_PACKAGES.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_PACKAGES.DESCRIPTION,
        'icon': 'bi-archive',
        'external': false,
        'custom_columns':[
            {
                attribute: 'partition_id',
                translations: Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_PACKAGES.PARTITON_ID,
                icon: "bi-cart",
                value: (data) => getShopFromId(data)?.name??data,
            }
        ],
        'custom_details_info':[
            {
                attribute:'partition_id',
                translations: Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_PACKAGES.PARTITON_ID,
                icon: "bi-cart",
                value: (data) => getShopFromId(data)?.name??data,
                className:'details_info',
                onClick: (...args: any[]) => {
                    const [data, navigate] = args
                    const shop= getShopFromName(data)
                    if (typeof navigate === 'function' && data ) {
                        navigate(`/shops/${shop?.cs_shop_id?.$oid??''}/${shop?._id?.$oid??''}`)
                    } else {
                        console.log('parametri non validi')
                    }
                }
            },
            {
                attribute: 'external_key',
                translations: Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_PACKAGES.EXTERNAL_KEY,
                icon: "bi-send",
                className:'details_info',
                value: (data) => data,
                onClick: (...args: any[]) => {
                    const [data, navigate] = args
                    if (typeof navigate === 'function' && data ) {
                        navigate(`/orders/${data}`)
                    } else {
                        console.log('parametri non validi')
                    }
                }
            }

        ],
        'custon_details_detail':'TEST'
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.SHOP,
        'card_group_translation': Localization.QUEUES.CARD.SHOP,
        'name': 'other.outbound.shipments',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_SHIPMENTS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_SHIPMENTS.DESCRIPTION,
        'icon': 'bi-truck',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group':QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.lrt',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.LRT.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.LRT.DESCRIPTION,
        'icon': 'bi-hourglass-split',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.bulk-inbound-items',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_BULK_INBOUND_ITEMS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_BULK_INBOUND_ITEMS.DESCRIPTION,
        'icon': 'bi-stack',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.report',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.REPORT.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.REPORT.DESCRIPTION,
        'icon': 'bi-file-text',
        'external': false
    },

    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group':QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.inbound-items',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_INBOUND_ITEMS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_INBOUND_ITEMS.DESCRIPTION,
        'icon': 'bi-upc',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.bulk.inbound.item-images',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_BULK_INBOUND_ITEM_IMAGES.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_BULK_INBOUND_ITEM_IMAGES.DESCRIPTION,
        'icon': 'bi-film',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.inbound.items-images',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_INBOUND_ITEM_IMAGES.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_INBOUND_ITEM_IMAGES.DESCRIPTION,
        'icon': 'bi-file-earmark-image',
        'external': false,
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.SHOP,
        'card_group_translation': Localization.QUEUES.CARD.SHOP,
        'name': 'other.outbound.returns',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_RETURN.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_RETURN.DESCRIPTION,
        'icon': 'bi-arrow-repeat',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.SHOP,
        'card_group_translation': Localization.QUEUES.CARD.SHOP,
        'name': 'other.outbound.return-requests-reply',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_RETURN_REQUEST.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.OTHER_OUTBOUND_RETURN_REQUEST.DESCRIPTION,
        'icon': 'bi-arrow-clockwise',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.listing',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.LISTING.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.LISTING.DESCRIPTION,
        'icon': 'bi-cloud-arrow-up',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.listing-exclusion-rule.apply',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.LISTING_EXCLUSION_RULES.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.LISTING_EXCLUSION_RULES.DESCRIPTION,
        'icon': 'bi-cart3',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.outbound.availability-prices',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.AVAILABILITY_PRICE.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.AVAILABILITY_PRICE.DESCRIPTION,
        'icon': 'bi-tag',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.OTHER,
        'card_group': QueueCardGroupEnum.ACCOUNT,
        'card_group_translation': Localization.QUEUES.CARD.ACCOUNT,
        'name': 'other.outbound.availability-prices.result',
        'label': Localization.QUEUES.DESCRIPTIONS.OTHER.AVAILABILITY_PRICE_RESULT.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OTHER.AVAILABILITY_PRICE_RESULT.DESCRIPTION,
        'icon': 'bi-tags',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.WHS,
        'card_group': QueueCardGroupEnum.WHS,
        'card_group_translation': Localization.QUEUES.CARD.WHS,
        'name': 'inbound.movements',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_INBOUND_WHS_MOVEMENTS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_INBOUND_WHS_MOVEMENTS.DESCRIPTION,
        'icon': 'bi-arrow-down-up',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.WHS,
        'card_group': QueueCardGroupEnum.WHS,
        'card_group_translation': Localization.QUEUES.CARD.WHS,
        'name': 'bulk.inbound.movements',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_BULK_INBOUND_WHS_MOVEMENTS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.OTHER_BULK_INBOUND_WHS_MOVEMENTS.DESCRIPTION,
        'icon': 'bi bi-arrow-repeat',
        'external': false,
    },
    {
        'group': QueueListInfoGroupEnum.BC,
        'card_group': QueueCardGroupEnum.BC,
        'card_group_translation': Localization.QUEUES.CARD.BC,
        'name': 'bc.apply.1',
        'label': Localization.QUEUES.DESCRIPTIONS.BC.BUSINESS_CONDITION_1.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.BC.BUSINESS_CONDITION_1.DESCRIPTION,
        'icon': 'bi-cash-stack',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.BC,
        'card_group': QueueCardGroupEnum.BC,
        'card_group_translation': Localization.QUEUES.CARD.BC,
        'name': 'bc.apply.2',
        'label': Localization.QUEUES.DESCRIPTIONS.BC.BUSINESS_CONDITION_2.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.BC.BUSINESS_CONDITION_2.DESCRIPTION,
        'icon': 'bi-cash-stack',
        'external': false
    },
    
    {
        'group': QueueListInfoGroupEnum.EXTERNAL,
        'name': 'rp.inbound',
        'card_group': QueueCardGroupEnum.RETAILPRO,
        'card_group_translation': Localization.QUEUES.CARD.RETAILPRO,
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.RP_INBOUND_MOVIMENTI.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.RP_INBOUND_MOVIMENTI.DESCRIPTION,
        'icon': 'bi-building',
        'external': true
    },
    {
        'group': QueueListInfoGroupEnum.EXTERNAL,
        'name': 'inbound-rp',
        'card_group': QueueCardGroupEnum.TON,
        'card_group_translation': Localization.QUEUES.CARD.THE_CORNER,
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.TON_RP.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.TON_RP.DESCRIPTION,
        'icon': 'bi-building-fill-down',
        'external': true
    },
    {
        'group': QueueListInfoGroupEnum.EXTERNAL,
        'name': 'outbound-item-ns',
        'card_group': QueueCardGroupEnum.TON,
        'card_group_translation': Localization.QUEUES.CARD.THE_CORNER,
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.TON_OUTBOUND_NS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.TON_OUTBOUND_NS.DESCRIPTION, //e' UGUALE ALLA LABEL RIDEFINIRLA
        'icon': 'bi-building-fill-up',
        'external': true
    },
    {
        'group': QueueListInfoGroupEnum.MKPL_SVT,
        'card_group': QueueCardGroupEnum.SAVAIT,
        'card_group_translation': Localization.QUEUES.CARD.SAVAIT,
        'name': 'mkplsvt.outbound.order',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.SVT_ORDER.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.SVT_ORDER.DESCRIPTION,
        'icon': 'bi-send',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.EXTERNAL,
        'card_group': QueueCardGroupEnum.MKPL_SVT,
        'card_group_translation': Localization.QUEUES.CARD.SAVAIT,
        'name': 'mkplsvt.outbound-ss.order',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.SVT_AC2SS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.SVT_AC2SS.DESCRIPTION,
        'icon': 'bi-send-fill',
        'external': true
    },
    {
        'group': QueueListInfoGroupEnum.EXTERNAL,
        'card_group': QueueCardGroupEnum.EFASHION,
        'name': 'inbound-items.efashion',
        'label': Localization.QUEUES.DESCRIPTIONS.INBOUND.EFASHION_ITEMS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.INBOUND.EFASHION_ITEMS.DESCRIPTION,
        'icon': 'bi-up',
        'external': true
    },
    {
        'group': QueueListInfoGroupEnum.ERP,
        'card_group':QueueCardGroupEnum.ERP,
        'card_group_translation': Localization.QUEUES.CARD.ERP,
        'name': 'outbound.order',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.ERP_ORDERS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.ERP_ORDERS.DESCRIPTION,
        'icon': 'bi-list-ul',
        'external': false
    },
    {
        'group': QueueListInfoGroupEnum.ERP,
        'card_group':QueueCardGroupEnum.ERP,
        'card_group_translation': Localization.QUEUES.CARD.ERP,
        'name': 'outbound.order',
        'label': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.ERP_ORDERS.LABEL,
        'description': Localization.QUEUES.DESCRIPTIONS.OUTBOUND.ERP_ORDERS.DESCRIPTION,
        'icon': 'bi-list-ul',
        'external': false
    }

]


export { QueueEntryObjectStatusEnum, QueueEntryStatuses, getQueueEntryStatus, queues_list, findQueueItemByName, statusIcons }