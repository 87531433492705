import Overlay from "@components/Overlay/Overlay"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import SectionGroupInlineItem from "@components/SectionGroup/SectionGroupInlineItem"
import { Order } from "@csapi/provisioning/api"
import Localization from "@localization/Index"
import { useNavigate } from "react-router-dom"
import OrderSummaryHeader from "../OrderSummaryHeader/OrderSummaryHeader"
import OrderItemsSummary from "@components/OrderItemsSummary/OrderItemsSummary"

type OrderPreviewProps = {
    order: Order,
    onClose: Function
}

const OrderPreview = (props:OrderPreviewProps) => {
    const { order, onClose } = props
    const navigate = useNavigate()

    const { shipping_info, billing_info } = order
    const { address:shippingAddress={} } = shipping_info ?? {}
    const { address:billingAddress={} } = billing_info ?? {}
    
    const hasShippingNominatives = !(!shippingAddress.company_name && !shippingAddress.first_name && !shippingAddress.last_name)
    const { first_name, last_name, company_name, street, house_number, city, province, state, zip, contact1, contact2 } = (hasShippingNominatives)?shippingAddress:billingAddress ?? {}

    const nominative = !!company_name?company_name:`${first_name} ${last_name}`
    const firstLineAddress = `${street || ""}${!!house_number?`, ${house_number}`:``}`
    const secondLineAddress = `${zip || ""} ${city || ""} ${!!province?`(${province})`:''} ${state || ""}`

    const addressRender = <div className="d-flex flex-column justify-content-end align-items-end">
        <div className="text-end">{firstLineAddress}</div>
        <div className="text-end"> {secondLineAddress}</div>
    </div>

    const contactRender = <div className="d-flex flex-column justify-content-end align-items-end">
        <div className="text-end">{contact1 ?? "N.D."}</div>
        <div className="text-end">{contact2 ?? "N.D."}</div>
    </div>
    
    return (
        <Overlay onClose={()=>{onClose()}}>
            <div className="w-100 p-4">
                <div className="d-flex justify-content-between align-items-start">
                    <OrderSummaryHeader order={order} onClick={()=>{navigate(`/orders/${order._id?.$oid}`)}} />
                    <div className="p-2" role="button" onClick={()=>{onClose()}}>
                        <i className={`bi-x font-3x`} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <SectionGroup className="me-2" title={Localization.PAGE_ORDERS.INFORMAZIONI_ACQUIRENTE}>
                            <SectionGroupInlineItem label="Nome" value={order.buyer_name || "N.D."} icon="bi-person-circle" />
                            <SectionGroupInlineItem label="Email" value={order.buyer_email || "N.D."} icon="bi-envelope" />
                            <SectionGroupInlineItem label="ID" value={order.buyer_identifier || "N.D."} icon="bi-upc-scan" />
                        </SectionGroup>
                    </div>
                    <div className="col-md-12">
                        <SectionGroup title={Localization.PAGE_ORDERS.SPEDIZIONE}>
                            <SectionGroupInlineItem label="Nominativo" value={order?.shipping_info?.address? nominative :"N.D."} icon="bi-person-circle" />
                            <SectionGroupInlineItem label="Indirizzo" value={order?.shipping_info?.address? addressRender :"N.D."} icon="bi-envelope" />
                            <SectionGroupInlineItem label="Contatti" value={(!!contact1 || !!contact2)? contactRender :"N.D."} icon="bi-upc-scan" />
                        </SectionGroup>
                    </div>
                </div>
                <SectionGroup title={Localization.PAGE_ORDER.ARTICOLI}>
                    <OrderItemsSummary order={order} />
                </SectionGroup>
                <div style={{height: '1rem'}}></div>
            </div>
        </Overlay>
    )
}

export default OrderPreview