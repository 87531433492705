import { ContextSubMenu } from "@interfaces/ContextMenu"
import styles from './css/ContextMenu.module.scss'

type Props = {
    content: ContextSubMenu,
    closeFunction?: Function
}

const SubMenu = (props:Props) =>{
    const { content, closeFunction } = props

    return <div className={`context-sub-menu ${styles.submenu}`}>
    {content.items?.filter(item=>{
        const {show=true } = item
        return !((typeof show === "boolean" && !show) || (typeof show === "function" && !show()))
    }).map((item,index)=>{
                    const { action, text } = item
                    const _text = (typeof text === "string") ? text : (typeof text == "function"?text():text)

                    return (
                            <div key={`contextsubmenu_${index}`} role="button" className={`d-flex align-items-center justify-content-between ${styles.item}`} onClick={(e)=>{e.stopPropagation();action?.();closeFunction?.()}}>
                                <div>{_text}</div>
                            </div>
                        )
                    })}
    </div>
}

export default SubMenu