import NavDropdown from "react-bootstrap/esm/NavDropdown"
import styles from './css/UserMenuItem.module.scss'

type Props = {
    to: string,
    label: string,
}

const UserMenuItem = (props:Props) =>{
    const { to, label } = props

    return (
        <NavDropdown.Item href={to} className={`${styles.container} px-4 d-flex`}>
            <div className="d-flex align-items-center justify-content-left text-muted w-100">
                <span className={`${styles.label}`}>{label}</span>
            </div>
        </NavDropdown.Item>
    )
}

export default UserMenuItem