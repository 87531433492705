import React from 'react'
import { QueueEntryObject, SingleMetadata } from '@csapi/provisioning'
import { QueueItem, queues_list, statusIcons } from '@utils/QueueUtils'
import SectionGroup from '@components/SectionGroup/SectionGroup'


type Props = {
  details: QueueEntryObject
  configDetails: QueueItem
}

const QueueDetailDetails: React.FC<Props> = ({ details, configDetails}) => {

const getComponentToShow = () =>{


  switch (configDetails?.custon_details_detail) {
    case "TEST":
        return <SectionGroup>TEST</SectionGroup>
    default:
        return <span>{'N.D'}</span>;
}

}

return (
  <>
    {details ? (
      <div className="row mt-field d-flex align-items-stretch">
        <div className="col-md-24 d-flex flex-column" >
              {getComponentToShow()}
        </div>
      </div> 
    ) : (
      <div></div>
    )}
  </>
)

}

export default QueueDetailDetails
