import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface FavoritesContextType {
  favorites: string[];
  toggleFavorite: (queueId: string) => void;
}

const defaultValue: FavoritesContextType = {
  favorites: [],
  toggleFavorite: () => {} 
};

const QueueFavoritesContext = createContext<FavoritesContextType>(defaultValue);

export const QueueFavoritesProvider = ({ children }: { children: ReactNode }) => {
  const [favorites, setFavorites] = useState<string[]>([]);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites') || '[]') as string[];
    setFavorites(storedFavorites);
  }, []);

  const toggleFavorite = (queueId: string) => {
    const updatedFavorites = favorites.includes(queueId)
      ? favorites.filter(f => f !== queueId)
      : [...favorites, queueId];
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    setFavorites(updatedFavorites);
  };

  return (
    <QueueFavoritesContext.Provider value={{ favorites, toggleFavorite }}>
      {children}
    </QueueFavoritesContext.Provider>
  );
};

export const useFavorites = () => useContext(QueueFavoritesContext);
