import axios from 'axios'

const changeLanguage = async(language:string) => {
    try {
        const response = await axios.patch('api/user/change-language', {language})
        return response.data
    } catch(error:any) {
        throw error
    }
}

export { changeLanguage }